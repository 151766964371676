import {HeaderToggleContext} from "../../App";
import getText from "../../locale/lang";
import {Link, useParams} from "react-router-dom";
import {useContext, useEffect} from "react";
import {useCookieContext} from "../../CookieContext";


export default function PolicyButtons(props) {
    const {changeToggle, setChangeToggle} = useContext(HeaderToggleContext);
    const {refKey} = useCookieContext();
    const {server} = useParams()

    useEffect(() => {
    }, [refKey, changeToggle])

    const linkAction = () => {
        setChangeToggle(!changeToggle)
        window.scrollTo(0, 0)
    }

    return (
        <div className="nk-footer-links" style={{display: "flex", justifyContent: props.position}}>
            <p>
                <Link to={`/refund-policy/${server}`} className="link-effect" onClick={linkAction}>{getText('refundPolicy')}</Link>
            </p>
            <p>
                <span>  |  </span>
            </p>
            <p>
                <Link to={`/server-rules/${server}`} className="link-effect" onClick={linkAction}>{getText('serverRules')}</Link>
            </p>
            <p>
                <span>  |  </span>
            </p>
            <p>
                <Link to={`/terms/${server}`} className="link-effect" onClick={linkAction}>{getText('termsAndConditions')}</Link>
            </p>
            <p>
                <span>  |  </span>
            </p>
            <p>
                <Link to={`/cookie-policy/${server}`} className="link-effect" onClick={linkAction}>{getText('cookiePolicy')}</Link>
            </p>
            <p>
                <span>  |  </span>
            </p>
            <p>
                <Link to={`/privacy-policy/${server}`} className="link-effect" onClick={linkAction}>{getText('privacyPolicy')}</Link>
            </p>
        </div>)
}