import React from 'react';
import getText from "../../../locale/lang";

const RareWeapon = ({info}) => {
    return (
        <div className="bg-dark-2" style={{marginBottom: 16, padding: 20, display: "flex", alignItems: 'flex-start', flexDirection: 'column'}}>
            <h4>{getText('rareWeapon')}</h4>
            <h5>Magician</h5>
            {info.rareWeaponMag.map((item, index) => (
                <React.Fragment key={index}>
                    <h6>{item.title}</h6>
                    {Array.isArray(item.description) && <ul>
                        {item.description.map((desc, index) => (
                            <li key={index} dangerouslySetInnerHTML={{__html: desc}}/>
                        ))}
                    </ul>}
                </React.Fragment>
            ))}
            <h5>Fighter</h5>
            {info.rareWeaponFighter.map((item, index) => (
                <React.Fragment key={index}>
                    <h6>{item.title}</h6>
                    {Array.isArray(item.description) && <ul>
                        {item.description.map((desc, index) => (
                            <li key={index} dangerouslySetInnerHTML={{__html: desc}}/>
                        ))}
                    </ul>}
                </React.Fragment>
            ))}
        </div>
    );
};

export default RareWeapon;