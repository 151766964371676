import styled from "styled-components";

const A = styled.a`
    color: #fff;
    fill: #fff;

    &:hover {
        text-decoration: none;

        & > div {
            box-shadow: 0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.3);
        }

        svg > circle {
            fill: #e08821;
        }

        svg > path {
            fill: #fff;
        }
    }
`;

export default function PalletButton({icon, children, padding, style, ...props}) {
    return (
        <A className={`col-md-6`} style={{ ...style}}  {...props}>
            <div className={`nk-box-${padding ? padding : 3}`} style={{backgroundColor: '#0e0e0e'}}>
                <div className="nk-ibox">
                    {icon ? <div className="nk-ibox-icon nk-ibox-icon-circle">
                        {icon}
                    </div> : null}
                    <div className="nk-ibox-cont">
                        {children}
                    </div>
                </div>
            </div>
        </A>
    )
}