import {Helmet} from "react-helmet-async";
import {isMobile, LINKS, REMASTERED_DATE_X3, REMASTERED_DATE_X6} from "../App";
import {TopParallax} from "../components/TopParallax";
import Gap from "../components/Gap";
import getText from "../locale/lang";
import Remastered from "../components/Features/RemsateredInfo/Remastered";
import Counter from "../components/Counter";
import {Box, Container} from "@mantine/core";
import {useParams} from "react-router-dom";

export default function FeaturesRemastered() {
    const {server} = useParams()

    return <>
        <Helmet>
            <title>Dares - {getText('features')} L2 Server</title>
            <link rel="canonical" href={`${LINKS.mainUrl}/`}/>
        </Helmet>

        <TopParallax>
            <Box className="container" style={{display: "flex", height: '100%', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                <Box style={{width: '50%'}}>
                    {isMobile ? <h3>Remastered</h3> : <h2 style={{color: '#e08821'}}>Remastered</h2>}
                    <h1 style={{fontSize: 56}}>Dares Legendary Edition</h1>
                </Box>
                   <Box style={{display: "flex", justifyContent: 'center', flexDirection: 'column', width: '50%'}}>
                       <Counter title={'Neonate x3 [NEW]'} date={REMASTERED_DATE_X3} to={'/features/remastered'}/>
                       <Counter title={'Endless X6 [OLD]'} date={REMASTERED_DATE_X6} to={'/features/remastered'}/>
                   </Box>
            </Box>
        </TopParallax>
        <Gap/>
        <Container>
            <Remastered server={server}/>
            {isMobile && <><Gap/><Gap/></>}
        </Container>
    </>
}