import {Helmet} from "react-helmet-async";
import getText from "../../locale/lang";
import {LINKS} from "../../App";
import {Center, Container, Table} from "@mantine/core";
import Gap from "../../components/Gap";

import {useParams} from "react-router-dom";
import ScrollToTopButton from "../../components/ScrollToTopButton";
import BackButton from "../../components/BackButton";
import useSkills from "../../hooks/useSkills";
import {useEffect} from "react";


const DatabaseProf = () => {
    const {id} = useParams()
    const {skill} = useSkills(id)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <Helmet>
                <title>Dares - {getText('database')} L2 Server</title>
                <link rel="canonical" href={`${LINKS.mainUrl}/`}/>
            </Helmet>

            <Container>
                <ScrollToTopButton/>
                <BackButton/>
                <Gap/>
                <Gap/>
                <Gap/>
                {!skill ? <Center><h3>{getText('loading')}</h3></Center> : <div className="bg-dark-1" style={{padding: 20}}>
                    <Container>
                        <Table style={{color: '#f8f9fa'}} verticalSpacing="sm">
                            <thead>
                            <tr>
                                <th style={{fontSize: 20, lineHeight: 1.8}}>Icon</th>
                                <th style={{fontSize: 20, lineHeight: 1.8}}>Description</th>
                            </tr>
                            </thead>
                            <tbody className={'custom-striped-tbody'}>
                            {Object.keys(skill).map((level, index) => {
                                const skillByLevel = skill[level][0]
                                return (
                                    <tr key={index}>
                                        <td>
                                            <img src={'https://images.dares.club/' + skillByLevel.icon.replace(/[[\]]/g, '').replace('.', '/skill_i/').replace(/^./, str => str.toUpperCase()) + '.png'} alt={skillByLevel.name}/>
                                        </td>
                                        <td>
                                            <span style={{color: '#e08821', fontSize: 16}}>{skillByLevel.name}</span>
                                            <span style={{color: 'gray', fontSize: 16}}> Lvl {skillByLevel.level}</span>
                                            <span style={{display: 'block', color: '#f8f9fa', fontSize: 16, lineHeight: 1.8, wordSpacing: 1.2}} dangerouslySetInnerHTML={{__html: skillByLevel.desc.replace(/\\n/gi, '<br/>')}}/>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                        <Gap/>
                    </Container>
                </div>}
            </Container>
        </>
    );
};

export default DatabaseProf;