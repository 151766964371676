export default function FooterButton(props) {
    return (
        <a id={props.matomo_id} href={props.to} onClick={props.onClick} target="_blank" rel="noreferrer" className="nk-btn nk-btn-md nk-btn-rounded link-effect-4 ready" style={{width: 140, marginBottom: 10, margin: 'auto'}}>
            <span className="link-effect-inner">
                <span className="link-effect-l">
                    <span>{props.children}</span>
                </span>
                <span className="link-effect-r">
                    <span>{props.children}</span>
                </span>
                <span className="link-effect-shade">
                    <span>{props.children}</span>
                </span>
            </span>
        </a>
    )
}