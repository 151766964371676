import {createStyles, Modal} from "@mantine/core";
import React, {useEffect, useState} from "react";
import FooterButton from "./Features/FooterButton";
import {isMobile, LINKS} from "../App";
import getText from "../locale/lang";
import {useCookieContext} from "../CookieContext";

const useStyles = createStyles(() => ({
    step: {
        width: `${isMobile ? '100%' : '33%'}`,
        borderBottom: `${isMobile ? '1px solid #555' : 'none'}`,
        borderRight: `${isMobile ? 'none' : '1px solid #555'}`,
        padding: 10,
        display: 'flex',
        flexDirection: `${isMobile ? 'row' : 'column'}`,
    },
}));

const PlayNow = () => {
    const {classes} = useStyles();

    const [opened, setOpened] = useState(false);
    const { refKey } = useCookieContext();

    useEffect(() => {}, [refKey]);

    return (
        <div>
            <button
                className="nk-btn nk-btn-x2 link-effect-1 nk-btn-color-main-1 ready"
                style={{margin: '-10px 0', fontFamily: 'Marcellus SC'}}
                onClick={() => setOpened(true)}
                id={'w_button_play_now'}
            >
                <i className="fa-solid fa-play" style={{fontSize: 18, marginRight: 10}}></i>
                <span className="">P</span>
                <span className="">L</span>
                <span className="">A</span>
                <span className="" style={{marginRight: 5}}>Y</span>
                <span className="">N</span>
                <span className="">O</span>
                <span className="">W</span>
            </button>
            <Modal opened={opened} onClose={() => setOpened(false)} centered title="Play Now" size="calc(100vw - 30%)"
                   styles={() => ({
                       root: {
                           zIndex: 99999,
                       },
                       title: {
                           fontFamily: 'Marcellus SC',
                       },
                       modal: {
                           backgroundColor: '#181818',
                           color: '#fff',
                       },
                       body: {
                           display: 'flex'
                       },
                       close: {
                           '&:hover': {
                               backgroundColor: '#222222',
                               color: '#fff'
                           }
                       }
                   })}
            >
                <div style={{display: 'flex', flexDirection: `${isMobile ? 'column' : 'row'}`}}>
                    <div className={classes.step}>
                        <h4 style={{textAlign: 'center'}}>{getText('step')} 1 - <span style={{color: '#e08821'}}>{getText('accountCreating')}</span></h4>
                        <p style={{textAlign: 'center'}}>{getText('playNowStep1')}</p>
                        <FooterButton matomo_id={'w_button_singup_play_now'} to={`${LINKS.signup}${refKey ? `?utm_source=${refKey}` : ''}`} className="link-effect">{getText('signup')}</FooterButton><br/>
                    </div>
                    <div className={classes.step}>
                        <h4 style={{textAlign: 'center'}}>{getText('step')} 2 - <span style={{color: '#e08821'}}>{getText('downloading')}</span></h4>
                        <p style={{textAlign: 'center'}}>{getText('playNowStep2')}</p>
                        <FooterButton matomo_id={'w_button_download_play_now'} to={LINKS.play_now_download} className="link-effect">{getText('download')}</FooterButton><br/>
                    </div>
                    <div className={classes.step} style={{border: 'none'}}>
                        <h4 style={{textAlign: 'center'}}>{getText('step')} 3 - <span style={{color: '#e08821'}}>{getText('startTheGame')}</span></h4>
                        <ol>
                            <li>{getText('playNowStep3_1')}</li>
                            <li>{getText('playNowStep3_2')}</li>
                        </ol>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default PlayNow;