import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

const useSets = (id = undefined) => {
    const dispatch = useDispatch();
    const sets = useSelector(state => state.setsJsonState);
    const [set, setSet] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!sets && !loading) {
            setLoading(true)
            dispatch.setsJsonState.fetchSets();
        }
        if (sets && id) {
            setSet(sets[id])
        }
    }, [sets, dispatch, loading, id]);

    const getSetIconSrc = (item) => {
        if (!item) {
            return ''
        }
        let icon = item.icon
        if (!icon) {
            icon = item[1][0].icon
        }
        return 'https://images.dares.club/' + icon.split(';')[0].replace('{', '').replace(/\[|\]/g, '').replace('icon.', '').replace('Icon.', '') + '.png'
    }

    return {sets, set, getSetIconSrc}
};

export default useSets;