import React from 'react';
import useSets from "../../../hooks/useSets";
import {Helmet} from "react-helmet-async";
import getText from "../../../locale/lang";
import {LINKS} from "../../../App";
import {Center, Container, Table} from "@mantine/core";
import ScrollToTopButton from "../../../components/ScrollToTopButton";
import BackButton from "../../../components/BackButton";
import Gap from "../../../components/Gap";
import useSkills from "../../../hooks/useSkills";
import {Link, useParams} from "react-router-dom";

const DatabaseSets = () => {
    const {server} = useParams()
    const {sets, getSetIconSrc} = useSets()
    const {skills} = useSkills()

    const grades = ['NONE', 'D', 'C', 'B', 'A', 'S']

    return (
        <>
            <Helmet>
                <title>Dares - {getText('database')} L2 Server</title>
                <link rel="canonical" href={`${LINKS.mainUrl}/`}/>
            </Helmet>

            <Container>
                <ScrollToTopButton/>
                <BackButton/>
                <Gap/>
                <Gap/>
                <Gap/>
                {(!sets || !skills) ? <Center><h3>{getText('loading')}</h3></Center> :
                    <Container size={'xl'} style={{padding: 20, backgroundColor: '#060606', lineHeight: 1.5}}>
                        {grades.map((grade, index) => {
                            return (
                                <React.Fragment key={`external_${index}`}>
                                    <h5 style={{color: '#f8f9fa'}}>{grade}-Grade</h5>
                                    <Table>
                                        <thead>
                                        <tr>
                                            <th style={{fontSize: 20, lineHeight: 1.8}}>Icon</th>
                                            <th style={{fontSize: 20, lineHeight: 1.8}}>Name</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {Object.keys(sets).filter((key) => sets[key].grade === grade).map((key, index) => {
                                            const set = sets[key]
                                            const skill = skills[set.set_skill.split(';')[0].split('-')[0]][1][0]
                                            return <tr key={`inner_${index}`} style={{color: '#f8f9fa', fontSize: 16}}>
                                                <td><img src={getSetIconSrc(skill)} alt={skill.name}/></td>
                                                <td>
                                                    <Link to={`/database/set/${key}/${server}`}>
                                                        <span style={{color: '#e08821', fontSize: 16}}>{skill.name}</span>
                                                    </Link>
                                                </td>
                                            </tr>
                                        })}
                                        </tbody>
                                    </Table>
                                    <Gap/>

                                </React.Fragment>)
                        })}
                    </Container>
                }
            </Container>
        </>
    );
};

export default DatabaseSets;