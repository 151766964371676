const translation = {
    en: {
        home: "Home",
        features: "Features",
        download: "Download",
        forum: "Forum",
        signup: "Sign Up",
        login: "Login / Register",
        donate: "Donate",
        vote: "Vote",
        database: "Database",
        discord: "Discord",
        agreement: "User Agreement",
        privacyPolicy: "Privacy Policy",
        refundPolicy: "Refund Policy",
        serverRules: "Server Rules",
        termsAndConditions: "Terms and Conditions",
        cookiePolicy: "Cookie Policy",
        language: "Language",
        en: "English",
        es: "Spanish",
        pt: "Portuguese",
        ua: "Ukrainian",
        ru: "Russian",
        years: "Years",
        months: "Months",
        days: "Days",
        hours: "Hours",
        minutes: "Minutes",
        seconds: "Seconds",
        obt: "This will be available few days before OBT start",
        streamers: "Streamers",
        loadingStreamers: "Just a moment, we are loading streamers...",
        offlineStreamers: "All streamers are offline",
        later: "Available soon",
        autoUpdater: "Auto Updater",
        client: "Client",
        fasterWay: "Faster way to download",
        patch: "Patch",
        howToPlay: "HOW TO PLAY",
        mustDownload: "To play on our server, be sure to download our client, the interlude patch does not work on standard clients",
        loading: "Loading",
        searchTitleClassic: "Search in Dares Classic DatabaseClasses",
        lookFor: "Look for",
        ratesAndFeatures: "RATES & FEATURES",
        featuresOf: "Features of",
        basicInfo: "Basic information",
        ratesAndChronicles: "Rates and chronicles",
        skillChanges: "Skill changes",
        skillFirstLi: "Skills: Divine Heal, Elemental Heal, Poison, Frost Strike, Harmsting, Horror, Entangle, Life Drain, Leach Seed fix cast time 2-2.5 seconds.",
        skillSecondLi: "Poison and Bleed damage increase.",
        setChanges: "Set changes",
        booksInfo: "Books for skills",
        startQuests: "Basic starting quests for weapons and armor",
        rareWeapon: "Rare weapon",
        rareArmor: "Rare armor",
        rareJewelry: "Rare Jewelry and Epic upgrade",
        fortressAndCastle: "Fortress and Castle bonus skills",
        buffs: "Buffs",
        bossInfo: "Epic Bosses & Raid Bosses",
        donateShop: "Donate Shop",
        voteReward: "Vote Reward",
        rates: "Rates",
        premiumRates: "Rates with premium account",
        ratesAttention: "Attention! A premium account in a group only works if all party members have a premium account! If the conditions are not met, then drop for a lower rate in the party, but you still get more experience if you have a premium account.",
        common: "Common",
        votersBuffs: "Voter's Buffs",
        booksTitle: "To learn skills books are needed, which come in two types (Warrior + Magic), obtainable from monsters",
        comingSoon: 'Coming Soon',
        willOpen: 'Starts on January 13th',
        eventObtTitle: "OBT x3",
        eventObtDescription: "Open Beta Test - 17 may 20:00 GMT +3",
        eventObtDescriptionAvailable: "Open Beta Test - Available now",
        step: 'Step',
        accountCreating: 'Creating an Account',
        downloading: 'Downloading',
        startTheGame: 'Start the game',
        playNowStep1: 'Create a Master account in your personal account. In the master account, create accounts for the game, as many as you need for a comfortable game',
        playNowStep2: 'Download the game client from a resource convenient for you. Download the updater to the folder with the game client, run it (it may take 1-2 minutes, it depends on your provider), wait for the updater to update and do its job',
        playNowStep3_1: 'With the help of the updater, you have the opportunity to save passwords so that you do not need to enter them manually the next time',
        playNowStep3_2: 'The classic option, for oldies, is to run L2.exe in the system folder, minus this method, you will have to enter passwords manually every time',
        referralCollaboration: 'Collaboration',
        referralDescription: 'Welcome to the project Dares.club. We offer cooperation on favorable conditions for you on the basis of referral system. That is: regardless of whether you will play on the server or not, your earnings depend on the number of registered accounts and their progress in the game.' +
            '<br>Specifically:' +
            '<ul>' +
            '<li>for a unique user who took level 80 - $0.5</li>' +
            '<li>with proffesion ($0.5)</li>' +
            '<li>Noblesse (1$)</li>' +
            '<li>5% of these players donations</li>' +
            '</ul>' +
            '(all the statistics of accrual of funds will be presented in a Personal Cabinet)' +
            '<br>Full freedom of action of your advertising campaign: stream, ads, etc. Withdrawal conditions, from 10+$, if the amount is less, possible issuance converted to game currency. If you are interested in this offer, please write to us in discord. Also, we will be glad to see you at our project, broadcast active streamers, we display on the main page of our site!' +
            '<br>With best wishes to the team of Dares.club!'
    },
    es: {
        home: "Hogar",
        features: "Descripción",
        download: "Descargar",
        forum: "Fórum",
        signup: "Registar-se",
        login: "Entrar",
        donate: "Doar",
        vote: "Votar",
        database: "Base de Conhecimento",
        discord: "Discórdia",
        agreement: "Contrato do usuário",
        privacyPolicy: "Política de privacidad",
        refundPolicy: "Política de reembolso",
        serverRules: "Normas del servidor",
        termsAndConditions: "Condiciones de uso",
        cookiePolicy: "Política de cookies",
        language: "idioma",
        en: "Inglés",
        es: "Español",
        pt: "Portugués",
        ua: "Ucraniano",
        ru: "Ruso",
        years: "Anos",
        months: "Meses",
        days: "Días",
        hours: "Horas",
        minutes: "Minutos",
        seconds: "Segundos",
        obt: "Estará disponível alguns dias antes do início do OBT",
        streamers: "Streamers",
        offlineStreamers: "Todos los streamers están desconectados",
        later: "Em breve",
        autoUpdater: "Auto Updater",
        client: "Cliente",
        fasterWay: "Faster way to download",
        patch: "Patch",
        howToPlay: "COMO JOGAR",
        mustDownload: "Para jogar em nosso servidor, certifique-se de baixar nosso cliente, o patch interlude não funciona em clientes padrão",
        loading: "Carregando",
        searchTitleClassic: "Pesquisar na base de conhecimento do Dares Classic",
        lookFor: "Pesquisar",
        donateShop: "Loja de doações",
        voteReward: "Recompensas de votação",
        common: "Comum",
        votersBuffs: "Eleitores Buffs",
        booksTitle: "To learn skills books are needed, which come in two types (Warrior + Magic), obtainable from monsters",
        comingSoon: 'Muy pronto',
        willOpen: 'Apertura el 13 de enero',
        step: 'Paso',
        accountCreating: 'Creando una cuenta',
        downloading: 'Descargando',
        startTheGame: 'Iniciar el juego',
        playNowStep1: 'Cree una cuenta Maestra en su cuenta personal. En la cuenta maestra, crea cuentas para el juego, tantas como necesites para un juego cómodo',
        playNowStep2: 'Descargue el cliente del juego desde un recurso conveniente para usted. Descarga el actualizador a la carpeta con el cliente del juego, ejecútalo (puede tardar de 1 a 2 minutos, depende de tu proveedor), espera a que el actualizador se actualice y haga su trabajo',
        playNowStep3_1: 'Con la ayuda del actualizador, tiene la oportunidad de guardar contraseñas para que no tenga que ingresarlas manualmente la próxima vez',
        playNowStep3_2: 'La opción clásica, para los viejos, es ejecutar L2.exe en la carpeta del sistema, menos este método, tendrá que ingresar las contraseñas manualmente cada vez',
        referralCollaboration: 'Colaboración',
        referralDescription: 'Bienvenido al proyecto Dares.club. Ofrecemos cooperación en condiciones favorables para usted sobre la base del sistema de referencia. Es decir: independientemente de si va a jugar en el servidor o no, sus ganancias dependen del número de cuentas registradas y su progreso en el juego.' +
            '<br>Específicamente:' +
            '<ul>' +
            '<li>para un usuario único que haya alcanzado el nivel 80: 0,5 $.</li>' +
            '<li>con proffesion ($0.5)</li>' +
            '<li>Noblesse (1$)</li>' +
            '<li>5% de las donaciones de estos jugadores</li>' +
            '</ul>' +
            '(todas las estadísticas de devengo de fondos se presentarán en un Gabinete Personal)' +
            '<br>Total libertad de acción de su campaña publicitaria: stream, anuncios, etc. Condiciones de retirada, a partir de 10+$, si la cantidad es menor, posible emisión convertida a moneda del juego. Si está interesado en esta oferta, por favor escríbanos en discordia. ¡Además, estaremos encantados de verte en nuestro proyecto, transmitir streamers activos, mostramos en la página principal de nuestro sitio!' +
            '<br>¡Con mis mejores deseos para el equipo de Dares.club!'
    },
    pt: {
        home: "Casa",
        features: "Descrição",
        download: "Baixar",
        forum: "Foro",
        signup: "Registrarse",
        login: "Iniciar sesión",
        donate: "Donar",
        vote: "Votar",
        database: "Base de conocimientos",
        discord: "Discordia",
        agreement: "Acuerdo de usuario",
        privacyPolicy: "Política de privacidade",
        refundPolicy: "Política de reembolso",
        serverRules: "Regras do servidor",
        termsAndConditions: "Termos e condições",
        cookiePolicy: "Política de cookies",
        language: "Idioma",
        en: "Inglês",
        es: "Espanhol",
        pt: "Português",
        ua: "Ucraniano",
        ru: "Russo",
        years: "Años",
        months: "Meses",
        days: "Días",
        hours: "Horas",
        minutes: "Minutos",
        seconds: "Segundos",
        obt: "Estará disponible unos días antes del inicio de la OBT",
        streamers: "Serpentinas",
        offlineStreamers: "Todos os streamers estão offline",
        later: "Próximamente",
        autoUpdater: "Auto Updater",
        client: "Cliente",
        fasterWay: "Faster way to download",
        patch: "Parche",
        howToPlay: "CÓMO JUGAR",
        mustDownload: "Para jugar en nuestro servidor, asegúrese de descargar nuestro cliente, el parche interludio no funciona en clientes estándar",
        loading: "Cargando",
        searchTitleClassic: "Buscar en la base de conocimiento de Dares Classic",
        lookFor: "Buscar",
        donateShop: "Tienda de donaciones",
        voteReward: "Recompensas por votación",
        common: "Común",
        votersBuffs: "Aficionados a votar",
        booksTitle: "To learn skills books are needed, which come in two types (Warrior + Magic), obtainable from monsters",
        comingSoon: 'Em breve',
        willOpen: 'Inauguração dia 13 de janeiro',
        step: 'Passo',
        accountCreating: 'Criando uma conta',
        downloading: 'Baixando',
        startTheGame: 'Iniciar o jogo',
        playNowStep1: 'Crie uma conta Master em sua conta pessoal. Na conta master, crie contas para o jogo, quantas forem necessárias para um jogo confortável',
        playNowStep2: 'Baixe o cliente do jogo de um recurso conveniente para você. Baixe o atualizador para a pasta com o cliente do jogo, execute-o (pode levar de 1 a 2 minutos, depende do seu provedor), aguarde a atualização do atualizador e faça seu trabalho',
        playNowStep3_1: 'Com a ajuda do atualizador, você tem a oportunidade de salvar senhas para não precisar digitá-las manualmente na próxima vez',
        playNowStep3_2: 'A opção clássica, para os antigos, é executar o L2.exe na pasta do sistema, menos este método, você terá que inserir as senhas manualmente todas as vezes',
        referralCollaboration: 'Colaboração',
        referralDescription: 'Bem-vindo ao projeto Dares.club. Oferecemos cooperação em condições favoráveis para você com base no sistema de indicação. Ou seja: independentemente de você jogar no servidor ou não, seus ganhos dependem do número de contas registradas e do progresso delas no jogo.' +
            '<br>Especificamente:' +
            '<ul>' +
            '<li>para um usuário único que atingiu o nível 80 - US$ 0,5</li>' +
            '<li>com profissão (US$ 0,5)</li>' +
            '<li>Noblesse (1$)</li>' +
            '<li>5% das doações desses jogadores</li>' +
            '</ul>' +
            '(todas as estatísticas de acumulação de fundos serão apresentadas em um Gabinete Pessoal)' +
            '<br>Liberdade total de ação de sua campanha publicitária: fluxo, anúncios, etc. Condições de saque, a partir de 10+$, se o valor for menor, possível emissão convertida em moeda do jogo. Se estiver interessado nesta oferta, escreva para nós no discord. Além disso, ficaremos felizes em vê-lo em nosso projeto, transmitindo streamers ativos, que exibimos na página principal do nosso site!' +
            '<br>Com os melhores votos para a equipe do Dares.club!'
    },
    ua: {
        home: "Головна",
        features: "Опис",
        download: "Завантажити",
        forum: "Форум",
        signup: "Реєстрація",
        login: "Увійти / Зареєструватися",
        donate: "Донат",
        vote: "Голосування",
        database: "База знань",
        discord: "Діскорд",
        agreement: "Угода користувача",
        privacyPolicy: "Політика конфіденційності",
        refundPolicy: "Політика повернення коштів",
        serverRules: "Правила сервера",
        termsAndConditions: "Загальні положення та умови",
        cookiePolicy: "Політика щодо файлів cookie",
        language: "Мова",
        en: "Англійська",
        es: "Іспанська",
        pt: "Португальська",
        ua: "Український",
        ru: "Російська",
        years: "Років",
        months: "Місяців",
        days: "Днів",
        hours: "Годин",
        minutes: "Хвилин",
        seconds: "Секунд",
        obt: "Буде доступно за кілька днів до початку ОБТ",
        streamers: "Стрімери",
        offlineStreamers: "Усі стримери зараз offline",
        later: "Скоро буде доступно",
        autoUpdater: "Апдейтер",
        client: "Клієнт",
        fasterWay: "Найшвидший спосіб завантаження",
        patch: "Патч",
        howToPlay: "ЯК ГРАТИ",
        mustDownload: "Для гри на нашому сервері обов'язково скачайте наш клієнт, на стандартних клієнтах інтерлюд патч не працює",
        loading: "Завантаження",
        searchTitleClassic: "Шукати в базі знань Dares Classic",
        lookFor: "Знайти",
        ratesAndFeatures: "РЕЙТИ І ОПИС",
        featuresOf: "Особливості",
        basicInfo: "Основна інформація",
        ratesAndChronicles: "Рейти",
        skillChanges: "Зміни навичок",
        skillFirstLi: "Навички: Divine Heal, Elemental Heal, Poison, Frost Strike, Harmsting, Horror, Entangle, Life Drain, Leach Seed, фіксований час застосування 2-2,5 секунди.",
        skillSecondLi: "Шкода від отрути та кровотечі збільшена.",
        setChanges: "Зміни у сетах",
        booksInfo: "Книги навичок",
        startQuests: "Основні початкові квести на зброю та броню",
        rareWeapon: "Рар зброя",
        rareArmor: "Рар броня",
        rareJewelry: "Рар біжутерія та удосконалення епік біжутерії",
        fortressAndCastle: "Бонусні навички за володіння фортом та замком",
        buffs: "Buffs",
        bossInfo: "Епік босси та рейд боси",
        donateShop: "Донат Магазин",
        voteReward: "Нагороди за голосування",
        common: "Загальні",
        votersBuffs: "Бафи за голосування",
        rates: "Рейти",
        premiumRates: "Рейти з преміум аккаунтом",
        ratesAttention: "Увага! Преміум-акаунт у групі працює, лише якщо всі учасники групи мають преміум-акаунт! Якщо умови не виконуються, тоді дроп буде рахуватись по найнижчому рейту в групі, але ви все одно отримаєте більше досвіду, якщо у вас є преміум-акаунт.",
        booksTitle: "Для вивчення навичок потрібні книги двох типів (Воїн та Маг), випадають з монстрів",
        comingSoon: 'Незабаром',
        willOpen: 'Відкриття 13го січня',
        step: 'Крок',
        eventObtTitle: "OБT x3",
        eventObtDescription: "Відкритий Бета Тест - 17 травня 20:00 GMT +3",
        eventObtDescriptionAvailable: "Відкритий Бета Тест - Доступно зараз",
        accountCreating: 'Створення Аккаунту',
        downloading: 'Завантаження',
        startTheGame: 'Почнiть гру',
        playNowStep1: 'Створіть Мастер акаунт в особистому кабінеті. В мастер акаунті, створіть акаунти для гри, стільки, скільки Вам потрібно для комфортної гри',
        playNowStep2: 'Скачайте ігровий клієнт із зручного для Вас ресурсу. Завантажте updater у папку з ігровим клієнтом, запустіть(може зайняти 1-2хв, залежить від вашого провайдера), зачекайте поки апдайтер оновиться і зробить свою роботу',
        playNowStep3_1: 'За допомогою updater у вас з`являється можливість зберегти паролі, щоб не потрібно було вписувати вручну наступного разу',
        playNowStep3_2: 'Класичний варіант, для олдів, запустити L2.exe в папці system, мінус цього методу, вам прийдеться кожен раз вводи паролі в ручну',
        referralCollaboration: 'Співпраця',
        referralDescription: 'Вас вітає проект Dares.club. Ми пропонуємо співпрацю на вигідних умовах для Вас на основі реферальної системи. Тобто: не залежно від того чи будете ви грати на сервері чи ні, ваш заробіток залежить від кількості зареєстрованих акаунтів і їхнього прогресу у грі.' +
            '<br>А саме:' +
            '<ul>' +
            '<li>за унікального користувача взявшого  80 рівень - 0.5$</li>' +
            '<li>3 проффесію(0.5$)</li>' +
            '<li>Noblesse (1$)</li>' +
            '<li>5% від донату цих гравців</li>' +
            '</ul>' +
            '(уся статистика нарахування коштів буде представленна у Особистому кабінеті)' +
            '<br>Повна свобода дій вашої рекламної кампанії:  стрім, оголошення і т.д. Умови виводу коштів, від 10+$, якщо ж сума меньша, можлива видача конвертована у ігровій валюті. Якщо вас цікавить дана пропозиція, прошу відписти нам в discord. Також будемо раді бачити Вас у нас на проекті, трансляцію активних стрімерів ми виводимо  на основну сторінку нашого сайту!\n' +
            '<br>З найліпшими побажаннями команда Dares.club!'
    },
    ru: {
        home: "Главная",
        features: "Описание",
        download: "Скачать",
        forum: "Форум",
        signup: "Регистрация",
        login: "Войти / Зарегистрироваться",
        donate: "Донат",
        vote: "Голосование",
        database: "База знаний",
        discord: "Дискорд",
        agreement: "Пользовательское Соглашение",
        privacyPolicy: "Политика конфиденциальности",
        refundPolicy: "Политика возврата",
        serverRules: "Правила сервера",
        termsAndConditions: "Правила и условия",
        cookiePolicy: "Политика в отношении файлов cookie",
        language: "Язык",
        en: "Английский",
        es: "Испанский",
        pt: "Португальский",
        ua: "Украинский",
        ru: "Русский",
        years: "Годов",
        months: "Месяцев",
        days: "Дней",
        hours: "Часов",
        minutes: "Минут",
        seconds: "Секунд",
        obt: "Будет доступно за несколько дней до начала ОБТ",
        streamers: "Стримеры",
        offlineStreamers: "Все стримеры сейчас offline",
        later: "Скоро будет доступно",
        autoUpdater: "Апдейтер",
        client: "Клиент",
        fasterWay: "Самый быстрый способ загрузки",
        patch: "Патч",
        howToPlay: "КАК ИГРАТЬ",
        mustDownload: "Для игры на нашем сервере обязательно скачайте наш клиент, на стандартных клиентах интерлюд патч не работает",
        loading: "Загрузка",
        searchTitleClassic: "Искать в базе знаний Dares Classic",
        lookFor: "Найти",
        featuresOf: "Особенности",
        basicInfo: "Основная информация",
        ratesAndChronicles: "Рейты",
        skillChanges: "Изменения навыков",
        skillFirstLi: "Навыки: Divine Heal, Elemental Heal, Poison, Frost Strike, Harmsting, Horror, Entangle, Life Drain, Leach Seed, фиксированое время применения 2-2,5 секунды.",
        skillSecondLi: "Урон от яда и кровотечения увеличен.",
        setChanges: "Изменения в сетах",
        booksInfo: "Книги навыков",
        startQuests: "Основные стартовые квесты на оружие и броню",
        rareWeapon: "Рар оружие",
        rareArmor: "Рар броня",
        rareJewelry: "Рар бижутерия и усовершенствованая эпик бижутерия",
        fortressAndCastle: "Бонусные навыки за владение фортом и замком",
        buffs: "Buffs",
        bossInfo: "Эпик боссы и рейд боссы",
        donateShop: "Донат Магазин",
        voteReward: "Награды за голосование",
        common: "Общие",
        votersBuffs: "Баффы за голосование",
        rates: "Рейты",
        premiumRates: "Рейты с премиум аккаунтом",
        ratesAttention: "Внимание! Премиум-аккаунт в группе работает только в том случае, если у всех участников группы есть премиум-аккаунт! Если условия не выполнены, то дроп будем считаться по нижайшему рейту в группе, но вы все равно получите больше опыта, если у вас премиум-аккаунт.",
        booksTitle: "Для изучения навыков необходимы книги двух типов (Воин + Маг), выпадают с монстров.",
        comingSoon: 'Скоро будет',
        willOpen: 'Открытие 13го января',
        step: 'Шаг',
        eventObtTitle: "OБT x3",
        eventObtDescription: "Открытый Бета Тест - 17 мая 20:00 GMT +3",
        eventObtDescriptionAvailable: "Открытый Бета Тест - Доступно сейчас",
        accountCreating: 'Создание учетной записи',
        downloading: 'Скачивание',
        startTheGame: 'Начать игру',
        playNowStep1: 'Создайте мастер-аккаунт в личном кабинете. В мастер аккаунте создайте аккаунты для игры, сколько вам нужно для комфортной игры',
        playNowStep2: 'Скачать клиент игры с удобного вам ресурса. Загрузите апдейтер в папку с игровым клиентом, запустите его (это может занять 1-2 минуты, зависит от вашего провайдера), дождитесь, пока апдейтер обновится и выполнит свою работу',
        playNowStep3_1: 'С помощью апдейтера у вас есть возможность сохранить пароли, чтобы в следующий раз не нужно было вводить их вручную',
        playNowStep3_2: 'Классический вариант, для старичков, запускать L2.exe в системной папке, минус этот способ, каждый раз придется вводить пароли вручную',
        referralCollaboration: 'Сотрудничество',
        referralDescription: 'Вас приветствует проект Dares.club. Мы предлагаем сотрудничество на выгодных условиях для Вас на основе реферальной системы. То есть: не зависимо от того будете ли вы играть на сервере или нет, ваш заработок зависит от количества зарегистрированных аккаунтов и их прогресса в игре.' +
            '<br>А именно:' +
            '<ul>' +
            '<li>за уникального пользователя взявшего 80 уровень - 0.5$</li>' +
            '<li>C проффессией (0.5$)</li>' +
            '<li>Noblesse (1$)</li>' +
            '<li>5% от доната этих игроков</li>' +
            '</ul>' +
            '(вся статистика начисления средств будет представлена в Личном кабинете)' +
            '<br>Полная свобода действий вашей рекламной кампании: стрим, объявления и т.д. Условия вывода средств, от 10+$, если же сумма меньше, возможна выдача конвертированная в игровой валюте. Если вас интересует данное предложение, прошу отписать нам в discord. Также будем рады видеть Вас у нас на проекте, трансляцию активных стримеров мы выводим на основную страницу нашего сайта!' +
            '<br>С наилучшими пожеланиями команда Dares.club!'
    },
}

const getLang = () => {
    return localStorage.getItem('lang') ? deleteFramingSymbols(localStorage.getItem('lang')) : 'en'
}

export const deleteFramingSymbols = (str) => {
    return (str).replace(/^.|.$/g, "")
}

export default function getText(str) {
    const result = translation[getLang()][str]
    if (result === undefined) {
        return translation['en'][str]
    }
    return result
}