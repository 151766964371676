import {Helmet} from "react-helmet-async";
import getText from "../locale/lang";
import {isMobile, LINKS} from "../App";
import {Container, Group} from "@mantine/core";
import Gap from "../components/Gap";
import OptionButton from "../components/OptionButton";
import {useNavigate, useParams} from "react-router-dom";

const Database = () => {
    const navigate = useNavigate();
    const {server} = useParams()

    return (
        <>
            <Helmet>
                <title>Dares - {getText('database')} L2 Server</title>
                <link rel="canonical" href={`${LINKS.mainUrl}/`}/>
            </Helmet>

            <Container>
                <Gap/>
                <Gap/>
                <Gap/>
                {isMobile ? <>
                    <Gap/>
                    <Gap/>
                    <Gap/>
                    <Gap/>
                    <Gap/>
                </> : null}
                <div style={{padding: 20, backgroundColor: '#0e0e0e'}}>
                    <Container>
                        <Gap/>
                        <Group position="center">
                            {/*<OptionButton onClick={() => navigate('/database/classes')}>Items</OptionButton>*/}
                            {/*<OptionButton onClick={() => navigate('/database/classes')}>NPC</OptionButton>*/}
                            {/*<OptionButton onClick={() => navigate('/database/classes')}>Skills</OptionButton>*/}
                            <OptionButton onClick={() => navigate(`/database/classes/${server}`)}>Classes</OptionButton>
                            <OptionButton onClick={() => navigate(`/database/sets/${server}`)}>Sets</OptionButton>
                        </Group>
                        <Gap/>
                    </Container>
                </div>
            </Container>
        </>
    );
};

export default Database;