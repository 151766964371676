import React from 'react';
import CountUp from "./CountUp";
import MyCountdown from "./MyCountdown";
import {getDayDate, getMonthDate} from "../misc";

const Counter = (props) => {
    return (
        Date.now() > props.date ?
            <CountUp title={`${props.title} <span>Server Up</span>`} to={props.to} date={props.date}/> :
            <MyCountdown title={`${props.title} <span>Start Server</span></br> ${getDayDate(props.date)}.${getMonthDate(props.date)}  ${props.date.getHours()}:00`} date={props.date}/>
    );
};

export default Counter;