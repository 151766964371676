import {useInterval} from "@mantine/hooks";
import {useEffect, useState} from "react";
import moment from "moment";
import getText from "../locale/lang";
import {isMobile} from "../App";

export default function CountUp(props) {
    const [timeDiff, setTimeDiff] = useState(moment.duration(new Date().getTime() - props.date))
    const intervalCountdown = useInterval(() => setTimeDiff(() => moment.duration(new Date().getTime() - props.date)), 1000);
    const [title, setTitle] = useState(null)
    const [style, setStyle] = useState({})

    useEffect(() => {
    }, [timeDiff])

    useEffect(() => {
        intervalCountdown.start();
        return () => {
            intervalCountdown.stop()
        }
    }, [intervalCountdown])

    useEffect(() => {
        if (isMobile) {
            setTitle(<h6 style={{marginBottom: 0}} dangerouslySetInnerHTML={{__html: props.title}}></h6>)
            setStyle({
                fontSize: '0.7rem', backdropFilter: 'blur(5px)', margin: '0 auto',
                backgroundColor: 'rgba(14, 14, 14, 0.5)', borderRadius: 10, padding: '20px 40px', width: 'fit-content'
            })
        } else {
            setTitle(<h5 style={{marginBottom: 0}} dangerouslySetInnerHTML={{__html: props.title}}></h5>)
            setStyle({
                margin: '0 auto', fontSize: '1rem', backdropFilter: 'blur(5px)',
                backgroundColor: 'rgba(14, 14, 14, 0.5)', borderRadius: 10, padding: '20px 40px', width: 'fit-content'
            })
        }
    }, [props.title])


    return (
        <div id={'w_button_features_home'} style={{marginBottom: 10}}>
            <div className="nk-countdown rate-button" style={style}>
                {title}
                {timeDiff.years() > 0 && <div style={style}><span style={style}>{timeDiff.years()}</span>{getText('years')}</div>}
                {(timeDiff.years() > 0 || timeDiff.months() > 0) && <div><span>{timeDiff.months()}</span>{getText('months')}</div>}
                {(timeDiff.years() > 0 || timeDiff.months() > 0 || timeDiff.days() > 0) && <div><span>{timeDiff.days()}</span>{getText('days')}</div>}
                {(timeDiff.years() > 0 || timeDiff.months() > 0 || timeDiff.days() > 0 || timeDiff.hours() > 0) && <div><span>{timeDiff.hours()}</span>{getText('hours')}</div>}
                {(timeDiff.years() > 0 || timeDiff.months() > 0 || timeDiff.days() > 0 || timeDiff.hours() > 0 || timeDiff.minutes() > 0) &&
                    <div><span>{timeDiff.minutes()}</span>{getText('minutes')}</div>}
            </div>
        </div>
    )
}