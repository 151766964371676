import Gap from "../../../components/Gap";
import {useParams} from "react-router-dom";
import useSets from "../../../hooks/useSets";
import {Helmet} from "react-helmet-async";
import getText from "../../../locale/lang";
import {LINKS} from "../../../App";
import {Box, Center, Container, Grid, Table, Tooltip} from "@mantine/core";
import ScrollToTopButton from "../../../components/ScrollToTopButton";
import BackButton from "../../../components/BackButton";
import useItems from "../../../hooks/useItems";
import useSkills from "../../../hooks/useSkills";
import React, {useEffect} from "react";


const ItemImage = ({itemsId}) => {
    const {items, getItem, getItemIconSrc} = useItems()

    if (!itemsId || !items) {
        return null
    }
    const item = getItem(itemsId, items)

    return <Tooltip.Floating multiline label={<span>{item.name}</span>}>
        <img height={50} width={50} src={getItemIconSrc(item)} alt={item.name}/>
    </Tooltip.Floating>
}

const ItemNames = ({itemsId, title}) => {
    const {items} = useItems()
    if (!itemsId || !items) {
        return null
    }

    return <Box>
        <h6>{title}</h6>
        <ul>
            {typeof itemsId === 'string' ?
                itemsId.split(';').map((item, index) => <li key={index}>{items[item].name}</li>) : <li>{items[itemsId].name}</li>}
        </ul>
    </Box>

}

const DatabaseSet = () => {
    const {id} = useParams()
    const {set, getSetIconSrc} = useSets(id)
    const {skills} = useSkills()
    const {items} = useItems()


    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container>
            <Helmet>
                <title>Dares - {getText('database')} L2 Server</title>
                <link rel="canonical" href={`${LINKS.mainUrl}/`}/>
            </Helmet>

            <ScrollToTopButton/>
            <BackButton/>
            <Gap/>
            <Gap/>
            <Gap/>
            {(!set || !skills || !items) ? <Center><h3>{getText('loading')}</h3></Center> :
                <Container size={'lg'} style={{minWidth: 500, padding: 20, backgroundColor: '#060606', lineHeight: 1.5}}>
                    <Center m={'20px 0'}><h5>{skills[set.set_skill.split(';')[0].split('-')[0]][1][0].name}</h5></Center>
                    <Grid>
                        <Grid.Col span={6}>
                            <Table style={{color: "white"}}>
                                <tbody>
                                <tr>
                                    <td style={{border: 'none'}}></td>
                                    <td style={{border: 'none'}}>
                                        <ItemImage itemsId={set.head}/>
                                    </td>
                                    <td style={{border: 'none'}}></td>
                                    <td style={{border: 'none'}}></td>
                                    <td style={{border: 'none'}}></td>
                                </tr>
                                <tr>
                                    <td style={{border: 'none'}}>
                                        <ItemImage itemsId={set.gloves}/>
                                    </td>
                                    <td style={{border: 'none'}}>
                                        <ItemImage itemsId={set.chest}/>
                                    </td>
                                    <td style={{border: 'none'}}>
                                        <ItemImage itemsId={set.feet}/>
                                    </td>
                                    <td style={{border: 'none'}}></td>
                                    <td style={{border: 'none'}}></td>
                                </tr>
                                <tr>
                                    <td style={{border: 'none'}}></td>
                                    <td style={{border: 'none'}}>
                                        <ItemImage itemsId={set.legs} items={items} getSetIconSrc={getSetIconSrc}/>
                                    </td>
                                    <td style={{border: 'none'}}></td>
                                    <td style={{border: 'none'}}></td>
                                    <td style={{border: 'none'}}>
                                        <ItemImage itemsId={set.shield} items={items} getSetIconSrc={getSetIconSrc}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5}>
                                        {skills ? skills[set.set_skill.split(';')[0].split('-')[0]][1][0].desc : null}
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Grid.Col>
                        <Grid.Col span={6} style={{paddingLeft: 20}}>
                            <ItemNames itemsId={set.head} title={'Head'}/>
                            <ItemNames itemsId={set.gloves} title={'Gloves'}/>
                            <ItemNames itemsId={set.chest} title={'Chest'}/>
                            <ItemNames itemsId={set.legs} title={'Legs'}/>
                            <ItemNames itemsId={set.feet} title={'Feet'}/>
                            <ItemNames itemsId={set.shield} title={'Shield'}/>
                        </Grid.Col>
                    </Grid>
                </Container>}
        </Container>
    );
};

export default DatabaseSet;