export default function RedirectButton(props) {

    return (
        <li className={props.dropItem} onClick={props.onClick ?? props.onClick} id={props.matomo_id ?? ''} >
            <a href={props.to} target="_blank" rel="noreferrer" className="link-effect-4 ready">
                <span className="link-effect-inner" >
                    <span className="link-effect-l">
                        <span style={props.size ? {fontSize: props.size} : null}>{props.children}</span>
                    </span>
                    <span className="link-effect-r">
                        <span style={props.size ? {fontSize: props.size} : null}>{props.children}</span>
                    </span>
                    <span className="link-effect-shade">
                        <span style={props.size ? {fontSize: props.size} : null}>{props.children}</span>
                    </span>
                </span>
            </a>
        </li>
    )
}