import {ES, GB, PT, RU, UA} from "country-flag-icons/react/3x2";
import {useLocalStorage} from "@mantine/hooks";
import {HeaderToggleContext} from "../../App";
import {useContext} from "react";


export default function MobileLangButton() {
    const {changeToggle, setChangeToggle} = useContext(HeaderToggleContext);
    const [, setLang] = useLocalStorage({key: 'lang', defaultValue: 'en'});

    const changeLang = (lang) => {
        setLang(lang);
        setChangeToggle(!changeToggle)
    }

    return (
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
            <GB id={'w_button_lang_gb_m'} onClick={() => changeLang('en')} style={{height: 32, width: '33%', margin: '10 auto'}}/>
            <ES id={'w_button_lang_es_m'} onClick={() => changeLang('es')} style={{height: 32, width: '33%', margin: '10 auto'}}/>
            <PT id={'w_button_lang_pt_m'} onClick={() => changeLang('pt')} style={{height: 32, width: '33%', margin: '10 auto'}}/>
            <UA id={'w_button_lang_ua_m'} onClick={() => changeLang('ua')} style={{height: 32, width: '33%', margin: '10 auto'}}/>
            <RU id={'w_button_lang_ru_m'} onClick={() => changeLang('ru')} style={{height: 32, width: '33%', margin: '10 auto'}}/>
        </div>
    )
}