import Gap from "../../components/Gap";
import {Helmet} from "react-helmet-async";
import {LINKS} from "../../App";
import getText from "../../locale/lang";
import {Container} from "@mantine/core";
import PolicyButtons from "./PolicyButtons";


export default function PrivacyPolicy() {

    return (<Container>
        <Helmet>
            <title>Dares - {getText('privacyPolicy')} L2 Server</title>
            <link rel="canonical" href={`${LINKS.mainUrl}/`}/>
        </Helmet>
        <Gap/>
        <Gap/>
        <Gap/>
        <PolicyButtons position="start"/>
        <div>
            <h2>Privacy Policy</h2>
            <ol>
                <li>
                    <b>Ownership of the information </b>
                    <ol>
                        <li>
                            In accordance with the laws established in the Organic Law 15/1999 for Personal Data Protection (Ley Orgánica 15/1999 de Protección de Datos de Carácter Personal) we inform you that any personal information given to dares.club as well as any
                            information proceeding from any transactions carried out within the website will be included and stored in a file property of Dares Club.
                            <br/>
                            You can contact the person responsible for your private information via e-mail at info@dares.club
                            <br/>
                            Dares Club agrees to treat your personal information with respect and absolute confidentiality and declares that this information will not be passed on to third parties without prior consent.
                        </li>
                    </ol>
                </li>
                <li>
                    <b>Use and purpose of personal information</b>
                    <ol>
                        <li>
                            Your personal details are dealt with by Dares Club or third parties electronically or via data transmission.
                        </li>
                        <li>
                            Your personal data will be used (according to the articles 6.1 (points ab and f) of the UE policy regulation 679/2016) to provide the following services available through dares.club:
                            <ol>
                                <li>To navigate the website.</li>
                                <li>To receive your payment.</li>
                                <li>Registration in order to be able to enjoy the services on the website.</li>
                                <li>Management and/or processing of orders and associated activities.</li>
                                <li>Management of advice doubts or problems of a technical or commercial nature about the state of orders and other general information requirements.</li>
                                <li>To manage, maintain and optimize our website and our services.</li>
                                <li>Personalized attention through Customer Services.</li>
                                <li>Own product promotion and of companies linked to Dares Club.</li>
                            </ol>
                        </li>
                        <li>
                            Dares Club may process and/or manage personal data relative to third parties which has been facilitated directly by you for example when you purchase a product for a friend, when a product is paid for by someone other than the recipient of the order,
                            when you tell a friend about a service available in dares.club or notify them of an offer for a determined product). In this case, Dares Club will inform the third party in question about the Data Protection Act 15/99 when registering their data in the
                            file but you will be responsible for communicating the following to the person to which the data refers to: that the information will be stored in a file property of Dares Club, the purpose of the information and finally that they can exercise their
                            right to access, rectify, cancel and oppose the information in the manner specified in the present document.
                        </li>
                        <li>
                            In each case, the mandatory or discretionary nature of the transmission and / or transfer of data -in relation to the requested personal data- will be specified without distinction due to the processing and / or collection of said information
                            distinguishing the fields required by an asterisk (*).
                        </li>
                        <li>
                            The eventual lack of communication to Dares Club of the data corresponding to mandatory fields will impede the attainment and / or scope of the main purpose associated with the data collection. On the other hand, the non-communication of said Dares
                            Club data could determine for example the impossibility of holding all purchase orders for the products for sale on the dares.club website as well as the provision of other services available on the web.
                        </li>
                        <li>
                            There is the possibility that Dares Club processes and / or manages personal data related to third parties that have been transmitted or transferred directly by the Users of Dares Club. In these cases, Dares Club will inform the third party in question
                            about the contents of Regulation (EU) 679/2016 when registering its data in the file although the User will be responsible for communicating to the person to whom said data refers before communicating them to Dares Club. The inclusion of the data in a
                            Dares Club file, the purpose of the data processing and the possibility of exercising the rights of access, rectification, cancellation and opposition to Dares Club in the manner specified in this privacy policy.
                        </li>
                        <li>
                            Dares Club presumes that the data has been entered by its owner or by a person authorized by it as well as that it is correct and accurate. It is up to Users to update their own data accessing the section My account at dares.club. Therefore, the User
                            is responsible for the veracity of their personal data; Dares Club will not be responsible for the possible inaccuracy of the personal data of the Users.
                        </li>
                        <li>
                            Dares Club reserves the right to cancel an account and all relevant information if any content is perceived to be illicit or damaging to the image of Dares Club and/or its products or those of third parties, as well as any offensive content or that
                            which incites, encourages or publicises illegal or defamatory activities, pornographic or violent content, or which encourages discrimination of a racial, sexual or sexual orientation nature.
                        </li>
                    </ol>
                </li>
                <li>
                    <b>Compulsory transfer of data</b>
                    <ol>
                        <li>
                            The transfer of personal data to Dares Club may be compulsory by virtue of content and the law. In other cases, it will be discretionary.
                        </li>
                        <li>
                            The compulsory or discretional character of personal information will be specified by the use of an asterisk (*) which will be used in the compulsory fields.
                        </li>
                        <li>
                            If you do not fill in the compulsory fields it could prevent us from carrying out our services. Not communicating the data in question to Dares Club could determine for example the fulfillment of any order of any product in dares.club as well as other
                            services available on the website.
                        </li>
                        <li>
                            Dares Club assumes that all the information has been provided by you or a person authorised by you and that the information will be exact and correct. Updating the information is your responsibility and can be done by accessing the My Account section
                            in dares.club. You are responsible for the veracity of your personal information; Dares Club will not be responsible if your information is incorrect.
                        </li>
                        <li>
                            Dares Club is committed to respect and keep absolute confidentiality in the collection and processing of the personal data of the User. Your personal data will not be communicated to other people or companies to be used for their own purposes without
                            the prior consent of the User. However, some entities subcontracted by Dares Club could access the personal data and information to help Dares Club in the management of the website. Dares Club ensures that all these entities comply with the data
                            protection regulations which are also directly applicable to them.
                        </li>
                    </ol>
                </li>
                <li>
                    <b>Your Rights</b>
                    <ol>
                        <li>
                            You can exercise your right to access, rectify, oppose or cancel your information contacting us by a support ticket in discord.
                        </li>
                        <li>
                            The user may exercise his right to forget before Dares Club and therefore request the elimination of his personal data in any of the following cases: (i) when the personal data cease to be necessary in accordance with the purpose for which they were
                            collected; (ii) when the interested party withdraws his consent or opposes certain acts of treatment provided that there are no other means of legitimizing such treatment; (iii) when the personal data has been treated unlawfully; (iv) when personal
                            data must be deleted to fulfill a legal obligation; or (v) when the data has been obtained in relation to services offered to minors.
                        </li>
                        <li>
                            The user has the right to request the portability of their data. In order for the interested party to be able to exercise this right two requirements must be fulfilled: (i) that the processing is carried out in an automated manner and (ii) that the
                            legal basis for legitimizing the treatment is either the consent or the need to execute a contract of which the interested party is a party.
                        </li>
                    </ol>
                </li>
                <li>
                    <b>Passwords</b>
                    <ol>
                        <li>
                            You are solely responsible for keeping your password in the strictest and absolute confidentiality and assume therefore any damages or consequences arising from revealing the password.
                        </li>
                        <li>
                            You can modify the password at any time. You agree to notify Dares Club immediately regarding improper or unauthorized use of your password as well as the unauthorized access by a third party.
                        </li>
                    </ol>
                </li>
                <li>
                    <b>Cookies</b>
                    <ol>
                        <li>
                            dares.club uses automatic systems for gathering information not given to us directly such as cookies.
                        </li>
                        <li>
                            Cookies are pieces of information that are stored on your hard drive by your browser that track your movements within websites. It allows to associate your identity with the personal data that you leave on the website.
                        </li>
                        <li>
                            The cookies are located in a server owned by Dares Club so that only Dares Club can process and/or manage information gathered and obtained by cookies in order to optimize their own services and website with relation to your specific needs and
                            preferences.
                        </li>
                        <li>
                            If you do not wish to install a cookie in your hard drive you must configure your web browser to deactivate them. You may also freely destroy the cookies at the end of each session. In this case, Dares Club does not guarantee that you will be able to
                            view the entire website or that you will have full access to some services offered by dares.club. The website installs the following types of cookies: Technical cookies: They are necessary for the operation of the website and allow for example that the
                            user can access secure areas.
                        </li>
                        <li>
                            The following is the route that the User must follow to not authorize, disable or block cookies in different web browsers: Internet Explorer: Tools -> Internet Options -> Privacy -> Configuration. Firefox: Tools -> Options -> Privacy -> History ->
                            Custom Settings. Chrome: Settings -> Show advanced options -> Privacy -> Content settings. Safari: Preferences -> Security.
                        </li>
                    </ol>
                </li>
                <li>
                    <b>Game Related Data</b>
                    <ol>
                        <li>
                            Players agree and authorize Dares Club to analyze and collect any required events and data related to their computers while they play in our servers. This information will be used with the sole purpose of identifying and banning players that are trying
                            to cheat by using third party programs and not following our strict rules.
                        </li>
                        <li>
                            The data collected will not be shared with any third parties whatsoever.
                        </li>
                        <li>
                            The data collected may include and it is not limited to: processes running in the computer, applications installed, IP address and MAC (HWID), account names etc.
                        </li>
                        <li>
                            Dares Club does not collect any personal/sensitive data like credit cards, passwords etc. only information related to the other processes running in the computer while they play in our game servers.
                        </li>
                        <li>
                            Players can request the erasure of their data at any time as well as the deletion of their game account.
                        </li>
                        <li>
                            Dares Club reserves the right to close any game account and delete all its related data to the players that revoke the consent of collecting this data as this is a requirement to be allowed to play in our private server.
                        </li>
                    </ol>
                </li>
                <li>
                    <b>Security measures</b>
                    <ol>
                        <li>
                            Dares Club ensures the absolute confidentiality and privacy of the personal data. Security measures have been adopted to avoid the alteration, loss, treatment or non-authorized access to personal data, thus guaranteeing their integrity and security as
                            per Royal Decree 1720/2007, 21 December, which approves norms for the development of Organic Law (Ley Orgánica) 15/1999, 13 December for the protection of personal information.
                        </li>
                        <li>
                            Dares Club will facilitate the use of personal passwords to the User that registers in dares.club. These passwords will serve to access the exclusive services of dares.club for registered users.
                        </li>
                        <li>
                            The User must keep the password under its exclusive responsibility in the strictest and absolute confidentiality, assuming therefore how many damages or consequences of all kinds derive from the breach or disclosure of the secret.
                        </li>
                        <li>
                            The password may be modified at any time by the User. The User undertakes to notify Dares Club immediately of any unauthorized use of their password as well as access by unauthorized third parties to it.
                        </li>
                        <li>
                            Dares Club will not be responsible for any incidents arising from attack or non-authorized access to their systems which have not been detected by the security features or when it is a result of your own lack of care regarding the safeguarding of the
                            password or your own personal information.
                        </li>
                    </ol>
                </li>
            </ol>

        </div>

    </Container>)
}