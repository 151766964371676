import React from 'react';
import getText from "../../../locale/lang";
import {useLocalStorage} from "@mantine/hooks";

const StartQuests = ({info}) => {
    const [lang] = useLocalStorage({key: 'lang', defaultValue: localStorage.getItem('lang').replace(/"/g, '') || 'en'});

    return <div className="bg-dark-2" style={{marginBottom: 16, padding: 20, display: "flex", alignItems: 'flex-start', flexDirection: 'column'}}>
        <h4>{getText('startQuests')}</h4>
        <ul>
            {info.startQuestsInfo.map((item, index) => {
                const text = item[lang] === undefined || item[lang].length === 0 ? item.en : item[lang]

                return <li key={index}>{text}</li>
            })}
        </ul>
    </div>
};

export default StartQuests;