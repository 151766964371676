import {ACTIVE_SERVER, SERVER_LIST} from "./App";

let TRACKING_ID
if (window.location.href.split('/')[2] === 'dares.club') {
    TRACKING_ID = "G-B3KXT7663D";
} else {
    TRACKING_ID = "G-3TSK11Q3E3";
}

export const count = (obj) => {
    return Object.keys(obj).length;
}

export const GoogleAnalyticsButton = () => {
    const gtag = window.gtag;
    gtag('config', TRACKING_ID, {
        'page_title': document.title, 'page_location': window.location.href, 'page_path': window.location.pathname
    })
}


export const is_referral_key_exist = () => {
    return document.cookie.split('; ').find((row) => row.startsWith('referral_key='))?.split('=')[1]
}

export const getDayDate = (date) => {
    if (date.getDate() < 10) {
        return `0${date.getDate()}`
    } else {
        return date.getDate()
    }
}

export const getMonthDate = (date) => {
    if (date.getMonth() + 1 < 10) {
        return `0${date.getMonth() + 1}`
    } else {
        return date.getMonth() + 1
    }
}

export const deleteFramingSymbols = (str) => {
    if (str === undefined || str === null) return ''
    return (str).replace(/^.|.$/g, "")
}

export const getServer = () => {
    const loc_array = window.location.pathname.split('/')
    const server = loc_array[loc_array.length - 1]
    if (SERVER_LIST.includes(server)) {
        return server
    }
    return ACTIVE_SERVER
}