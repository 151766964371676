import React from "react";
import getText from "../../../locale/lang";

const BooksInfo = ({info}) => {
    return <div className="bg-dark-2" style={{marginBottom: 16, padding: 20, display: "flex", alignItems: 'flex-start', flexDirection: 'column'}}>
        <h4>{getText('booksTitle')}</h4>
        {info.booksInfo.map((book, index) => {
            return <div key={index}>
                <h5>{book.title}</h5>
                <ul>
                    {book.grades ? book.grades.map((grade, gradeIndex) => (
                        <li key={gradeIndex}>
                            {grade.grade}
                            <ul>
                                {grade.locations.map((location, locationIndex) => (
                                    <li key={locationIndex}>{location}</li>
                                ))}
                            </ul>
                        </li>
                    )) : (
                        <p>{book.description}</p>
                    )}
                </ul>
            </div>
        })}
    </div>
};

export default BooksInfo;