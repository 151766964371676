import {Background, Parallax} from "react-parallax";
import {useEffect, useState} from "react";
import parallax2 from "../img/image_2024-04-15_19-21-58.png";

export function TopParallax(props) {
    const [height, setHeight] = useState(props.page404 ? '91vh' : 600);
    const [padding, setPadding] = useState(0);
    const [paddingParallax, setPaddingParallax] = useState(135);

    const [imageSettings, setImageSettings] = useState({transform: 'translateY(-30%)'});

    useEffect(() => {
        if (props.page404) {
            return
        }

        if (window.screen.width < 650) {
            setHeight(350)
            setPadding(0)
            setPaddingParallax(170)

            setImageSettings({height: 380})
        }
    }, [props.page404])


    return (<Parallax
        strength={450}
        height={height}
        style={{paddingTop: paddingParallax}}
    >
        <Background className="custom-bg">
            <img src={parallax2} alt="fill murray" style={{backgroundPosition: 'center', ...imageSettings}}/>
        </Background>
        <div style={{background: 'linear-gradient(to top, #000 0%, rgba(6, 6, 6, 0.5) 30%)', height: height, paddingTop: padding}}>
            {props.children}
        </div>
    </Parallax>);
}
