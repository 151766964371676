export default function BurgerButton() {

    return (
        <div className="hidden-lg-up d-lg-none" id={'w_button_burger'} style={{width: '33%'}}>
            <p id="burgerButton" data-nav-toggle="#nk-nav-mobile" style={{marginBottom: 0, display: "flex", justifyContent: 'space-around'}}>
                <span className="nk-icon-burger">

                </span>
                <span className="nk-icon-burger">

                </span>
                <span className="nk-icon-burger">
                    <span className="nk-t-1"></span>
                    <span className="nk-t-2"></span>
                    <span className="nk-t-3"></span>
                </span>
            </p>
        </div>
    )
}