export default function MainInfoCell(props) {

    return (
        <div className={`nk-box-2`} style={{border: '1px solid #222222', borderRadius: 8, flexGrow: 1, padding: 24}}>
            <div className="nk-ibox" style={{display: 'flex', gap: 16, flexDirection: 'column'}}>

                <div style={{display: 'flex', gap: 8, flexDirection: 'column'}}>
                    <div className="nk-ibox-cont">
                        {props.name}
                    </div>
                    <div className="nk-ibox-cont" style={{color: '#e08821', fontWeight: 'bold', fontSize: 24}}>
                        {props.value}
                    </div>
                </div>
            </div>
        </div>
    )
}