import MainInfoCell from "../MainInfoCell";
import getText from "../../../locale/lang";
import skill_exp_sp_up from "../../../img/features_main/skill_exp_sp_up.png";
import etc_adena_i00 from "../../../img/features_main/etc_adena_i00.png";
import armor_t84_b_i00 from "../../../img/features_main/armor_t84_b_i00.png";
import skill0254 from "../../../img/features_main/skill0254.png";
import sealStone from "../../../img/features_main/sealStone.png";
import accessory_necklace_of_frintessa_i00 from "../../../img/features_main/accessory_necklace_of_frintessa_i00.png";
import etc_permit_card_i00 from "../../../img/features_main/etc_permit_card_i00.png";
import React, {useCallback, useEffect, useState} from "react";
import FeaturesButton from "../FeaturesButton";

import FeaturesInfo from "./FeaturesInfo";
import BasicInfo from "./BasicInfo";
import RatesChroniclesInfo from "./RatesChroniclesInfo";
import BossInfo from "./BossInfo";
import SkillsInfo from "./SkillsInfo";
import BooksInfo from "./BooksInfo";
import StartQuests from "./StartQuests";
import RareWeapon from "./RareWeapon";
import Sets from "./Sets";
import {isMobile} from "../../../App";
import * as remasteredInfo from "../../../locale/info/remastered/remsateredInfo";
import RareArmor from "./RareArmor";
import RareJewelry from "./RareJewelry";
import FortressAndCastle from "./FortressAndCastle";
import ScrollToTopButton from "../../ScrollToTopButton";
import Gap from "../../Gap";

const serverInfoData = {
    'remastered-x3': remasteredInfo,
}

const mainInfoData = {
    'remastered-x3': {
        exp: 'x3',
        drop: 'x3',
        spoil: 'x3',
        adena: 'x2.5',
        sealStone: 'x2.5',
        RB: 'x1',
        quest: 'x1',
    }
}

export default function Remastered({server}) {
    const [button, setButton] = useState(window.location.hash.split('#')[1] ?? 'features')
    const [serverInfo, setServerInfo] = useState();

    useEffect(() => {
        setServerInfo(serverInfoData['remastered-x3'])
        if (!isMobile) {
            if (window.location.hash.length) {
                scrollToSection()
            }
        }
    }, [server]);

    const changeTab = useCallback((id) => {
        setButton(id)
        window.location.hash = `#${id}`
        if (!isMobile) {
            scrollToSection()
        }
    }, [])

    useEffect(() => {
        const handleHashChange = () => {
            if (button !== window.location.hash.split('#')[1]) {
                changeTab(window.location.hash.split('#')[1])
            }
        }

        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [button, changeTab]);

    const mainInfo = [
        {img: skill_exp_sp_up, name: "Exp/SP", value: mainInfoData[server].exp},
        {img: armor_t84_b_i00, name: "Drop", value: mainInfoData[server].drop},
        {img: skill0254, name: "Spoil", value: mainInfoData[server].spoil},
        {img: etc_adena_i00, name: "Adena", value: mainInfoData[server].adena},
        {img: sealStone, name: "Seal Stone", value: mainInfoData[server].sealStone},
        {img: accessory_necklace_of_frintessa_i00, name: "RB", value: mainInfoData[server].RB},
        {img: etc_permit_card_i00, name: "Quest", value: mainInfoData[server].quest},
    ]

    const scrollToSection = () => {
        const headerHeight = 200;
        const element = document.querySelector('#w_button_features_features');
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
            top: elementPosition - headerHeight,
            behavior: 'smooth'
        });
    }


    return (
        <>
            <div style={{display: "flex", gap: 8, flexWrap: 'wrap'}}>
                {mainInfo.map((item, index) => (
                    <MainInfoCell key={index} img={item.img} name={item.name} value={item.value}/>
                ))}
            </div>
            <Gap/>
            <div className="row align-items-top" style={{backgroundColor: 'rgba(14, 14, 14, .9)'}}>
                <div className="col-lg-2">
                    <FeaturesButton
                        matomo_id={'w_button_features_features'}
                        active={button}
                        onClick={() => changeTab('features')}
                        name={'features'}
                        text={`${getText('featuresOf')} Dares.club`}
                    />
                    <FeaturesButton
                        matomo_id={'w_button_features_basic'}
                        active={button}
                        onClick={() => changeTab('basic')}
                        name={'basic'}
                        text={getText('basicInfo')}
                    />
                    <FeaturesButton
                        matomo_id={'w_button_features_rates_and_chronicles'}
                        active={button}
                        onClick={() => changeTab('ratesChronicles')}
                        name={'ratesChronicles'}
                        text={getText('rates')}
                    />
                    <FeaturesButton
                        matomo_id={'w_button_features_boss'}
                        active={button}
                        onClick={() => changeTab('boss')}
                        name={'boss'}
                        text={getText('bossInfo')}
                    />
                    <FeaturesButton
                        matomo_id={'w_button_features_skills'}
                        active={button}
                        onClick={() => changeTab('skills')}
                        name={'skills'}
                        text={getText('skillChanges')}
                    />
                    <FeaturesButton
                        matomo_id={'w_button_features_sets'}
                        active={button}
                        onClick={() => changeTab('sets')}
                        name={'sets'}
                        text={getText('setChanges')}
                    />
                    <FeaturesButton
                        matomo_id={'w_button_features_books'}
                        active={button}
                        onClick={() => changeTab('books')}
                        name={'books'}
                        text={getText('booksInfo')}
                    />
                    {server === 'remastered-x3' ? <FeaturesButton
                        matomo_id={'w_button_features_start_quests'}
                        active={button}
                        onClick={() => changeTab('startQuests')}
                        name={'startQuests'}
                        text={getText('startQuests')}
                    /> : null}
                    <FeaturesButton
                        matomo_id={'w_button_features_rare_weapons'}
                        active={button}
                        onClick={() => changeTab('rareWeapon')}
                        name={'rareWeapon'}
                        text={getText('rareWeapon')}
                    />
                    <FeaturesButton
                        matomo_id={'w_button_features_rare_armor'}
                        active={button}
                        onClick={() => changeTab('rareArmor')}
                        name={'rareArmor'}
                        text={getText('rareArmor')}
                    />
                    <FeaturesButton
                        matomo_id={'w_button_features_rare_jewelry'}
                        active={button}
                        onClick={() => changeTab('rareJewelry')}
                        name={'rareJewelry'}
                        text={getText('rareJewelry')}
                    />
                    <FeaturesButton
                        matomo_id={'w_button_features_fortress_and_castle'}
                        active={button}
                        onClick={() => changeTab('fortressAndCastle')}
                        name={'fortressAndCastle'}
                        text={getText('fortressAndCastle')}
                    />
                </div>
                {serverInfo !== undefined ?
                    <div className="col-lg-10">
                        <div className="row">
                            <div className="col-lg-12" style={{marginTop: 16}}>
                                {button === 'features' && <FeaturesInfo info={serverInfo}/>}
                                {button === 'basic' && <BasicInfo info={serverInfo}/>}
                                {button === 'ratesChronicles' && <RatesChroniclesInfo info={serverInfo}/>}
                                {button === 'boss' && <BossInfo info={serverInfo}/>}
                                {button === 'skills' && <SkillsInfo info={serverInfo}/>}
                                {button === 'sets' && <Sets info={serverInfo}/>}
                                {button === 'books' && <BooksInfo info={serverInfo}/>}
                                {button === 'startQuests' && <StartQuests info={serverInfo}/>}
                                {button === 'rareWeapon' && <RareWeapon info={serverInfo}/>}
                                {button === 'rareArmor' && <RareArmor info={serverInfo}/>}
                                {button === 'rareJewelry' && <RareJewelry info={serverInfo}/>}
                                {button === 'fortressAndCastle' && <FortressAndCastle info={serverInfo}/>}
                            </div>
                        </div>
                    </div> : null}
                <ScrollToTopButton/>
            </div>
        </>
    )
}