export const featuresInfo = [
    {
        en: "The server build is based on a mix of Interlude, High Five, Classic chronicles",
        ua: "Збірка сервера базується хроніках Interlude, High Five, Classic",
        ru: "Сборка сервера основана на хрониках Interlude, High Five, Classic."
    },
    {
        en: "The development system and economy are 90% based on the Interlude Chronicles.",
        ua: "Система розвитку та економіка на 90% засновані на хроніках Interlude.",
        ru: "Система развития и экономика на 90% основаны на хрониках Interlude."
    },
    {
        en: "The PvP system is based on High Five's most balanced chronicles: ~80% of the skills are taken from the High Five chronicles, ~10% are integrated from the Classic chronicles, another ~10% of the author's skills are introduced to improve all classes in both PvP and PvE.",
        ua: "Система PvP заснована на найбільш збалансованих хроніках High Five. Блиьзко 80% скілів взяті з хронік High Five, 10% - інтегровано із хронік Classic, ще введено 10% авторських скілів для поліпшення всіх класів як у PvP, так і в PvE.",
        ru: "Система PvP основана на наиболее сбалансированных хрониках High Five. Около 80% скилов взято из хроник High Five, 10% - интегрировано из хроник Classic, ещё 10% авторских навыков внесено для улучшения всех классов как в PvP, так и в PvE."
    },
    {
        en: "Shadow weapons removed, but alternative <a href='https://dares.club/features/remastered-x3#startQuests' rel='noopener noreferrer'>quest</a> is provided for NG and D grade weapons.",
        ua: "Тіньову зброю видалено, але для зброї рангу NG і D передбачено альтернативний <a href='https://dares.club/features/remastered-x3#startQuests' rel='noopener noreferrer'>квест</a>.",
        ru: "Теневое оружие удалено, но предусмотрен альтернативный <a href='https://dares.club/features/remastered-x3#startQuests' rel='noopener noreferrer'>квест</a> для оружия рангов NG и D."
    },
    {
        en: "A maximum of 2 windows can be from one PC. With a premium account, you can launch 1 more window. For each window on which there is a premium account, it is allowed to launch +1 window",
        ua: "Дозволяється запуск максимум 2 вікон з одного ПК. Із преміум акаунтом можна запустити ще 1 вікно. За кожне вікно, на якому присутній преміум акаунт, дозволяється запуск +1-го вікна",
        ru: "Разрешается запуск максимум 2 окон с одного ПК. С премиум аккаунтом можно запустить еще 1 окно. За каждое окно, на котором присутствует премиум аккаунт, разрешается запуск +1-го окна"
    },
    {
        en: "Monster info is available in in-game database",
        ua: "Інформація про монстрів доступна у внтурішньоігровій базі даних.",
        ru: "Информация о монстрах доступна во внутриигровой базе данных."
    },
    {
        en: "Removal of buffs Alt+Click",
        ua: "Зняття бафів за допомогою комбінації Alt+Клік",
        ru: "Удаление баффов с помощью комбинации Alt+Клик"
    },
    {
        en: "Convenient NPC buffer, the entire buff lasts for 1 hour. Starting from lvl 70 Symphonies are available but their power is 50% of originals.",
        ua: "Зручний NPC-бафер, тривалість бафів 1 година. Починаючи з 70 рівня доступні Симфонії, але їх потужність становить 50% від оригіналів.",
        ru: "Удобный NPC-бафер, длительность бафов 1 час. Начиная с 70 уровня доступны Симфонии, но их мощность составляет 50% от оригиналов."
    },
    {
        en: "Premium Buffs and Premium Teleports available when buying a Dares Premuim Card (for voting or through donations). Free to use up to 40 lvl.",
        ua: "Преміум-бафи та Преміум телепорти доступні при купівлі Dares Premuim Card (за голосування або через пожертви). Безкоштовне використання до 40 лвл.",
        ru: "Премиум-бафы и Премиум телепорты доступны при покупке Dares Premuim Card (через голосование или через пожертвования). Бесплатное использование до 40 лвл."
    },
    {
        en: "AutoLoot is available via AutoLoot Card. You can obtain it for Vote Coins or Dares Coins",
        ua: "Автолут доступний через AutoLoot Card. Можна отримати за Vote Coins та Dares Coins",
        ru: "Автолут доступен через AutoLoot Card. Можно получить за Vote Coins или Dares Coins."
    },
    {
        en: "No MP potions, but a special skill (added fo every class at 1 lvl) can be used to restore 50% mana in 15 seconds. Fixed skill cooldown 3 minutes.",
        ua: "MП-банки відсутні, але за допомогою спеціального скіла (доданий усім класам з 1го рівня) можна відновлювати 50% мани за 15 секунд. Фіксований відкат скіла 3 хвилини.",
        ru: "MP-банки отсутствуют, но с помощью специального скила (добавлен всем класам с 1го уровня) можно восстановить 50% маны за 15 секунд. Фиксированый откат скила 3 минуты."
    },
    {
        en: "Offline store and craft with the help of the .offline command",
        ua: "Офлайн магазин та крафт за допомогою команди .offline",
        ru: "Офлайн магазин и крафт с помощью команды .offline"
    },
    {
        en: "Level difference in party to get exp = 9 ",
        ua: "Різниця в рівнях у групі, щоб отримати досвід = 9",
        ru: "Разница уровней в группе для получения опыта = 9"
    },
    {
        en: "Invulnerability after teleportation - 3 seconds",
        ua: "Невразливість після телепортації - 3 секунди",
        ru: "Неуязвимость после телепортации - 3 секунды."
    },
    {
        en: "Multiple Life Stones have been replaced with a single no-lvl (Average/Mid/High/Top) can be obtained form Life Stone Box, which drops from monsters, Raid Bosses and Epic Bosses",
        ua: "Лайф стоуни усіх рівнів замінені на єдиний безрівневий (звичайний/мід/хай/топ), їх можна отримати при відкритті Life Stone Box, котрі випадають  з певним шансом із монстрів, рейд боссів та епік боссів.",
        ru: "Лайф стоуны всех уровней заменены единственными безуровневым (обычный/мид/хай/топ), можно получить с Life Stone Box, которые выпадают с определенным шансом с монстров, рейд боссов и эпик боссов"
    },
    {
        en: "The Sub-skills system is implemented and revised. Every 5 levels from 55 you can get a book to learn basic skill with level 75 teaching class skill",
        ua: "Вдосконалено систему саб-скілів. Кожні 5 рівнів, починаючи з 55-го ви можете отримати книгу, щоб вивчити базові скіли.На 75 рівні видається книга для вивчення класового саб-скіла.",
        ru: "Усовершенствовано систему саб-скилов. Каждые 5 уровней, начиная из 55-го вы можете получить книгу для изучения базовых скилов. На 75 уровне выдается книга для изучения класового саб-скила."
    },
    {
        en: "The rare item system has been improved, the chance to get a rare item during crafting from B grade and above is 2%, for grades below, the chance is 2% for doublecraft. Unidentified items also drop from monsters and bosses, when opening them with a double click, there is a 2% chance to get a rare item.",
        ua: "Вдосконалено систему рідкісних предметів(рар), шанс отримати рідкісний предмет під час крафту від B grade та вище становить 2%, для грейдів нище, шанс 2% на даблкрафт. Також із монстрів та босів випадають предмети unidentified, при розкритті їх подвійним кліком, є шанс 2% отримати рідкісний предмет.",
        ru: "Усовершенствована система редких предметов(рар), шанс получить редкий предмет во время крафта от B grade и выше составляет 2%, для грейдов ниже, шанс 2% на даблкрафт. Также из монстров и боссов выпадают предметы unidentified, при раскрытии их двойным щелчком, есть шанс 2% получить редкий предмет."
    },
    {
        en: "Reworked weapon SA system to upgrade all weapons to suit your playstyle. Level 1-13 leveling on monsters and raid bosses. Can also be purchased from Giran Luxury Shop for adena + Sealed Soul Crystal Fragments (drops from level 40+ raid bosses)",
        ua: "Перероблена система SA на зброю, щоб оновити всю зброю під ваш стиль гри. 1-13 рівень прокачка на монстрах та рейд босах. Також можна придбати в Giran Luxury Shop за адену + Sealed Soul Crystal Fragments (випадають з рейд боссів рівня 40+)",
        ru: "Обработанная система SA на оружие, чтобы обновить все оружие под ваш стиль игры. 1-13 уровень прокачки на монстрах и рейд боссах. Также можно приобрести в Giran Luxury Shop за адену + Sealed Soul Crystal Fragments (выпадают из рейд боссов уровня 40+)"
    },
    {
        en: "Reworked bonuses of certain sets of <a href='https://dares.club/features/remastered-x3#sets'  rel='noopener noreferrer'>armor</a> to update them for your play style",
        ua: "Перероблені бонуси певних комплектів <a href='https://dares.club/features/remastered-x3#sets'  rel='noopener noreferrer'>броні</a>, щоб оновити їх під ваш стиль гри.",
        ru: "Переработаны бонусы некоторых комплектов <a href='https://dares.club/features/remastered-x3#sets'  rel='noopener noreferrer'>брони</a>, чтобы обновить их под ваш стиль игры."
    },
    {
        en: "Maximum level 81",
        ua: "Максимальний рівень 81",
        ru: "Максимальный уровень 81"
    },
    {
        en: "The attribute system works according to the High Five chronicles",
        ua: "Система атрибутів працює по High Five хроніках.",
        ru: "Система атрибутов работает по хроникам High Five."
    },
    {
        en: "To learn skills, <a href='https://dares.club/features/remastered-x3#books'  rel='noopener noreferrer'>books</a> are needed, which come in two types (Warrior + Magic), obtainable from monsters",
        ua: "Для вивчення скілів потрібні <a href='https://dares.club/features/remastered-x3#books'  rel='noopener noreferrer'>книги</a> двох типів (Воїн та Маг), випадають з монстрів",
        ru: "Для изучения скилов необходимы <a href='https://dares.club/features/remastered-x3#books'  rel='noopener noreferrer'>книги</a> двух типов (Воин + Маг), выпадают с монстров."
    },
    {
        en: "Skill enchant system have been reworked and works like on High Five chronicles with changes on level 81 (same chances as 85 on High Five chronicles)",
        ua: "Система заточки скілів була перероблена і працює згідно хронік High Five зі змінами на 81 рівні (шанси такі ж, як на 85 рівні хронік High Five)",
        ru: "Система заточки скилов была переработана и работает согласно хроникам High Five с изменениями на 81 уровне (те же шансы, что и на 85 уровне хроник High Five)."
    },
    {
        en: "The Labyrinth and Kamaloka will be available on the server",
        ua: "Реалізовано Лабіринт і Камалока.",
        ru: "Реализовано Лабиринт и Камалока."
    },
    {
        en: "A system of Forts and Clan Halls has been implemented, for the possession of which you get certain <a href='https://dares.club/features/remastered-x3#fortressAndCastle'  rel='noopener noreferrer'>clan skills</a>",
        ua: "Реалізована система Фортів і Клан Холлів, за володіння якими ви отримуєте певні <a href='https://dares.club/features/remastered-x3#fortressAndCastle'  rel='noopener noreferrer'>кланові скіли.</a>",
        ru: "Реализована система Фортов и Клан Холлов, за владение которыми вы получаете определенные <a href='https://dares.club/features/remastered-x3#fortressAndCastle'  rel='noopener noreferrer'>клановые скилы.</a>"
    },
    {
        en: "A maximum of 3 sub-classes",
        ua: "Максимальна кількість саб-класів - 3",
        ru: "Максимальное количество саб-класов - 3"
    },
    {
        en: "The sub class requires the completion of the High Five chronicles quest",
        ua: "Отримання саб-класу - згідно квесту на хроніках High Five.",
        ru: "Получение саб-класа - согласно квесту на хрониках High Five."
    },
    {
        en: "Noblesse status complete quest by Interlude, but if the party doesn't finish the Raid Boss, a shard is given, 10 shards automatically transform into a quest item",
        ua: "Статус дворянина згідно квесту на хроніках Interlude. Якщо група не вбиває рейд босса, то всім її учасниками видається уламок. 10 уламків автоматично перетворюються на необхідний квестовий ітем.",
        ru: "Статус дворянина согласно квесту на хрониках Interlude. Если группа не убивает рейд босса, то всем ее участникам дается осколок. 10 осколков автоматически превращаются в необходимый квестовый итем."
    },
    {
        en: "Buffs from Hot Springs can no longer be obtained from monsters, but there is an option to buy a bottle with this buff from an NPC for items that drop in this location from all monsters",
        ua: "Бафи з Hot Springs більше не можна отримати з монстрів. Але є можливість купити пляшку з Hot Springs-бафами на вибір у спеціального NPC за предмети, які випадають з усіх монстрів у цій ж локації",
        ru: "Бафы из Hot Springs больше нельзя получить с монстров. Но есть возможность купить бутылку с Hot Springs-бафами на выбор у специального NPC за предметы, которые выпадают со всех монстров в этой же локации."
    },
    {
        en: "Revised ACP-System which works from pannel and allows for CP/HP potions and elixirs",
        ua: "Вдосконалена ACP-System, яка працює з панелі скілів та дозволяє використовувати зілля та еліксири CP/HP",
        ru: "Пересмотренная ACP-System, которая работает с панели и позволяет использовать зелья CP/HP и эликсиры."
    },

]


export const basicInfo = [
    {
        img: null,
        en: {
            title: "Changing profession",
            description: [
                "1st - quest: reward - 1 ticket for top NG weapon; or u can buy it in Prof Manager, price 150 000 adena or 15 Dares coins",
                "2nd - quest: reward ticket for top D weapon and 1 500 000 adena. Alternative quest: NPC Daeger in Giran Blacksmith's Shop, price 1 500 000 adena. Reward: 3 marks needed to obtain second class transfer and ticket for top D weapon. Example: you have obtained 2 marks passing the 2 main quests, if you don't want to complete 3rd main quest, you can complete alternative quest in Daeger to receive 3rd mark, you will be charged in 1 500 000 adena, but for each available mark at the end of the quest you will be returned for 500 000 adena (for 2 available marks 1 000 000 adena will be returned). So price for this quest will be 500 000 adena. Also you can obtain 2nd profession for 50 Dares Coins",
                "3rd - full quest"
            ]
        },
        ua: {
            title: "Зміна професії",
            description: [
                "1-а профа - по квесту: нагорода - 1 купон на топ NG зброю; або покупка у Proff Manager, вартість 150 000 аден",
                "2-й - квест: нагорода - купон на топ D зброю і 1 500 000 аден. Альтернативний квест: NPC Daeger в Giran Blacksmith's Shop, ціна 1 500 000 аден. Нагорода: 3 марки, необхідні для отримання 2 профи та купон на топ D зброю. Приклад: ви отримали 2 марки, пройшовши основні 2 квести, якщо ви не хочете проходити 3-ій квест, ви можете завершити альтернативний квест у NPS Daeger, щоб отримати 3-тю марку, з вас буде стягнуто 1 500 000 аден, але за кожну наявну марку в кінці квесту вам повернеться по 500 000 аден (за 2 доступні позначки буде повернено 1 000 000 аден). Таким чином, ціна цього квесту складе 500 000 аден.Також ви можете отримати другу профу за Dares Coins",
                "3-я профа - повний квест"
            ]
        },
        ru: {
            title: "Смена профессии",
            description: [
                "1-я профа - по квесту: награда - 1 купон на топ NG оружие; или покупка в Proff Manager, стоимость 150 000 аден",
                "2-й - квест: награда -  купон на топ D оружие и 1 500 000 аден.Альтернативный квест: NPC Daeger в Giran Blacksmith's Shop, цена 1 500 000 аден. Награда: 3 марки, необходимые для получения 2 профы и купон на топ D оружиеПример: вы получили 2 марки, пройдя основных 2 квеста, если вы не хотите проходить 3-ий квест, вы можете выполнить альтернативный квест в NPC Daeger, чтобы получить 3-ю марку, с вас будет списано 1 500 000 аден, но за каждую имеющуюся марку по окончании квеста вам вернут по 500 000 аден (за 2 имеющиеся отметки вернут 1 000 000 аден). Таким образом цена этого квеста составит 500 000 аден.Также вы можете получить 2-ю профессию за Dares Coins.",
                "3-я профа - полный квест"
            ]
        }
    },
    {
        img: null,
        en: {
            title: "Talisman of Baium",
            description: [
                "Talisman of Insolence Box (used to buy and upgrade Talisman of Insolence) drop from monsters, raid bosses and Baium in ToI",
                "Baium Soul drops from Cherub Galaxia (5% chance) & Baium (100% chance), it's needed to improve Talisman of Insolence (lvlv 6) to Talisman of Baium"
            ]
        },
        ua: {
            title: "Талісман Баюма",
            description: [
                "Talisman of Insolence Box (використовується для покупки та покращення Talisman of Insolence) випадає з монстрів, Raid bosses і Baium у ToI.",
                "Baium Soul випадає з Cherub Galaxia (імовірність 5%) і Baium (імовірність 100%), необхідно для покращення Talisman of Insolence (6-го рівня) до Talisman of Baium",

            ]
        },
        ru: {
            title: "Талисман Баюма",
            description: [
                "Коробка с Талисманом Дерзости (используется для покупки и улучшения Talisman of Insolence) выпадает с монстров, Raid bosses и Baium в ToI.",
                "Baium Soul выпадает из Cherub Galaxia (5% шанс) и Баюма 100% (шанс), необходимо для улучшения Talisman of Insolence (6-го уровня) до Talisman of Baium",

            ]
        }
    },
    {
        img: null,
        en: {
            title: "SA upgrade",
            description: [
                "1-13 lvl up from monsters and Raid Boss.",
                "Also can be purchased in Giran Luxury Shop for adena + Sealed Soul Crystal Fragments (drops from Raid Bosses lvl 40+)",
            ]
        },
        ua: {
            title: "Прокачка SA",
            description: [
                "1-13 рівень прокачка на монстрах та рейд босах.",
                "Також можна придбати в Giran Luxury Shop за адену + Sealed Soul Crystal Fragments (випадають з рейд боссів рівня 40+)",
            ]
        },
        ru: {
            title: "Прокачка SA",
            description: [
                "1-13 уровень только прокачка на монстрах и Raid Boss.",
                "Так же можно приобрести в Магазине Гирана за адену + Sealed Soul Crystal Fragments (выпадают с рейд боссов уровня 40+)",
            ]
        }
    },
    {
        img: null,
        en: {
            title: "Giants Codex",
            description: [
                "Drop from Raid Boses lvl 76+ (drops only 1 item, chance 100%)",
                "Drop from Raid Bosses in Four Sepulhcers (Four Goblets Quest) drops 1-3 items, 100% chance",
                "Can exchange for Giants Codex Mastery (Bless Skill Enchant) in Giran NPC Kuram",
                "PVP zone near NPC 4 Goblets",
                "There is also an opportunity, during the siege of the forts, for killing the guards to get an “Epaulette” in the form of a drop, which can be exchanged in Giran at the NPC Kuram for the usual Giants Codex (1 book = 1.5kk adena + 1500 Epaulette)"
            ]
        },
        ua: {
            title: "Giants Codex",
            description: [
                "Випадає з Raid Boses рівня 76+ (дропається лише 1 предмет, шанс 100%)",
                "Випадає з Raid Bosses в Four Sepulchers (Квест Four Goblets) випадає 1-3 предмета, шанс 100%",
                "Giants Codex можна обміняти на Giants Codex Mastery (Bless Skiil Enchant) в Giran NPC Kuram.",
                "Зона PVP біля NPC 4 Goblets",
                "Також є можливість, під час осади фортів, за вбивства охоронців отримати у вигляді дропу “Epaulette”, які можна обміняти в Гірані у NPC Kuram на звичайні Giants Codex(1книга = 1.5кк адени + 1500 Epaulette)",
            ]
        },
        ru: {
            title: "Giants Codex",
            description: [
                "Выпадает с Raid Boses 76+ уровня (выпадает только 1 предмет, шанс 100%)",
                "Выпадает из Raid Bosses в Four Sepulchers (Квест Four Goblets) 1-3 предмета, шанс 100%",
                "Giants Codex можно обменять на Giants Codex Mastery (Bless Skill Enchant) в Giran NPC Kuram.",
                "ПВП-зона возле NPC 4 Кубка",
                "Также есть возможность, во время осады фортов, при убийствах охранников получить в виде дропа \"Epaulette\", которые можно обменять в Гиране в NPC Kuram на обычные Giants Codex (1книга = 1.5кк адены + 1500 Epaulette)"
            ]
        }
    },
    {
        img: null,
        en: {
            title: "Fame system",
            description: [
                "You can upgrade your armor and weapon in Giran NPC Rapidus",
                "Fame is obtained by participating in sieges, or by exchanging Daily coins, which can be obtained by completing daily tasks.",
            ]
        },
        ua: {
            title: "Fame system",
            description: [
                "Ви можете покращити свою броню та зброю вставивши в Giran NPC Rapidus",
                "Fame дістається шляхом участі у облогах, або обміну Daily coins, які можна отримати, виконуючи кожнодневні завдання."
            ]
        },
        ru: {
            title: "Fame system",
            description: [
                "Вы можете улучшить свою броню и оружие в Giran NPC Rapidus.",
                "Fame достается путем участия в осадках или обмена Daily coins, которые можно получить, выполняя ежедневные задания.",
            ]
        }
    },
    {
        img: null,
        en: {
            title: "Subclass",
            description: [
                "Sub-class quest is based on HighFive chronicles system",
                "Sub-class can be changed in town using Character Status window (Alt+T)"
            ]
        },
        ua: {
            title: "Subclass",
            description: [
                "Квест на sub-class базується на системі із хронік HighFive",
                "Sub-class можна змінити в місті за допомогою вікна статусу персонажа (Alt+T)"
            ]
        },
        ru: {
            title: "Subclass",
            description: [
                "Квест на sub-class основан на системе из хроник HighFive.",
                "Sub-class можно сменить в городе с помощью окна статуса персонажа (Alt+T)."
            ]
        }
    },
    {
        img: null,
        en: {
            title: "Subclass Certification",
            description: [
                "Sub-skills can learn in Ivory Tower, don't need quest. new system learning",
                "Updated system for Sub-Class Ability skills (starting at level 50 and to level 80 every 5 levels with new skills",
                "Sub-Class Ability – Physical Attack",
                "Sub-Class Ability – Magical Attack",
                "Sub-Class Ability – Physical Defence",
                "Sub-Class Ability – Magical Defence",
                "Sub-Class Ability – Acumen",
                "Sub-Class Ability – Focus",
                "Sub-Class Ability – Wind Walk",
                "Master Abilities and Class Abilities share the same book and can be learned on level 75"
            ]
        },
        ua: {
            title: "Subclass Certification",
            description: [
                "Sub-skills можна вивчити в Ivory Tower. Квест не потрібен. Нова система вивчення.",
                "Оновлена ​​система саб-скілів (починаючи з 50 рівня і до 80 рівня кожні 5 рівнів з новими навичками",
                "Sub-Class Ability – Physical Attack",
                "Sub-Class Ability – Magical Attack",
                "Sub-Class Ability – Physical Defence",
                "Sub-Class Ability – Magical Defence",
                "Sub-Class Ability – Acumen",
                "Sub-Class Ability – Focus",
                "Sub-Class Ability – Wind Walk",
                "Master Abilities and Class Abilities можна вивчити за допомогою однієї книги взятої на 75 рівні"
            ]
        },
        ru: {
            title: "Subclass Certification",
            description: [
                "Sub-skills можно выучить в Ivory Tower. Квест не нужен. Новая система изучения.",
                "Обновлена ​​система навыков способностей подкласса (начиная с уровня 50 и до уровня 80 каждые 5 уровней с новыми навыками).",
                "Sub-Class Ability – Physical Attack",
                "Sub-Class Ability – Magical Attack",
                "Sub-Class Ability – Physical Defence",
                "Sub-Class Ability – Magical Defence",
                "Sub-Class Ability – Acumen",
                "Sub-Class Ability – Focus",
                "Sub-Class Ability – Wind Walk",
                "Master Abilities and Class Abilities можно изучить с помощью одной книги взятой на 75 уровне"
            ]
        }
    },
    {
        img: null,
        en: {
            title: "Nobless",
            description: [
                "Respawn Noblesse RB = 8 hours (+- 30 minutes)",
                "Noblesse RB and security - 80 lvl",
                "To become a Nobless, you need to complete a quest and kill Barakiel. Quest Staff is given to party on Last Hit. Anyone who was alive during Raid Boss Barakiel death in 1500 range from Barakiel and is Alive will get a 1-3 piece of staff. 10 Pieces will give a full staff",
                "Reward for Noblesse status is Summon Bracer (6 slots for talisman, skill: Summon Friend, can summon anyone who have this bracer) coldown 60min"
            ]
        },
        ua: {
            title: "Дворянство",
            description: [
                "Відродження Noblesse Raid Boss = 8 годин (+- 30 хвилин)",
                "Noblesse Raid Boss і охорона - 80 рівня",
                "Щоб стати Noblesse, вам потрібно виконати квест і вбити Raid Boss Barakiel. Квестовий посох дається для гравців у групі котра нанесе останній удар. Будь-хто, хто був живий під час смерті Raid Boss Barakiel в радіусі 1500 від Barakiel - отримає від 1 до 3 частин посоха. 10 частин посоха дадуть повноцінний квестовий посох.",
                "Нагорода за статус Noblesse - Summon Bracer (Браслет на 6 слотів для талісманів та скіл: Summon Friend, дає можливість викликати члена група хто також має цей браслет), час перезарядки скіла 60хв."
            ]
        },
        ru: {
            title: "Дворянство",
            description: [
                "Возрождение Noblesse Raid Boss = 8 часов (+- 30 минут)",
                "Noblesse Raid Boss и охрана - 80 уровня",
                "Чтобы стать Noblesse, вам нужно выполнить квест и убить Raid Boss Barakiel. Квестовый посох дается всем учасникам групы которая нанесет последний удар. Любой, кто был жив во время смерти Raid Boss Barakiel в радиусе 1500 от Barakiel, получит от 1 до 3 часте посоха. 10 частей посоха дадут полноценный квестовый посох",
                "Награда за статус Noblesse - Summon Bracer (Браслет на 6 слотов для талисманов и скил: Summon Friend, дает возможность призвать члена группы который так же имеет этот браслет), время перезарядки скила - 60 минут"
            ]
        }
    },
    {
        img: null,
        en: {
            title: "Siege",
            description: [
                "Siege every week; Sunday 16:00 and 20:00 UTC+3",
                "Bonus Clan Skills are given for owning a Castle, Fort, or Clan Hall"
            ]
        },
        ua: {
            title: "Облога",
            description: [
                "Облога кожного тижня; Неділя 16:00 і 20:00 UTC+3 ",
                "Бонусні клан-скіли видаються за володіння замком, фортом або клан-холлом"
            ]
        },
        ru: {
            title: "Осада",
            description: [
                "Осада каждую неделю; Воскресенье 16:00 и 20:00 UTC+3",
                "Бонусные клан-скилы выдаются за владение замком, фортом или клан-холлом."
            ]
        }
    },
    {
        img: null,
        en: {
            title: "Olympiad",
            description: [
                "Duration of the Olympics: from 18:00 to 22:00 UTC+3 Thursday - Sunday",
                "Hero status every Monday at 00:00",
                "The olympiad period last one week",
                "HWID protection",
                "Classless battles require at least 5 players to register. Class battles from 10 players",
                "The minimum number of battles to be included in the list of battles for the title of hero is 10. Of them, the minimum number of victories is 1",

            ]
        },
        ua: {
            title: "Олімпіада",
            description: [
                "Тривалість Олімпіади: з 18:00 до 24:00 UTC+3 з четверга по неділю",
                "Видача геройства щопонеділка о 00:00",
                "Період олімпіади триває тиждень",
                "Захист по HWID",
                "Безкласові бої потребують не менше 5 зареєстрованих учасників. Класові бої від 10 учасників.",
                "Мінімальна кількість боїв для включення в список за звання героя - 10. З них мінімальна кількість перемог - 1",

            ]
        },
        ru: {
            title: "Олимпиада",
            description: [
                "Продолжительность Олимпиады: с 18:00 до 24:00 UTC+3 с четверга по воскресенье",
                "Выдача геройства каждый понедельник в 00:00",
                "Период олимпиады длится неделю",
                "Защита по HWID",
                "Внеклассовые бои требуют как минимум 5 зарегистрированых участников. Классовые бои от 10 участников.",
                "Минимальное количество боев для включения в список на звание героя – 10. Из них минимальное количество побед – 1",

            ]
        }
    },
    {
        img: null,
        en: {
            title: "Augmentation (Life Stone)",
            description: [
                "A chance to gain a skill from an augment: Top LS 76 - 10%, High LS 76 - 7%, Mid LS 76 - 3%, LS 76 - 1%"
            ]
        },
        ua: {
            title: "Augmentation (Life Stone)",
            description: [
                "Шанс отримання скіла від вставки Life Stone: Top LS - 10%, High LS - 7%, Mid LS - 3%, LS - 1%"
            ]
        },
        ru: {
            title: "Augmentation (Life Stone)",
            description: [
                "Шанс получения скила от вставки Life Stone: Top LS - 10%, High LS - 7%, Mid LS - 3%, LS - 1%"
            ]
        }
    },
    {
        img: null,
        en: {
            title: "Maximum enchant",
            description: [
                "Safe Enchantment +3 (+4 Full body)",
                "Weapons +20, armor +14, jewelry +14",
                "Balanced chance to improve",
                "The odds are the same for normal and Blessed scrolls",
                "NPC Kuram in Giran can exchange enchant scroll for bless enchant scroll(15 scrols some adena and some AA for 1 bless scroll)",
                "Improved enchant armor bonus system, starting from +4 to +10"
            ]
        },
        ua: {
            title: "Максимальна заточка",
            description: [
                "Безпечна заточка +3 (+4 суцільна броня)",
                "Зброя +20, броня +14, біжутерія +14",
                "Збалансований шанс заточки:",
                "Шанси однакові для звичайних і блесс скролів",
                "NPC Kuram у Гірані може обміняти звичайний скрол на блес скрол (15 звичайних скролів, трохи аден і трохи АА за 1 блесс скрол)",
                "Покращена система бонусів за заточку броні, починаючи з +4 до +10"
            ]
        },
        ru: {
            title: "Максимальная заточка",
            description: [
                "Безопасная заточка +3 (+4 сплошная броня)",
                "Оружие +20, броня +14, бижутерия +14.",
                "Сбалансированный шанс заточки",
                "Шансы одинаковы для обычных и блесс скролов.",
                "NPC Kuram в Гиране может обменять обычный скрол на блесс скрол (15 обычных скролов, немного аден и немного АА за 1 блесс скрол)",
                "Улучшена система бонусов заточки, начиная с +4 до +10"
            ]
        }
    },
    {
        img: null,
        en: {
            title: "Server currency",
            description: [
                "Vote Coin - can be obtained by voting for our server at NPC Vote Manager in towns.",
                "Dares Coin - can be obtained by supporting for our server. 10 Dares Coins = 1 EUR"
            ]
        },
        ua: {
            title: "Валюти сервера",
            description: [
                "Vote Coin - можна отримати, проголосувавши за наш сервер через NPC Vote Manager у містах.",
                "Dares Coin - можна отримати, підтримуючи наш сервер. 10 монет Dares = 1 євро"
            ]
        },
        ru: {
            title: "Валюти сервера",
            description: [
                "Vote Coin - можно получить, проголосовав за наш сервер через NPC Vote Manager в городах.",
                "Dares Coin — можно получить, поддержав наш сервер. 10 монет Dares = 1 евро"
            ]
        }
    },
    {
        img: null,
        en: {
            title: "The Seven Signs",
            description: [
                "Permanently available NPC Meow in Giran, Monday 18:00 to Friday 18:00 everything x3 prices, Friday 18:00 to Monday 18:00 everything standard prices. Mammon services of Exchange and Upgrade weapons available when a certain Seal wins.",
            ]
        },
        ua: {
            title: "The Seven Signs",
            description: [
                "Постійно доступний NPC Meow в Гірані, з понеділка 18:00 по п'ятницю 18:00 ціни на все х3, з п'ятниці 18:00 по понеділок 18:00 ціни на усе стандартні. Послуги Маммона для обміну та покращення зброї доступні, коли виграє певна печатка.",
            ]
        },
        ru: {
            title: "The Seven Signs",
            description: [
                "Постоянно доступный NPC Meow в Гиране, с понедельника 18:00 по пятницу 18:00 цены на все х3, с пятницы 18:00 по понедельник 18:00 цены на все стандартные. Услуги Mammon по обмену и улучшению оружия доступны при победе определенной печати.",
            ]
        }
    }
]

export const ratesInfo = {
    rates: [
        'EXP/SP: x3',
        'Drop: x3',
        'Spoil: x3',
        'Adena: x2.5',
        'Seal Stone: x2.5',
        'Raid Boss: x1',
    ],
    premiumRates: [
        '+30%',
        '+ 1 window',
    ]
}

export const setChanges = [
    {
        title: 'D-Grade',
        description: [
            {
                title: 'Heavy',
                description: [
                    'Mithril Heavy Armor Set: Resist Bow +5%, Max HP +80, P. Def. +5%. With Shield: Max HP +126',
                    'Brigandine Set: P. Atk. Spd. +2%, P. Atk. +3%, P. Def. +5%, Max HP +160. With Shield: Max HP +20',
                    'Revision Heavy Armor Set(Quest): M. Def. +3%, P. Def. +3%, CON -1, STR +1, Max HP +185',
                    'NEW:Half Plate Armor Set: P. Def +5%, Max HP +250, CON +1, STR -1. With Shield: Shield Def. Rate +24%, CON +1',
                ]
            },
            {
                title: 'Light',
                description: [
                    'Reinforced Leather Set: Max MP +80, P. Atk. +5%',
                    'Manticore Set: P. Atk. Spd. +2%, Run Spd. +3, P. Accuracy +4, DEX +1, CON -1',
                    'Revision Light Armor Set(Quest): P. Atk. +2%, P. Crit. Dmg. +3%, CON -1, DEX +1',
                    'NEW:Salamander Skin Armor Set: STR +1, CON -1, P. Atk. +5%',
                ]
            },
            {
                title: 'Robe',
                description: [
                    'Knowledge Set: M. Atk. +10%, Cast. Spd. +10%, MP Regen. -5%',
                    'Mithril Set: INT -1, WIT +1, Run Spd. +7, Cast. Spd. +10%',
                    'Revision Robe Set(Quest): Cast. Spd. +7%, Run Spd. +5, INT -1, WIT +1',
                    'NEW:Sage\'s Armor Set: MP Reg. +10%, Max MP +200, MEN +2, INT -2',
                ]
            },

        ]
    },
    {
        title: "C-Grade",
        description: [
            {
                title: "Heavy",
                description: [
                    "Chain Mail Set: P. Def +6%, STR +2, CON -2. With Shield: Max HP +198",
                    "Composite Heavy Armor Set: M. Def +5%, Run Spd. +5, Weight Limit +5759. With Shield: M. Def. +10%",
                    "Full Plate Set: P. Atk. +3%, M. Def. +7%, Run Spd. +3%, Max HP +270, STR +1. With Shield: Shield Defense Rate +5.26%",
                    "NEW:Dwarven Chain Mail Armor Set: P. Atk +10%, STR +1, CON -1. With Shield: Max HP +198"
                ]
            },
            {
                title: "Light",
                description: [
                    "Mithril Light Armor Set: P. Evasion +4. M. Skill Evasion +4%, Run Spd. +4, P. Atk. Spd +4",
                    "Plated Leather Set: STR +4, CON -1",
                    "Theca Leather Light Armor Set: P. Def. +10%, Run Spd. +7, DEX +2",
                    "Drake Leather Set: M. Def. +10%, Cast. Spd. +15%, DEX +2"
                ]
            },
            {
                title: "Robe",
                description: [
                    "Karmian Set: P. Def. +5%, Casting Spd. +15%",
                    "Demon's Set: INT +4, WIT -1, Max HP -270, M. Crit. Rate +25%",
                    "Divine Set: Max MP +200, WIT +2, INT -2, P. Def. +7%, MP Reg. +10%, Heal Power +60"
                ]
            }
        ]
    },
    {
        title: "B-Grade",
        description: [
            {
                title: "Heavy",
                description: [
                    "Zubei's Heavy Armor Set: P. Def. 6%, Max HP +300, Reflect Damage +5%, Paralysis Resistance +30%",
                    "Avadon Breastplate Heavy Armor Set: Max HP +300, P. Accuracy +3, M. Def. +7%, Healing Received +15%, Mental Resist +30%. With Shield: Shield Defense Rate +24%",
                    "Blue Wolf Heavy Armor Set: Run Spd. +7, HP Reg. +12%, STR +3, CON -1, DEX -2, Stun Resistance +30%",
                    "Doom Heavy Armor Set: Max HP +320, CON +3, STR -3, P. Def. +4%, Sleep/Root Resistance 30%. With Shield: Shield Defense Rate +35%"
                ]
            },
            {
                title: "Light",
                description: [
                    "Zubei's Leather Shirt Light Armor Set: P. Atk. Spd. +4%, Run Spd. +4, P. Evasion +4, Max Weight +7000, Paralysis Resistance +30%",
                    "Avadon Light Armor Set: M. Def. +8%, P. Atk. +5%, Run Spd. +7, Max Weight +6000, Mental Resist +30%",
                    "Blue Wolf Light Armor Set: P. Def. +6%, Cast. Spd. +15%, MEN +3, INT -2, WIT -1, Stun Resistance +30%",
                    "Doom Light Armor Set: P. Crit. Rate +10, P. Atk. Spd. +6%, DEX +3, STR -1, CON -2, Sleep/Root Resistance +30%"
                ]
            },
            {
                title: "Robe",
                description: [
                    "Tunic of Zubei Robe Set: M. Atk. +10%, Cast. Spd. +10%, INT +1, WIT +1, MEN -2, Paralysis Resistance +30%",
                    "Avadon Robe Set: P. Def. +10%, Cast. Spd. +15%, Mental Resistance +30%",
                    "Blue Wolf Robe Set: Max MP +336, MP reg. +9%, M. Def. +8%, WIT +3, MEN -1, INT -2, Stun Resistance +30%",
                    "Doom Robe Set: Cast. Spd. +15%, P. Def. +8.5%, MEN +1, WIT +2, INT -3, M. Skill Reuse -5%, Run Spd. +7"
                ]
            }
        ]
    },
    {
        title: "A-Grade",
        description: [
            {
                title: "Heavy",
                description: [
                    "Dark Crystal Breastplate Heavy Armor Set: Received Healing +10%, HP Reg. +15%, Cast. Spd. +15%, Paralysis Resistance +50%, CON +2, STR -2. With Shield: Shield Defense Rate +25%",
                    "Tallum Heavy Armor: P. Atk. Spd. +8%, STR +2, CON -2, Weight Limit ++6000, Poison/Bleed Resistance +80%, Mental Resistance +50%",
                    "Nightmare Heavy Armor Set: P. Atk. +8%, CON +2 DEX -2, Root/Sleep Resistance +50%, Magic Resistance +5%. With Shield: P. Damage Reflect. +5%",
                    "Majestic Heavy Armor Set: P. Atk. +7%, P. Accuracy +4, STR +2, CON -2, Stun Resistance +50%, Run Spd. +5"
                ]
            },
            {
                title: "Light",
                description: [
                    "Dark Crystal Light Armor Set: P. Atk. +5%, P. Atk. Spd. +5%, P. Evasion +4, STR +1, CON -1, Paralysis Resist +50%",
                    "Tallum Light Armor Set: Max MP +260, MP Reg. +10%, MP Cost -10%, Cast. Spd. +15% MEN +3, WIT -3, Poison/Bleed Resistance +80%, Mental Resistance +50%",
                    "Nightmarish Leather Light Armor Set: M. Def. +8%, P. Evasion +4, M/P. Skill Evasion +5%, Vampiric +3%, DEX +2, CON -2, Sleep/Root Resistance +70%",
                    "Majestic Light Armor Set: P. Atk. when using BOW +10%, P. Atk. Range when using bow +50, Max MP +300, DEX +1, CON -1, Stun Resistance +50%, Weight Limit +6000"
                ]
            },
            {
                title: "Robe",
                description: [
                    "Dark Crystal Robe Set: Cast. Spd. +15%, P. Def. +8%, Run Spd. +7, Cast Break Resist +30%, WIT +2, MEN -2, Paralysis Resist +50%",
                    "Tallum Robe Set: M. Def. +8%, Cast. Spd. +15%, WIT +2, INT -2, Poison/Bleed Resist +80%, Mental Resistance +50%, Run Spd. +7",
                    "Nightmare Robe Set: M. Atk. +15%, Cast. Spd. +10%, MP Reg. +9%, M. Skill Reuse -10%, M. Crit. Rate +50%, INT +2, WIT -2, Sleep/Root Resistance +70%",
                    "Majestic Robe Set: Max MP +300, Cast. Spd. +15%, MP Reg. +10%, MEN +1, INT -1, Stun Resistance +50%, Healing Power +10%, Magic Skill Cost -10%"
                ]
            }
        ]
    },
    {
        title: "S-Grade",
        description: [
            {
                title: "Heavy",
                description: [
                    "Imperial Crusader Heavy Armor Set: Max HP +500, P. Def. +10%, STR +2, DEX -2, Sleep/Root Resistance +70%. With Shield: Poison Resistance +80%, Bleed Resistance +80%",
                ]
            },
            {
                title: "Light",
                description: [
                    "Draconic Light Armor Set: P. Atk. Spd. +10%, P. Atk. +10%, Max MP +300, STR +1, DEX +1, CON -2, Weight Limit +6000",
                ]
            },
            {
                title: "Robe",
                description: [
                    "Major Arcana Robe Set: M. Atk. +20%, Cast. Spd. +10%, WIT +2, MEN -2, Run. Spd. +7, Root/Sleep Resistance +40%, Cast Break Resist -50%, Stun Resistance +50%"
                ]
            },
        ]
    }
]

export const bossInfo = [
    {
        en: {
            title: "Levels of Epic bosses are unchanged"
        },
        ua: {
            title: "Рівні епік босів не змінені"
        },
        ru: {
            title: "Уровни эпик боссов не изменены"
        }
    },
    {
        en: {
            title: "Binding of epic bosses to evening time and their first revival",
            description: [
                "Epic bosses Queen Ant, Core, Orfen, Zaken are lvl 80",
                "All Epic respawn 20:00 - 22:00 UTC+3",
                "All Epic Jewel drop with 100% chance",
                "Epic bosses respawn begins on the 7th day from the start of the server",
                "Date and time of epics respawn available in Alt+B",
                "Core, Orfen - fixed respawn 2 days after kill.",
                "AQ - fixed respawn 3 days after kill.",
                "Zaken - fixed respawn 4 days after kill.",
                "Baium - fixed respawn 5 days after kill.",
                "Frintezza - fixed respawn 6 days after kill.",
                "Antharas - fixed respawn 9 days after kill.",
                "Valakas - fixed respawn 13 days after kill.",
                "Sailren respawn every 4h after kill.",
            ]
        },
        ua: {
            title: "Прив'язка Epic Bosses до вечірнього часу та їх перше відродження",
            description: [
                "Епік босси Queen Ant, Core, Orfen, Zaken 80-го рівня",
                "Відродження всіх Epic Bosses 20:00 - 22:00 UTC+3",
                "Уся епік біжутерія випадає зі 100% шансом",
                "Респаун епік боссів починається на 7 день зі старту сервера",
                "Інформація про дату і час респауну епік босів можна буде переглянути в Alt+B ",
                "Core, Orfen фіксований респаун 2 дні від моменту вбивства",
                "AQ фіксований респаун 3 дні від моменту вбивства",
                "Zaken фіксований респаун 4 днів від моменту вбивства",
                "Baium фіксований респаун 5 днів від моменту вбивства",
                "Frintezza фіксований респаун 6 днів від моменту вбивства",
                "Antharas фіксований респаун 9 днів від моменту вбивства",
                "Valakas фіксований респаун 13 днів від моменту вбивства",
                "Sailren відроджується кожні 4 години після вбивства",
            ]
        },
        ru: {
            title: "Привязка Epic Bosses к вечернему времени и первое их возрождение",
            description: [
                "Эпические боссы Queen Ant, Core, Orfen, Zaken 80-го уровня",
                "Возрождение всех Epic Bosses с 20:00 до 22:00 по UTC+3.",
                "Вся эпик бижутерия выпадает с шансом 100%.",
                "Респаун эпик боссов начинается на 7-й день после запуска сервера.",
                "Информацию о дате и времени респауна эпических боссов можно посмотреть в Alt+B.",
                "Core и Orfen имеют фиксированный респаун через 2 дня после убийства.",
                "AQ имеет фиксированный респаун через 3 дня после убийства.",
                "Zaken имеет фиксированный респаун через 4 дня после убийства.",
                "Baium имеет фиксированный респаун через 5 дней после убийства.",
                "Frintezza имеет фиксированный респаун через 6 дней после убийства.",
                "Antharas имеет фиксированный респаун через 9 дней после убийства.",
                "Valakas имеет фиксированный респаун через 13 дней после убийства.",
                "Sailren возрождается каждые 4 часа после убийства.",
            ]
        }
    },
    {
        en: {
            title: "Features RB",
            description: [
                "Normal raid boss respawn 10-11 hours",
                "Respawn of Raid Bosses on the Ketra/Varka alliance 12 hours",
                "When Raid Boss hited all nearby players become flag"
            ]
        },
        ua: {
            title: "Особливості Raid Bosses",
            description: [
                "Звичайні рейд босси відроджується через 10-11 годин ",
                "Відродження рейд боссів по квесту на альянс Кетри/Варки 12 годин",
                "Коли рейд босс атакований, усі гравці поруч стають флагнутими."
            ]
        },
        ru: {
            title: "Особенности Raid Bosses",
            description: [
                "Обычные рейд боссы возрождаются через 10-11 часов после убийства.",
                "Рейд боссы по квесту на альянс  Кетра/Варка возрождаются через 12 часов.",
                "Когда рейд босса атакуют, все игроки рядом становятся флагнутыми."
            ]
        }
    }
]

export const rareWeaponMag = [
    {
        title: "All Rare items with buff buff chance skills are set to 85%"
    },
    {
        title: "B-Grade",
        description: [
            "Hell Knife - Cleverness: MP Reg. +15%",
            "Kaim Vanul's Bones - Cleverness: MP Reg. +20%",
            "Sword of Valhalla - Holy Spirit Heal: Power +15%",
            "Spell Breaker - Earth: Max HP +10%",
            "Sprite's Staff - Nature: Max MP +15%",
            "Wizard's Tear - Quickness: Cast Spd. +4%",
            "Staff of Evil Spirits - Hail: M. Atk. +7%"
        ]
    },
    {
        title: "A-Grade",
        description: [
            "Themis' Tongue - Cleverness: MP Reg. +23%",
            "Spiritual Eye - Earth: Max HP +11%",
            "Elemental Sword - Nature: Max MP +20%",
            "Cabrio's Hand - Nature: Max MP +25%",
            "Flaming Dragon Skull - Holy Spirit: Heal Power +20%",
            "Sword of Miracles - Quickness: Cast Spd. +5%",
            "Branch of the Mother Tree - Hail: M. Atk. +8%",
            "Dasparion's Staff - Hail: M. Atk. +7.5%",
            "Daimon Crystal - Hail: M. Atk. +8.5%"
        ]
    },
    {
        title: "S-Grade",
        description: [
            "Arcana Mace - Quickness: Cast Spd. +6%",
            "Imperial Staff - Hail: M. Atk. +9.5%"
        ]
    },
    // {
    //     title: "Dynasty S-80 NEW",
    //     description: [
    //         "Phantom - Holly Spirit (+40% Heal Power)",
    //         "Mace - Cast (7.5% cast)",
    //         "Staff - Hail (11%+M.Atk)"
    //     ]
    // }
]

export const rareWeaponFighter = [
    {
        title: "B-Grade",
        description: [
            "Keshanberk - Great Gale: P. Atk. Spd. +5%",
            "Bow of Peril - Great Gale: P. Atk. Spd. +5.5%",
            "Bellion Cestus - Great Gale: P. Atk. Spd. +5.5%",
            "Ice Storm Hammer - Great Gale: P. Atk. Spd. +5%",
            "Great Axe - Great Gale: P. Atk. Spd. +5%",
            "Art of Battle Axe - Great Gale: P. Atk. Spd. +5.5%",
            "Kris - Lightning: P. Crit. Rate +55",
            "Arthro Nail - Lightning: P. Crit. Rate +55",
            "Sword of Damascus - Lightning: P. Crit. Rate +65",
            "Guardian Sword - Lightning: P. Crit. Rate +55",
            "Star Buster - Thunder: P. Crit. Dmg. +5%",
            "Demon Dagger - Thunder: P. Crit. Dmg. +5%",
            "Heavy War Axe - Earth: Max HP +10%",
            "Great Sword - Evil Spirit: P. Vamp. +4%",
            "Lance - Evil Spirit: P. Vamp. +4.5%",
            "Dark Elven Long Bow - Destruction: P. Atk. +8%",
            "Deadman's Glory - Landslide: 7% chance to Stun during P. Atk"
        ]
    },
    {
        title: "A-Grade",
        description: [
            "Tallum Blade - Great Gale: P. Atk. Spd. +6%",
            "Carnage Bow - Great Gale: P. Atk. Spd. +6%",
            "Destroyer Hammer - Great Gale: P. Atk. Spd. +6%",
            "Keshanberk*Damascus - Great Gale: P. Atk. Spd. +6%",
            "Blood Tornado - Great Gale: P. Atk. Spd. +6%",
            "Halberd - Great Gale: P. Atk. Spd. +6%",
            "Elysian - Great Gale: P. Atk. Spd. +7%",
            "Naga Storm - Great Gale: P. Atk. Spd. +8%",
            "Infernal Master - Evil Spirit: P. Vamp. +4.5%",
            "Meteor Shower - Earth: Max HP +11%",
            "Damascus*Damascus - Earth: Max HP +12%",
            "Tallum Glaive - Earth: Max HP +12%",
            "Keshanberk*Keshanberk - Wisdom: All Skills MP Cost -10%",
            "Bloody Orchid - Molar: 8.5% chance to casts Poison with Slow on target during P. Atk",
            "Dark Legion - Lightning: P. Crit. Rate +75",
            "Dragon Slayer - Lightning: P. Crit. Rate +65",
            "Doom Crusher - Lightning: P. Crit. Rate +65",
            "Dragon Grinder - Lightning: P. Crit. Rate +65",
            "Soul Bow - Clairvoyance: Bow Atk. Range +100",
            "Soul Separator - Thunder: P. Crit. Dmg. +6.5%",
            "Behemoth's Tuning Fork - Thunder: P. Crit. Dmg. +6.5%",
            "Sirra's Blade - Destruction: P. Atk. +9%",
            "Sword of Ipos - Destruction: P. Atk. +9%",
            "Tallum Blade*Damascus - Destruction: P. Atk. +9%",
            "Sobekk's Hurricane - Destruction: P. Atk. +9%",
            "Shyeed's Bow - Destruction: P. Atk. +9%",
            "Barakiel's Axe - Landslide: 8.5% chance to Stun during P. Atk",
            "Tiphon's Spear - Landslide: 8.5% chance to Stun during P. Atk"
        ]
    },
    {
        title: "S-Grade",
        description: [
            "Forgotten Blade - Lightning: P. Crit. Rate +85",
            "Heaven's Divider - Lightning: P. Crit. Rate +75",
            "Basalt Battlehammer - Landslide: 10% chance to Stun during P. Atk",
            "Dragon Hunter Axe - Thunder: P. Crit. Dmg. +8%",
            "Angel Slayer - Thunder: P. Crit. Dmg. +8%",
            "Draconic Bow - Clairvoyance: Bow Atk. Range +125",
            "Saint Spear - Destruction: P. Atk. +10.5%",
            "Demon Splinter - Destruction: P. Atk. +10.5%",
            "Tallum Blade*Dark Legion's Edge - Destruction: P. Atk. +10.5%"
        ]
    },
    // {
    //     title: "Dynasty S-80",
    //     description: [
    //         "Dynasty Sword - Lightining(+95 crit rate)",
    //         "Dynasty Blade - Lightining(+85 crit rate)",
    //         "Dynasty Cudgel - Landslide(12% Stun during P.Atk)",
    //         "Dynasty Crusher - Thunder(10% crit DMG)",
    //         "Dynasty Dualsword - Destruction(+12%P.Atk)",
    //         "Dynasty Knife - Thunder(10% crit DMG)",
    //         "Dynasty Halberd - Destruction(+12%P.Atk)",
    //         "Dynasty Bow - Clairvoyance(+150.Atk.Range)",
    //         "Dynasty Back-Nackh - Destruction(+12%P.Atk)"
    //     ]
    // }
]

export const skillsInfo = [
    {
        general: null,
        img: null,
        class: "WARRIORS",
        profs: [
            {
                prof: "Duelist",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0190.png",
                        change: "Removed: Fatal Strike."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0260.png",
                        change: "Removed: Hummer Crush."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0001.png",
                        change: "Changed: Triple Slash: Now Increase Charges by 1."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0006.png",
                        change: "Changed: Sonic Blaster: Has a chance to stun a target for 5 sec."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0078.png",
                        change: "Changed: War Cry and Duelist Spirit: Duration 5 min."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0451.png",
                        change: "Changed: Sonic Move: now doesn't need Focus Sonic charges."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0297.png",
                        change: "Changed: Duelist Spirit: +35% crit chance add"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0992.png",
                        change: "Added: Sonic Mastery: Increase Charges with a 15% chance for regular p.atk and 30% for crit.atk."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0919.png",
                        change: "Added: Maximum Focus Sonic: Increase Charges for maximum immediately."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0484.png",
                        change: "Added: Rush: Charges toward the enemy."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0793.png",
                        change: "Added: Rush Impact: Rush toward the enemy and inficts Shock."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0917.png",
                        change: "Added: Final Secret: for 30 sec +30% PvP power."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0775.png",
                        change: "Added: Weapon Blockade: disarmed enemy for 5 sec."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1605.png",
                        change: "Added: Gladiator Spirit: P.Skills evansion +40% for 30 sec. Reuse time 5 min"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0290.png",
                        change: "Added: Final Frenzy: Increases P. Atk. when HP is below 30% and 15% PVP damage"
                    }
                ]
            },
            {
                prof: "Dreadnought",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0078.png",
                        change: "Changed: War Cry: Duration 5 min."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0290.png",
                        change: "Added: Final Frenzy: Increases P. Atk. when HP is below 30% and 15% PVP damage"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0181.png",
                        change: "Changed: Revival: Can be used any time."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0116.png",
                        change: "Changed: Howl: Added -30% M.atk"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0920.png",
                        change: "Added: Power Crush: Atk. the enemy with power"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0921.png",
                        change: "Added: Cursed Pierce: Atk. the enemy with power"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0484.png",
                        change: "Added: Rush: Charges toward the enemy."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0793.png",
                        change: "Added: Rush Impact: Rush toward the enemy and inficts Shock."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0917.png",
                        change: "Added: Final Secret: for 30 sec +30% PvP power."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0774.png",
                        change: "Added: Dread Pool: Fear spreads around the area."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill30610.png",
                        change: "Added: Polearm Shield: +3200 P.Def for 30 sec, but -speed."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill11536_a.png",
                        change: "Added: Warlord Spirit: +100% M.Def for 15 sec."
                    }
                ]
            },
            {
                prof: "Titan",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/totem_spirit_ogre.png",
                        change: "Added: Savage: Limits HP at 30%."
                    },
                    {
                        img: null,
                        change: "Added: Full Swing: Toggle, Increases number of additional targets when using two-handed sword."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0139.png",
                        change: "Changed: Gust: Added M. Def +100%"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0484.png",
                        change: "Added: Rush: Charges toward the enemy."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0793.png",
                        change: "Added: Rush Impact: Rush toward the enemy and inficts Shock."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0917.png",
                        change: "Added: Final Secret: for 30 sec +30% PvP power."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0777.png",
                        change: "Added: Demolition Impact: attack nearby enemies."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0176.png",
                        change: "Changed: Work like on HF chronicle"
                    }
                ]
            },
            {
                prof: "Grand Ghavatary",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/totem_spirit_ogre.png",
                        change: "Added: Savage: Limits HP at 30%."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0054.png",
                        change: "Changed: Force Blaster: Has a chance to stun a target for 5 sec."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0281.png",
                        change: "Changed: Soul Breaker now not stun, but has a slow."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0280.png",
                        change: "Changed: Burning Fist: Now Increase Charges by 1."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0081.png",
                        change: "Changed: Punch of Doom: ability cooldown reduced to 30s"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0461.png",
                        change: "Changed: Break Duress no longer requires charging, work on magic and phys. root"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill10269.png",
                        change: "Added: Jump Attack: Jump toward the enemy and slows down the target by 40% for 10 sec."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0917.png",
                        change: "Added: Final Secret: for 30 sec +30% PvP power."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0993.png",
                        change: "Added: Force Mastery: Increase Energy with a 15% chance for regular p.atk and 30% for crit.atk."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0918.png",
                        change: "Added: Maximum Focus Force: Increase Energy for maximum immediately."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0776.png",
                        change: "Added: Force of Destruction: Attacks the enemy with 4500 Power."
                    }
                ]
            },
            {
                prof: "Maestro",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: null,
                        change: "Added: Two-Handed Blunt Mastery."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0078.png",
                        change: "Added: War Cry."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0287.png",
                        change: "Added: Lion Heart."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0290.png",
                        change: "Added: Final Frenzy: Increases P. Atk. and +15% PVP damage when HP is below 30%."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0286.png",
                        change: "Added: Provoke."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill11502.png",
                        change: "Added: Dwarf Technomancy (Increases armor bonus)."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill11501.png",
                        change: "Added: Dwarf Supremacy (increases weapon bonus) for 5 min to all party."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0315.png",
                        change: "Added: Crush Of Doom."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1542.png",
                        change: "Added: Counter Critical."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0484.png",
                        change: "Added: Rush: Charges toward the enemy."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0793.png",
                        change: "Added: Rush Impact: Rush toward the enemy and inficts Shock."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0917.png",
                        change: "Added: Final Secret: for 30 sec +30% PvP power."
                    }
                ]
            }
        ]
    },
    {
        general: [
            {
                img: "https://images.dares.club/Icon/skill_i/skill0028.png",
                change: "Doubled Agression Power. In PvP, forces you to target tank for 3 seconds. Can be removed with Cleanse."
            }
        ],
        img: null,
        class: "TANKS",
        profs: [
            {
                prof: "Shillen Templar",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0480.png",
                        change: "Added: Dark Blade."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0481.png",
                        change: "Added: Dark Armor."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill10016.png",
                        change: "Added: Chain Strike: Pulls the target to the caster."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0789.png",
                        change: "Added: Spirit of Shillen: now doesn't need charge."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0983.png",
                        change: "Changed: Patience: Has a chance to decrease all damage received by party in PVP by 10% for 5sec when you are under attack."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0103.png",
                        change: "Changed: Corpse Plague: now doesn't need a corpse, you can use like AoE Poison atk."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0982.png",
                        change: "Changed: Combat Aura: Increases M. Atk and Cast Spd."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0288.png",
                        change: "Changed: Guard Stance: Now are passive skill."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0984.png",
                        change: "Added: Shield Strike: Atk. the enemy with shield"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0916.png",
                        change: "Added: Shield Deflect Magic: For 8 sec return magical damage to enemies"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0527.png",
                        change: "Added: Iron Shield: Increase shield defence."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0528.png",
                        change: "Added: Shield Of Faith: For 15 sec increase for party members P.Def 3600 and M.Def 2700"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0913.png",
                        change: "Added: Deflect Magic: Increase resist for magical attacks."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0760.png",
                        change: "Added: Anti Magic Armor: Increase M.Def 5000 for 30 sec."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0779.png",
                        change: "Added: Summ. Smart Cubic: Cleance yourself"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0985.png",
                        change: "Added: Chellenge for Fate: Chellenge and provoke nearby. Increase your P.Def and M.Def for 15 sec"
                    },
                    {
                        img: null,
                        change: "Added: Boost Morale: Selfbuff for 2 min, increase Acc. for 8, Crit.rate +150, Crit. damage +30%"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0767.png",
                        change: "Added: Expose Weak Point: Gives flesh and takes a bone. Decreases the enemy's P.Def. by temporarily exposing his weakness when receiving the enemy's attack. Increases the critical hit rate of general attacks."
                    }
                ]
            },
            {
                prof: "Eva's Templar",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1043.png",
                        change: "Added: Holly Blade."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0197.png",
                        change: "Added: Holly Armor."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill10016.png",
                        change: "Added: Chain Strike: Pulls the target to the caster."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0405.png",
                        change: "Added: Banish Undead."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1553.png",
                        change: "Added: Chain Heal."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0787.png",
                        change: "Added: Touch of Eva: increases Heal Power (30%), M.atk, M.atk.speed (30%), debuff (30%) and cancel (100%) resist."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0982.png",
                        change: "Changed: Combat Aura: Increases M. Atk and Cast Spd."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0983.png",
                        change: "Changed: Patience: Has a chance to decrease all damage received by party in PVP by 10% for 5sec when you are under attack."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0288.png",
                        change: "Changed: Guard Stance: Now are passive skill."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0984.png",
                        change: "Added: Shield Strike: Atk. the enemy with shield"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0916.png",
                        change: "Added: Shield Deflect Magic: For 8 sec return magical damage to enemies"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0527.png",
                        change: "Added: Iron Shield: Increase shield defence."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0528.png",
                        change: "Added: Shield Of Faith: For 15 sec increase for party members P.Def 3600 and M.Def 2700"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0913.png",
                        change: "Added: Deflect Magic: Increase resist for magical attacks."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0760.png",
                        change: "Added: Anti Magic Armor: Increase M.Def 5000 for 30 sec."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0779.png",
                        change: "Added: Summ. Smart Cubic: Cleance yourself"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0985.png",
                        change: "Added: Chellenge for Fate: Chellenge and provoke nearby. Increase your P.Def and M.Def for 15 sec"
                    },
                    {
                        img: null,
                        change: "Added: Boost Morale: Selfbuff for 2 min, increase Acc. for 8, Crit.rate +150, Crit. damage +30%"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0766.png",
                        change: "Added: Six Sense: When stamina is at its limit, sensation reaches its peak to increase Evasion by 15 when HP is below 30%"
                    }
                ]
            },
            {
                prof: "Phoenix Knight",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill10016.png",
                        change: "Added: Chain Strike: Pulls the target to the caster."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0785.png",
                        change: "Added: Flame Icon: doesn't need to be charged."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1043.png",
                        change: "Added: Holly Blade."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0197.png",
                        change: "Added: Holly Armor."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0406.png",
                        change: "Changed: Angelic Icon: remove HP use condition."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0982.png",
                        change: "Changed: Combat Aura: Increases M. Atk and Cast Spd."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0983.png",
                        change: "Changed: Patience: Has a chance to decrease all damage received by party in PVP by 10% for 5sec when you are under attack."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0984.png",
                        change: "Added: Shield Strike: Atk. the enemy with shield"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0916.png",
                        change: "Added: Shield Deflect Magic: For 8 sec return magical damage to enemies"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0527.png",
                        change: "Added: Iron Shield: Increase shield defence."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0528.png",
                        change: "Added: Shield Of Faith: For 15 sec increase for party members P.Def 3600 and M.Def 2700"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0913.png",
                        change: "Added: Deflect Magic: Increase resist for magical attacks."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0760.png",
                        change: "Added: Anti Magic Armor: Increase M.Def 5000 for 30 sec."
                    },
                    {
                        img: null,
                        change: "Added: Boost Morale: Selfbuff for 2 min, increase Acc. for 8, Crit.rate +150, Crit. damage +30%"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0985.png",
                        change: "Added: Chellenge for Fate: Chellenge and provoke nearby. Increase your P.Def and M.Def for 15 sec"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0912.png",
                        change: "Added: Summ. Imperial Phoenix"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0766.png",
                        change: "Added: Six Sense: When stamina is at its limit, sensation reaches its peak to increase Evasion by 15 when HP is below 30%."
                    }
                ]
            },
            {
                prof: "Hell Knight",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0480.png",
                        change: "Added: Dark Blade."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0481.png",
                        change: "Added: Dark Armor."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0762.png",
                        change: "Added: Insane Crusher: doesn't need to be charged and reuse is set to 18 seconds."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill19542.png",
                        change: "Added: Spirit Of Avenger: like Flame Icon (Phoenix Knight skill) doesn't need charge."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0103.png",
                        change: "Changed: Corpse Plague: now doesn't need a corpse, you can use like AoE Poison atk."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0982.png",
                        change: "Changed: Combat Aura: Increases M. Atk and Cast Spd."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0983.png",
                        change: "Changed: Patience: Has a chance to decrease all damage received by party in PVP by 10% for 5sec when you are under attack."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0984.png",
                        change: "Added: Shield Strike: Atk. the enemy with shield"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0916.png",
                        change: "Added: Shield Deflect Magic: For 8 sec return magical damage to enemies"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0527.png",
                        change: "Added: Iron Shield: Increase shield defence."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0528.png",
                        change: "Added: Shield Of Faith: For 15 sec increase for party members P.Def 3600 and M.Def 2700"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0913.png",
                        change: "Added: Deflect Magic: Increase resist for magical attacks."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0760.png",
                        change: "Added: Anti Magic Armor: Increase M.Def 5000 for 30 sec."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0985.png",
                        change: "Added: Chellenge for Fate: Chellenge and provoke nearby. Increase your P.Def and M.Def for 15 sec"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0763.png",
                        change: "Added: Hell Scream: For 8 sec decrease M.Def, P.Def, Spd. for -30% and inflicts a fear for nearby enemies."
                    },
                    {
                        img: null,
                        change: "Added: Boost Morale: Selfbuff for 2 min, increase Acc. for 8, Crit.rate +150, Crit. damage +30%"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0767.png",
                        change: "Added: Expose Weak Point: Gives flesh and takes a bone. Decreases the enemy's P.Def. by temporarily exposing his weakness when receiving the enemy's attack. Increases the critical hit rate of general attacks."
                    }
                ]
            }
        ]
    },
    {
        general: [
            {
                img: "https://images.dares.club/Icon/skill_i/skill10772.png",
                change: "Add new AoE skill Rain of Arrows for all archers."
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill0416.png",
                change: "Bless of Sagittarius are passive skills."
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill0415.png",
                change: "Spirit of Sagittarius are passive skills."
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill0470.png",
                change: "Detect: Finds near hidden enemies."
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill0946.png",
                change: "Added: Silent Mind: Allows use of a bow whithout consuming MP."
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill0924.png",
                change: "Added: Seven Arrow: Powerful attack"
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill0990.png",
                change: "Added: Death Shot: Powerful attack"
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill0987.png",
                change: "Added: Multiple Shot: Powerful attack to area of enemies"
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill0535.png",
                change: "Added: Counter Mind: When attacked, user's MP is recovered at a certain rate."
            }
        ],
        img: null,
        class: "ARCHERS",
        profs: [
            {
                prof: "Ghost Sentinel",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0024.png",
                        change: "Added: Burst shot."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/s_lethal_shot_da.png",
                        change: "Added: Counter Chance: When attacked Party P. Crit. Dmg +15%."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0414.png",
                        change: "Changed: Dead Eye: doesn't decrease P. Atk. Spd. now."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/s_accurate.png",
                        change: "Added: Phantom bow: Increase Accuracy +10, P.Atk +5% but - 10% P.Def"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill31127.png",
                        change: "Added: Archer's Jump: warp on 250 distance and hide for 3 sec. Reuse time 2 min."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0773.png",
                        change: "Added: Ghost Piercing: Powerful attack"
                    }
                ]
            },
            {
                prof: "Moonlight Sentinel",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0413.png",
                        change: "Changed: Rapid Fire: doesn't decrease atk.range now."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0533.png",
                        change: "Changed: Counter Rapid Shot: now work when attacked or autoattacks."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/s_swift.png",
                        change: "Added::Silver Bow: Increase Accuracy +10, P.Atk.Spd +5% but - 10% P.Def."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill31127.png",
                        change: "Added: Archer's Jump: warp on 250 distance and hide for 3 sec. Reuse time 2 min."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0772.png",
                        change: "Added: Arrow Rain: Powerful attack"
                    }
                ]
            },
            {
                prof: "Sagittarius",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0131.png",
                        change: "Changed: Hawk Eye: Decreases P.Def -10%, but adds P. Accuracy and P. Crit. Dmg."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0313.png",
                        change: "Changed: Snipe: Removed Move Restriction."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0771.png",
                        change: "Added: Flame Hawk: Powerful attack"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill31127.png",
                        change: "Added: Archer's Jump: warp on 250 distance and hide for 3 sec. Reuse time 2 min."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0534.png",
                        change: "Added: Counter Dash: Chance when attacked, party member's increas speed."
                    }
                ]
            }
        ]
    },
    {
        general: [
            {
                img: "https://images.dares.club/Icon/skill_i/skill0821.png",
                change: "Changed: The cooldown of the Shadow Step ability has been reduced to 50 seconds."
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill0453.png",
                change: "Changed: Ability Escape Shackle skill now also removes magic root."
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill0221.png",
                change: "Changed: Silent Move: Doesn't remove speed now."
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill31133.png",
                change: "Added: Dagger Spirit: When enemy has a HP 30% and lower, +15% PVP damage"
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill10518.png",
                change: "Added: Clear Movements: Resetting reuse of Shadow Step and Hide."
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill1511.png",
                change: "Added: Critical bleed: Chance to cause Bleed during P.Crit.dmg 12%"
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill0922.png",
                change: "Added: Hide"
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill0531.png",
                change: "Added: Critical Wound: De-buff +15% Crit.damage"
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill0991.png",
                change: "Added: Throwing Dagger: range attack with dagger, decrease speed by 80% for 10%"
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill1559.png",
                change: "Added: Potential Ability: with dagger crit.rate +20% and crit.damage +177"
            }
        ],
        img: null,
        class: "DAGGERS",
        profs: [
            {
                prof: "Ghost Hunter",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0818.png",
                        change: "Added: Evasion Counter: During Evasion, counters by stunning the enemy"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0770.png",
                        change: "Ghost Walking:  For 30 sec - Spd.+33, Evasion +15, vital point attack rate +40%, P.Skill evasion rate +60%, resist to cancel 90%"
                    }
                ]
            },
            {
                prof: "Wind Rider",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0819.png",
                        change: "Added: Evasion Chance: During Evasion, chance to increase rate of succesfully striking"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0769.png",
                        change: "Added: Wind Riding:  For 30 sec - Spd.+50, Evasion +20, vital point attack rate +20%, P.Skill evasion rate +75%, resist to cancel 80%"
                    }
                ]
            },
            {
                prof: "Adventurer",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0820.png",
                        change: "Added: Evasion Haste: During Evasion, chance to increase Atk.Spd"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0768.png",
                        change: "Added: Exciting Adventure: For 30 sec - Spd.+20, Evasion +15, vital point attack rate +30%, P.Skill evasion rate +40%, resist to cancel 90%"
                    }
                ]
            },
            {
                prof: "Fortune Seeker",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: null,
                        change: "Deleted: All blunt skills."
                    },
                    {
                        img: null,
                        change: "Now only Dagger and Polearm weapons."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0016.png",
                        change: "Added: Mortal Strike."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0263.png",
                        change: "Added: Deadly Blow."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1560.png",
                        change: "Added: Lucky Blow."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0344.png",
                        change: "Added: Lethal Blow."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0409.png",
                        change: "Added: Critical Blow."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0169.png",
                        change: "Added: Quick Step."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0225.png",
                        change: "Added: Acrobatic Move."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0209.png",
                        change: "Added: Dagger Mastery."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0312.png",
                        change: "Added: Vicious Stance."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0004.png",
                        change: "Added: Dash."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0193.png",
                        change: "Added: Critical Damage."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0319.png",
                        change: "Added: Agile Movement."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0256.png",
                        change: "Added: Accuracy."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0096.png",
                        change: "Added: Bleed."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0137.png",
                        change: "Added: Critical chance."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0168.png",
                        change: "Added: Boost atk speed."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0287.png",
                        change: "Added: Lion Heart."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0221.png",
                        change: "Added: Silent Move."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0286.png",
                        change: "Added: Provoke."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1511.png",
                        change: "Added: Critical Bleed."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0501.png",
                        change: "Added: Violent Templar."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0922.png",
                        change: "Added: Hide."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0422.png",
                        change: "Added: Polearm Accuracy."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0432.png",
                        change: "Added: Assassination."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0359.png",
                        change: "Added: Eye of Hunter."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0360.png",
                        change: "Added: Eye of Slayer."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0947.png",
                        change: "Added: Lucky Strike."
                    }
                ]
            }
        ]
    },
    {
        general: [
            {
                img: "https://images.dares.club/Icon/skill_i/skill0925.png",
                change: "Added: Sigil Masteri: can use sigil"
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill1532.png",
                change: "Added: Enlightment: for 20 sec increase M.atk by 40%, Cast.Spd. by 50%, Magical crit. rate by 50% "
            }
        ],
        img: null,
        class: "WIZZARDS",
        profs: [
            {
                prof: "Soultaker",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: null,
                        change: "Deleted: Corpse Burst"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1438.png",
                        change: "New: Annihilation Circlet."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill11817.png",
                        change: "New: Dark Burst: every 5th Death Spike attack does double magic damage."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1495.png",
                        change: "Added: Vampiric Mist: Powerful magic attack for nearby enemies, absorb 20% of the damages to HP and for 10 sec decrease Spd., Ak/Cast Spd. by 50%, Acc. -12"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1467.png",
                        change: "Added: Meteor: Powerful magical attack for nearby enemies"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1557.png",
                        change: "Added: Servitor Share: Extends the summoner's abilities to the servitor."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1497.png",
                        change: "Added: Spirit Sharing: For 20 min increase P.Atk,M.Atk, Skill P.Atk, Atk.Spd, Cast.Spd, Crit.rate."
                    }
                ]
            },
            {
                prof: "Mystic Muse",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: null,
                        change: "Deleted: Solar Flare."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1453.png",
                        change: "Changed: Ice Vortex Crusher: now doesn't remove effect from Ice Vortex debuff."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill11046.png",
                        change: "New: Blink: jump back from the enemy."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skiill11265.png",
                        change: "New: Aqva Blink: jump forward a short distance, and a 10 sec increase in running speed and casting speed."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/hydro_burst.png",
                        change: "New: Hydro Burst: every 5 Hydroblast attacks deal double magic damage."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1556.png",
                        change: "Added: Arcane Shield: 70% of damage turn in MP consumption."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1454.png",
                        change: "Added: Diamond Dust: for 15 sec decrease speed nearby enemies by 40%."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1453.png",
                        change: "Added: Ice Vortex Crusher: Powerful magical attack after Vortex de-buff succesfully."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1493.png",
                        change: "Added: Frost Armor: increase 10 resist Water, and slows down the attacking enemies."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1468.png",
                        change: "Added: Star Fall: Powerful magical attack for nearby enemies."
                    }
                ]
            },
            {
                prof: "Archmage",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1451.png",
                        change: "Changed: Fire Vortex Buster: now doesn't remove effect from Fire Vortex debuff."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill11046.png",
                        change: "New: Blink: jump back from the enemy."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill19303.png",
                        change: "New: Flame Blink: jump forward a short distance, and a 10 sec increase p./m.def +30%."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/flame_burst.png",
                        change: "New: FireBurst: every 5 Prominence attacks deal double magic damage."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1451.png",
                        change: "Added: Fire Vortex Buster: Powerful magical attack after Vortex de-buff succesfully."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1556.png",
                        change: "Added: Arcane Shield: 70% of damage turn in MP consumption."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1467.png",
                        change: "Added: Meteor: Powerful magical attack for nearby enemies"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1492.png",
                        change: "Added: Flame Armor:  increase 10 resist Fire, and causes burn the attacking enemies."
                    }
                ]
            },
            {
                prof: "Storm Screamer",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: null,
                        change: "Deleted: Shadow Flare, Death Spike, Dark Vortex."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1056.png",
                        change: "Added: Cancel."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1456.png",
                        change: "Changed: Vortex Slug doesn't remove Wind Vortex Debuff."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill11046.png",
                        change: "New: Blink: jump back from the enemy."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/gale_burst.png",
                        change: "New: Storm Burst: every 5 Huriccane attacks deal double magic damage."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill11034.png",
                        change: "New: Storm Blink: jump forward a short distance, and a 10 sec increase m.atk +100%."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1457.png",
                        change: "Added: Empowering Echo: +25% M.atk, but MP consuption by 35%"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1456.png",
                        change: "Added: Wind Vortex Slug: Powerful magical attack after Vortex de-buff succesfully."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1468.png",
                        change: "Added: Star Fall: Powerful magical attack for nearby enemies"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1556.png",
                        change: "Added: Arcane Shield: 70% of damage turn in MP consumption."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1494.png",
                        change: "Added: Hurricane Armor: increase 10 resist Wind, and slows down atk.spd the attacking enemies."
                    }
                ]
            }
        ]
    },
    {
        general: [
            {
                img: "https://images.dares.club/Icon/skill_i/skill1497.png",
                change: "Added: Spirit Sharing: For 20 min increase P.Atk,M.Atk, Skill P.Atk, Atk.Spd, Cast.Spd, Crit.rate."
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill1498.png",
                change: "Added: Mutual Response: Chance regenerate servitor HM and MP."
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill1496.png",
                change: "Added: Servitor Barrier: Makes invisible the servitor."
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill0779.png",
                change: "Added: Summ. Smart Cubic: Cleance yourself"
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill1497.png",
                change: "Added: Excessive Loyalty; When master incurse or deal damage, the servitor battle skills are increase."
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill1557.png",
                change: "Added: Servitor Share: Extends the summoner's abilities to the servitor."
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill1558.png",
                change: "Added; Dimension Spiral: magical damage skill"
            }
        ],
        img: null,
        class: "SUMMONERS",
        profs: [
            {
                prof: "Arcana Lord",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill10501.png",
                        change: "Added: Summon buffs to character Warlock's Gift(P.Atk and Acc.) for all party members."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill10502.png",
                        change: "Added: Summon buffs to character Warlock's Blessing(Crit. rate and damage) for all party members."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill31148.png",
                        change: "New: Warlock's Magic: 2 in 1 Warlock's Gift(P.Atk and Acc.) + Warlock's Blessing(Crit. rate and damage) for all party members."
                    }
                ]
            },
            {
                prof: "Elemental Master",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill11226.png",
                        change: "Added: Summon buffs to character Summoner's Gift(magical skill reuse) for all party members."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill11255.png",
                        change: "Added: Summon buffs to character Summoner's Blessing(MP regeneration, +M.Atk) for all party members."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill31150.png",
                        change: "New: Summoner's Magic: 2 in 1 Summoner's Gift(magical skill reuse) and Summoner's Blessing(MP regeneration) for all party members."
                    }
                ]
            },
            {
                prof: "Spectral Master",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/curse_of_shilen.png",
                        change: "New: Summon buffs on character Shade's Gift(increase P.M.Skill Power) for all party members."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/debuff_master.png",
                        change: "New: Summon buffs on character Shade's Blessing(P.Vamp) for all party members"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1148.png",
                        change: "Added: Death Spike"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill31152.png",
                        change: "New: Shade's Magic: 2 in 1 Shade's Gift(increase P.M.Skill Power) Shade's Blessing(P.Vamp) for all party members"
                    }
                ]
            }
        ]
    },
    {
        general: null,
        img: null,
        class: "Healler/Supports",
        profs: [
            {
                prof: "Cardinal",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: null,
                        change: "Removed: Inquisitor"
                    },
                    {
                        img: null,
                        change: "Changed: Moved Transform skills to character"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill11046.png",
                        change: "New: Blink."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0925.png",
                        change: "Added: Sigil mastery"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1553.png",
                        change: "Added: Chain Heal: heal nearby ppl for 30% of their Max. HP"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1540.png",
                        change: "Added: Turn to Stone: Petrifies yourself for 8 sec"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1459.png",
                        change: "Added: Divine Power: Increase recovery magic for 1000 power for 15 sec"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1505.png",
                        change: "Added: Sublime Self-Sacrifice: makes all party member invisible for 15 sec and restore HP/MP/CP without yourself."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1532.png",
                        change: "Added: Enlightment: increase M.Atk 10%, Heal rec. magic 40%, Cast.spd 50%, Mag.crit.rate 50%, decrease mp consuption 90% for 20 sec."
                    }
                ]
            },
            {
                prof: "Eva's Saint",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: null,
                        change: "Removed: Inquisitor"
                    },
                    {
                        img: null,
                        change: "Changed: Moved Transform skills to character"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1500.png",
                        change: "Added: Improved Magic (emp+mag.barier combo buff)."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1480.png",
                        change: "New: Soul Harmony: Invocation for all party (restore MP)."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1073.png",
                        change: "Change: Kiss of Eva: Decreases manacost."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill11046.png",
                        change: "New: Blink."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1552.png",
                        change: "Added: Mass Vitalize"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1550.png",
                        change: "Added: Mass Cure Poison"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1553.png",
                        change: "Added: Chain Heal: heal nearby ppl for 30% of their Max. HP"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1540.png",
                        change: "Added: Turn to Stone: Petrifies yourself for 8 sec"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0925.png",
                        change: "Added: Sigil mastery"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1506.png",
                        change: "Added: Blessing of Eva: cleanse de-buff and full restore HP/MP/CP"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1532.png",
                        change: "Added: Enlightment: increase M.Atk 10%, Heal rec. magic 40%, Cast.spd 50%, Mag.crit.rate 50%, decrease mp consuption 90% for 20 sec."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1503.png",
                        change: "Added: Improved Shield Defence(Bless Shield+Advanced Block)"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1504.png",
                        change: "Added: Improved Movement(WindWalk+Evasion)"
                    }
                ]
            },
            {
                prof: "Shillen Saint",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: null,
                        change: "Removed: Inquisitor"
                    },
                    {
                        img: null,
                        change: "Changed: Moved Transform skills to character"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1401.png",
                        change: "Added: Major Heal."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1397.png",
                        change: "Added: Clarity."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1553.png",
                        change: "Added: Chain Heal."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1480.png",
                        change: "New: Soul Harmony: Invocation for all party (restore MP)."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill11046.png",
                        change: "New: Blink."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1540.png",
                        change: "Added: Turn to Stone: Petrifies yourself for 8 sec"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0925.png",
                        change: "Added: Sigil mastery"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1532.png",
                        change: "Added: Enlightment: increase M.Atk 10%, Heal rec. magic 40%, Cast.spd 50%, Mag.crit.rate 50%, decrease mp consuption 90% for 20 sec."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1400.png",
                        change: "Added: Turn Undead"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1539.png",
                        change: "Added: Stigma of Shillen: decrease resistance to short-range weapon"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1897.png",
                        change: "Added: Mass Stigma of Shillen"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1443.png",
                        change: "Added: Dark Weapon: Increase the target dark att. atk by 20 for 20 min"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1218.png",
                        change: "Added: Bless Blood: for 15 sec chance restore HP a certain rate"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1255.png",
                        change: "Added: Party Recal"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1552.png",
                        change: "Added: Mass Vitalize"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1550.png",
                        change: "Added: Mass Cure Poison"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1551.png",
                        change: "Added: Mass Purify"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1508.png",
                        change: "Added: Throne Root: for 10 sec root all nearby enemies and lose 180 HP, 90 MP every sec"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1502.png",
                        change: "Added: Improved Critiacl Attack(Focus+Death Whisper)"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1500.png",
                        change: "Added: Improved Magic(Empower+Magic Barrier)"
                    }
                ]
            },
            {
                prof: "Doomcryer",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0522.png",
                        change: "Added: Real target."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0113.png",
                        change: "Added: Long Shot."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0099.png",
                        change: "Added: Rapid Shot."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/sympony_sword_buff.png",
                        change: "New: Blood Rush: Chance to increase Run. Spd. when attacked."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1476.png",
                        change: "New: Appetite for Destruction: increases +30% P.Atk, crt chance and rate."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1477.png",
                        change: "New: Vampiric Impulse: 60% Vamp."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1543.png",
                        change: "Added: Great Fury: 10% chance on autoattaks and 30% chance on Burning Chop takes 20% Atk.Spd for all party members "
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1562.png",
                        change: "Added: Chant of Berserker"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill11864.png",
                        change: "Added:Chant of Conditions(Bless body+Bless Soul)"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1499.png",
                        change: "Added:Chant of combat(Might+Shield)"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1502.png",
                        change: "Added: Chant of critical attak(Focus+Death Whispers)"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1535.png",
                        change: "Added: Chant of movement(Windwalk+Agility)"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1519.png",
                        change: "Added: Chant of Blood Awakening(Haste+P.Vamp)"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1540.png",
                        change: "Added: Turn to Stone: Petrifies yourself for 8 sec"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0925.png",
                        change: "Added: Sigil mastery"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1532.png",
                        change: "Added: Enlightment: increase M.Atk 10%, Heal rec. magic 40%, Cast.spd 50%, Mag.crit.rate 50%, decrease mp consuption 90% for 20 sec."
                    }
                ]
            },
            {
                prof: "Dominator",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0837.png",
                        change: "Added: Painkiller: makes invisible yourself for 8 sec"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1479.png",
                        change: "Added: Magic impulse: for 15 sec for all party members M.Atk 75%,Cast.spd 15%, Magic crit. rate 100%"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1536.png",
                        change: "Added: Combat of Pa'agrio(Might+Shield)"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1538.png",
                        change: "Added: Condition of Pa'agrio(Bless Body+Bless Soul)"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1537.png",
                        change: "Added: Critical of Pa'agrio(Focus+Death Whisper)"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1540.png",
                        change: "Added: Turn to Stone: Petrifies yourself for 8 sec"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0925.png",
                        change: "Added: Sigil mastery"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1532.png",
                        change: "Added: Enlightment: increase M.Atk 10%, Heal rec. magic 40%, Cast.spd 50%, Mag.crit.rate 50%, decrease mp consuption 90% for 20 sec."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1553.png",
                        change: "Added: Chain Heal: heal nearby ppl for 30% of their Max. HP"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1365.png",
                        change: "Added: Soul of Pa'agrio(Empower+Magic Barrier)"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1509.png",
                        change: "Added: Seal of Limit: for 5 min block nearby enemies restore HP/MP/CP by 70%"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill19193.png",
                        change: "Added: Magical Fury:10% chance on AutoAttack and 5% chance on Magic Skill Use\nMP Cost -50%, M. Atk. +16%, Cast. Spd. +4%."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1509.png",
                        change: "Added: Seal of Blockade: block ordinary attacks for 10 sec "
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0949.png",
                        change: "Added: Onslaught of Pa'agrio: Atta"
                    }
                ]
            },
            {
                prof: "Hierophant",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: null,
                        change: "Removed: Inquisitor"
                    },
                    {
                        img: null,
                        change: "Changed: Moved Transform skills to character"
                    },
                    {
                        img: "Turn Undead",
                        change: "Added: Turn Undead."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1409.png",
                        change: "Added: Cleanse."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1553.png",
                        change: "Added: Chain Heal."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1508.png",
                        change: "Added: Throne Root (Mass.root)."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1265.png",
                        change: "Added: Solar Flare."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1342.png",
                        change: "Added: Light Vortex: add debuff -10% M.Def."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1479.png",
                        change: "New: Magic Impulse: Increases M. Atk + Cast. Spd. and M. Crit. Chance."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/sympony_sword_buff.png",
                        change: "New: Blood Rush: Chance to increase Run. Spd. when attacked."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill11046.png",
                        change: "New: Blink."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1501.png",
                        change: "Added: Improved Condition(Bless Body+Bless Soul)"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1499.png",
                        change: "Added: Improved Combat(Might+Shield)"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1540.png",
                        change: "Added: Turn to Stone: Petrifies yourself for 8 sec"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0925.png",
                        change: "Added: Sigil mastery"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1532.png",
                        change: "Added: Enlightment: increase M.Atk 10%, Heal rec. magic 40%, Cast.spd 50%, Mag.crit.rate 50%, decrease mp consuption 90% for 20 sec."
                    }
                ]
            }
        ]
    },
    {
        general: [
            {
                img: "https://images.dares.club/Icon/skill_i/skill0028.png",
                change: "Aggression can be learned up to level 74"
            },
            {
                img: "https://images.dares.club/Icon/skill_i/song_of_hunter_02.png",
                change: "Merged Dances and Songs into 1 buff"
            },
            {
                img: null,
                change: "Added: Deadly Strike: P.attack skill" // delete stroky
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill0766.png",
                change: "Added: Sixth Sense: When stamina is at its limit, sensation reaches its peak to increase Evasion by 15 when HP is below 30%."
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill0767.png",
                change: "Added: Expose Weak Point: Gives flesh and takes a bone. Decreases the enemy's P.Def. by temporarily exposing his weakness when receiving the enemy's attack. Increases the critical hit rate of general attacks."
            },
            {
                img: "https://images.dares.club/Icon/skill_i/skill0913.png",
                change: "Added: Deflect Magic: Increases Resistance to magic attacks by 100% for 20 minutes."
            }
        ],
        img: null,
        class: "BARD",
        profs: [
            {
                prof: "Sword Muse",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0208.png",
                        change: "Added: Add Bow Mastery."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0209.png",
                        change: "Added: Dagger Mastery."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0113.png",
                        change: "Added: Long Shot."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0485.png",
                        change: "Added: Disarm."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0286.png",
                        change: "Added: Provoke: Provoke all enemies."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1478.png",
                        change: "Added: Protection Instinct: increases P and M.def."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0099.png",
                        change: "Added: Rapid Shot."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0484.png",
                        change: "Added: Rush."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0988.png",
                        change: "Added: Battle Whisper: increases the user's P. Atk. by 5%, Critical Damage by 5%, and Atk. Spd. by 5%."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0793.png",
                        change: "Added: Rush Impact."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0986.png",
                        change: "Added: Deadly Strike: Attacks the enemy with Power added to P. Atk. Decreases the effect of HP recovery magic received by the enemy by 50% for 30 seconds."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1485.png",
                        change: "Added: Spite."
                    },
                    {
                        img: null,
                        change: "New: Prahnah."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill10517.png",
                        change: "New: Symphony of Hide."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/sympony_sword_buff.png",
                        change: "New: Blood Rush: Chance to increase Run. Spd. when attacked."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0914.png",
                        change: "Added: Symphony of Purification: Provides an 80% a chance of removing a party members de-buff. And for the next 2 min increas resist for de-buuf and recovery magic +30%"
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0764.png",
                        change: "Added: Symphony of Wind Storm: For 2min increase resist to bow by 30"
                    }
                ]
            },
            {
                prof: "Spectral Dancer",
                img: null,
                databaseLink: null,
                changes: [
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0208.png",
                        change: "Added: Add Bow Mastery."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0209.png",
                        change: "Added: Dagger Mastery."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0113.png",
                        change: "Added: Long Shot."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0485.png",
                        change: "Added: Disarm."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0286.png",
                        change: "Added: Provoke: Provoke all enemies."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1478.png",
                        change: "Added: Protection Instinct: increases P and M.def."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0099.png",
                        change: "Added: Rapid Shot."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0484.png",
                        change: "Added: Rush."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0793.png",
                        change: "Added: Rush Impact."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0989.png",
                        change: "Added: Defense Motion: increases your P.Def. by 25%, Evasion by 3, and M.Def. by 10%."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0480.png",
                        change: "Added: Dark Blade."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0986.png",
                        change: "Added: Deadly Strike: Attacks the enemy with Power added to P. Atk. Decreases the effect of HP recovery magic received by the enemy by 50% for 30 seconds.."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill1485.png",
                        change: "Added: Spite."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0297.png",
                        change: "Added: Dualist Spirit."
                    },
                    {
                        img: null,
                        change: "New: Prahnah."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill10517.png",
                        change: "New: Symphony of Hide."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/sympony_sword_buff.png",
                        change: "New: Blood Rush: Chance to increase Run. Spd. when attacked."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0915.png",
                        change: "Added: Symphony Of Berserker: Decreases a party member's P.Def., M.Def. and Evasion, and increases their P. Atk., M. Atk., Atk. Spd., Casting Spd. and Speed for 2 minutes. Additionally increases MP consumption when dancing while Symphonies is in effect."
                    },
                    {
                        img: "https://images.dares.club/Icon/skill_i/skill0765.png",
                        change: "Added: Symphony of Blade Storm: Increases a party member's tolerance to bows by 99 and decreases M. Atk. by 99% for 15 seconds. Aditionally increases MP consumption when dancing while Symphonies is in effect."
                    }
                ]
            }
        ]
    }
]

export const booksInfo = [
    {
        title: "Book of the Warrior",
        grades: [
            // {
            //     grade: "D-Grade",
            //     img: null,
            //     locations: [
            //         "Neutral Zone - Arachnid Tracker, Lycanthrope, Scavenger Wererat",
            //         "Ruins of Agony - Tracker Skeleton Leader, Tracker Skeleton, Skeleton Scout",
            //         "Orc Barracks - Turek Orc Skirmisher, Turek Orc Elder, Turek Orc Warlord",
            //         "Partisan's Hideaway - Ol Mahum Guerilla, Ol Mahum Marksman",
            //         "Necropolis of Sacrifice - Lith Scout, Lith Witch, Lith Warrior, Lith Guard",
            //         "Death Pass - Wyrm, Fattered Soul, Road Scavenger"
            //     ]
            // },
            {
                grade: "C-Grade",
                img: null,
                locations: [
                    "Timak Outpost - Timak Orc Warrior, Timak Orc Soldier, Timak Orc Troop Leader",
                    "Ivory Tower Crater - Enchanted Stone Golem, Enchanted Monstereye",
                    "Plains of Glory - Vanor Silenos, Vanor Silenos Grunt, Vanor Silenos Scout",
                    "Cruma Tower 1 - Excuro, Shindebarn, Krator",
                    "Hideaway - Ol Mahum Guerilla, Ol Mahum Marksman",
                    "Catacomb of Branded - Lith Shaman, Lith Commander, Lilim Butcher, Lilim Magus",
                    "Forest of Evil - Tarlk Bugbear Warrior, Tarlk Bugbear, Formor Elder",
                    "Enchanted Valley - Fline, Liele, Valley Treant"
                ]
            },
            {
                grade: "B-Grade",
                img: null,
                locations: [
                    "Outlaw Forest - Oel Mahum, Hunter Gargoyle, Oel Mahum Warrior",
                    "Cemetery - Spiteful Ghost of Ruins, Soldier of Grief, Tairim",
                    "Enchanted Valley - Liele Elder, Valley Treant Elder, Unicorn Elder",
                    "Fields of Massacre - Wretched Archer, Doom Scout, Grave Lich",
                    "Catacomb of the Apostate - Lilim Knight Errant, Tomb Archon, Nephilim Bishop"
                ]
            },
            {
                grade: "A-Grade",
                img: null,
                locations: [
                    "Seal of Shilen - Trives, Kadios, Doom Knight",
                    "The Entrance of the Giant's Cave - Hamrut, Beast Lord, Kranrot",
                    "The Wall of Argos - Canyon Antelope Slave, Canyon Bandersnatch, Eye of Restrainer",
                    "Catacomb of the Witch - Crypt Guardian, Lilim Priest, Nephilim Centurion",
                    "Swamp of Screams - Needle Stakato Drone, Needle Stakato Soldier, Splinter Stakato Drone"
                ]
            },
            {
                grade: "S-Grade",
                img: null,
                locations: [
                    "Ketra Orc Outpost - Ketra Orc Footman, Ketra Orc Warrior, Ketra Orc Lieutenant",
                    "Imperial Tomb - Ghost of a Gatekeeper, Katraxis, Ashkenas, Royal Guard of Empire",
                    "Stakato Nest - Cannibalistic Stakato Leader, Spiked Stakato Sorcerer, Spiked Stakato Soldier",
                    "Hot Springs - Hot Springs Atrox, Hot Springs Antelope, Hot Springs Atroxspawn, Hot Springs Flava, Hot Springs Buffalo"
                ]
            }
        ]
    },
    {
        title: "Book of the Magician",
        grades: [
            // {
            //     grade: "D-Grade",
            //     img: null,
            //     locations: [
            //         "Windmill Hill - Vuku Orc Fighter, Arachnid Tracker, Poison Spider",
            //         "Ruins of Despair - Zombie Soldier, Ruin Imp Elder, Zombie Warrior",
            //         "Partisan's Hideaway - Ol Mahum Raider, Ol Mahum Sergeant",
            //         "Necropolis of Sacrifice - Gigant Slave, Gigant Acolyte, Gigant Overseer, Gigant Footman",
            //         "Forgotten Temple - Lakin Salamander, Lafi Lizardman, Lafi Lizardman Scout",
            //         "Field of Whispers - Rakul, Crokian Warrior, Crokian"
            //     ]
            // },
            {
                grade: "C-Grade",
                img: null,
                locations: [
                    "Plains of the Lizardman - Leto Lizardman Shaman, Leto Lizardman Soldier, Lord of the Plains",
                    "Ivory Tower Crater - Enchanted Iron Golem, Manashen Gargoyle",
                    "Cruma Tower 1 - Catherok, Ricenseo, Krator, Porta",
                    "Catacomb of Branded - Gigant Confessor, Gigant Commander, Nephilim Sentinel, Nephilim Priest",
                    "Dragon Valley - Shackle, Cave Servant, Cave Servant Archer",
                    "War-Torn Plains - Deprive, Hatar Ratman Boss, Hatar Hanishee",
                    "Cruma Tower 2 - Validus, Dicor, Ossiud"
                ]
            },
            {
                grade: "B-Grade",
                img: null,
                locations: [
                    "The Middle of the Dragon - Drake, Malruk Succubus, Thunder Wyrm",
                    "Outlaw Forest - Elder Tarlk Basilisk, Tarlk Basilisk, Hunter Gargoyle",
                    "Enchanted Valley - Fline Elder, Forest Runner, Unicorn Elder",
                    "Forsaken Plains - Marsh Stalker, Fallen Orc Shaman, Marsh Drake",
                    "Patriots Necropolis - Sepulcher Inquisitor, Lilim Marauder, Nephilim Swordsman"
                ]
            },
            {
                grade: "A-Grade",
                img: null,
                locations: [
                    "Valley of Saints - Flash of Splendor, Soul of Splendor, Shout of Splendor",
                    "The Wall of Argos - Canyon Antelope, Buffalo Slave, Eye of Guide",
                    "Blazing Swamp - Lava Wyrm, Conjurer Bat Lord, Cursed Guardian",
                    "Catacomb of the Witch - Purgatory Shadow, Nephilim Bishop, Lilim Knight",
                    "Forest of the Dead - Atrox, Ghost of Rebellion Leader, Requiem Priest"
                ]
            },
            {
                grade: "S-Grade",
                img: null,
                locations: [
                    "Varka Silenos Outpost - Varka Silenos Footman, Varka Silenos Shaman, Varka Silenos Scout",
                    "Forge of the Gods - Lavasaurus, Scarlet Stakato Noble, Tepra Scorpion",
                    "Monastery of Silence - Silent Seeker, Monastic Crusader, Solina Lay Brother",
                    "Hot Springs - Hot Springs Atrox, Hot Springs Antelope, Hot Springs Atroxspawn, Hot Springs Flava, Hot Springs Buffalo"
                ]
            }
        ]
    },
    {
        title: "Special Book of Power Skill 80+",
        description: "Drop from Saileren"
    },
    {
        title: "Special Book of Skill 81+",
        description: "Drop from the Lost Captain lvl 81 (Labyrinth)"
    }
]

export const startQuestsInfo = [
    {
        en: "Coupon for NG weapon(cannot be exchanged, augmented, dropped) can be obtained after completing 1 profession of full quest",
        ua: "Купон на зброю NG (не можна обміняти, посилити, викинути) можна отримати після завершення повного квесту на отримання 1 професії ",
        ru: "Купон на NG оружие (нельзя обменять, усилить, выбросить) можно получить после выполнения полного квеста на получение 1 профессии ",
        img: null
    },
    {
        en: "Alternative quest \"Lizardmen's Conspiracy\" (in Gludio town; Guard Praga) to obtain a coupon for mid D grade weapon(cannot be exchanged, augmented, dropped)",
        ua: "Альтернативний квест «Lizardmen's Conspiracy» (в Gludio, Guard Praga), щоб отримати купон на mid D зброю (не можна обміняти, вставити ЛС, викинути)",
        ru: "Альтернативный квест «Lizardmen's Conspiracy» (в Gludio, Guard Praga) для получения купона на mid D оружие (нельзя обменять, вставить ЛС, выбросить)",
        img: null
    },
    {
        en: "The quest for D grade armor can be taken in Gludin near GK , NPC - Hyum",
        ua: "Квест на D броню можна взяти в Gludin, біля GK, NPC - Hyum",
        ru: "Квест на D броню можно взять в Gludin, возле GK, NPC - Hyum",
        img: null
    },
    {
        en: "To receive a coupon for a top D grade weapon (cannot be exchanged, augmented, dropped but can be enchanted), you need to take 2nd professions for 3 marks (full quest or alternative quest for 2 professions at NPC Daeger у Giran Blacksmith's Shop)",
        ua: "Щоб отримати купон на топ D зброю (не можна обміняти, вставити ЛС, викинути, але можна заточувати), вам потрібно взяти 2-у професію за 3 марки (повний квест або альтернативний квест для 2 професій у NPC Daeger у Giran Blacksmith's Shop)",
        ru: "Для получения купона на топ D оружие (нельзя обменять, вставить ЛС, выкинуть, но можно заточить), вам нужно взять 2-ю профессию за 3 марки (полный квест или альтернативный квест на 2 профессию у NPC Daeger у Giran Blacksmith's Shop)",
        img: null
    },
    {
        en: "Buffs from Hot Springs can no longer be obtained from monsters, but there is an option to buy a bottle with this buff from an NPC Snape  for items that drop in this location",
        ua: "Бафи з Hot Springs більше не можна отримати від монстрів, але є можливість купити пляшку з цими бафами у NPC Snape  за предмети, які випадають у цьому місці",
        ru: "Баффы с Hot Springs больше нельзя получить с монстров, но есть возможность купить бутылку с этими бафами у NPC Snape  за предметы, которые выпадают в этой локации.",
        img: null
    }
]

export const jewelryInfo = [
    {
        grade: "B-Grade",
        items: [
            {
                name: "Adamantine",
                properties: [
                    "NeckLace - Stun Resistance +7%",
                    "Earring - Stun/Mental Resistance +3%",
                    "Ring - Root Resistance +3% P.Crit.Dmg. +1%"
                ]
            },
            {
                name: "Black Ore",
                properties: [
                    "NeckLace - Sleep/Paralysis Resistance +4% Elemental Resistance +2",
                    "Earring - Stun/Mental Resistance +4%",
                    "Ring - Root Resistance +4% P.Crit.Dmg. +2%"
                ]
            }
        ]
    },
    {
        grade: "A-Grade",
        items: [
            {
                name: "Phoenix",
                properties: [
                    "NeckLace - Sleep/Paralysis Resistance +6% Elemental Resistance +3",
                    "Earring - Stun/Mental Resistance +5%",
                    "Ring - Root Resistance +5% P.Crit.Dmg. +3%"
                ]
            },
            {
                name: "Majestic",
                properties: [
                    "NeckLace - Sleep/Paralysis Resistance +8% Elemental Resistance +4",
                    "Earring - Stun/Mental Resistance +6%",
                    "Ring - Root Resistance +6% P.Crit.Dmg. +4%"
                ]
            }
        ]
    },
    {
        grade: "S-Grade",
        items: [
            {
                name: "Tateossian",
                properties: [
                    "NeckLace - Sleep/Paralysis Resistance +10% Elemental Resistance +5",
                    "Earring - Stun/Mental Resistance +7%",
                    "Ring - Root Resistance +7% P.Crit.Dmg. +5%"
                ]
            }
        ]
    },
    {
        grade: "Epic Jewelry Upgrade",
        items: [
            "Improved Ring Of Core - MP+21, Poison Atk/Resistance +60%, P.Accuracy +3, HP reg +15%, Shield Def. and Chance +30%, P.Atk.Spd. +2%, P.Damage Reflect. +10%, STR +2, CON +2",
            "Improved Earring of Orfen - MP+31, MP reg +10%, M.Skill reuse -5%, M.Crit. rate +10%, Bleed Atk./Resistance +50%, Heal Power +15%, MEN +2, INT +2",
            "Improved Ring Of Ant Queen - MP+21, P.Accuracy +4, P.Crit.Damage +20%, Poison Atk./Resistance +60%, Root Atk./Resistance +40%",
            "Improved Earring Of Zaken - MP+31, Bleed Atk./Resistance +60%, Heal Recived +20%, P.Vamp. +8%, Stun Atk./Resistance +45%, Mental Atk./Resistance +45%",
            "Reinforced Baium's Ring - MP+21, Poison Atk./Resistance +80%, P.Accuracy +4, P.Crit.Damage +25%, Root Atk./Resistance +60%, Cast.Spd. /Atk. Spd. +6%"
        ]
    }
];

export const armorInfo = [
    {
        grade: "B-Grade",
        "sets": [
            {
                name: "Avadon Heavy",
                items: [
                    "Helmet - M.Def +1%, HP +75, Mental Resist +3.5%",
                    "Brestplate - M.Def +2%, HP +150, Mental Resist +7%",
                    "Gaiters - M.Def +1.5%, HP +100, Mental Resist +4.5%",
                    "Gloves - M.Def +0.75%, HP +50, Mental Resist +2.5%",
                    "Boots - M.Def +0.75%, HP +50, Mental Resist +2.5%",
                    "Shield - Magic skill resist +1%"
                ]
            },
            {
                name: "Avadon Light",
                items: [
                    "Helmet - M.Def +1.25%, Weight Limit +1440, +3.5%",
                    "Leather Armor - M.Def +2.625%, Weight Limit +2897, +11.5%",
                    "Gloves - M.Def +0.83%, Weight Limit +960,+2.5%",
                    "Boots - M.Def +0.83%, Weight Limit +960,+2.5%"
                ]
            },
            {
                name: "Avadon Robe",
                items: [
                    "Helmet - P.Def +1%, Mental resist +3.5%",
                    "Robe - P.Def +3.5%, Cast.Spd +2.5%, Mental resist +11.5%",
                    "Gloves - P.Def +0.75%, Mental resist +2.5%",
                    "Boots - P.Def +0.75%, Mental resist +2.5%"
                ]
            },
            {
                name: "Zubei's Heavy",
                items: [
                    "Helmet - P.Def +1%, HP +75, Paralysis resistance +3.5%",
                    "Brestplate - P.Def +2%, HP +150, Paralysis resistance +7%",
                    "Gaiters - P.Def +1.5%, HP +100, Paralysis resistance +4.5%",
                    "Gauntlet - P.Def +0.75%, HP +50, Paralysis resistance +2.5%",
                    "Boots - P.Def +0.75%, HP +50, Paralysis resistance +2.5%",
                    "Shield - Magic skill resist +1%"
                ]
            },
            {
                name: "Zubei's Light",
                items: [
                    "Helmet - P.Evansion +1, Atk.Spd. +0.6%, Paralysis resistance +3.5%",
                    "Leather Shirt - P.Evansion +2.5, Atk.Spd. +1%, Paralysis resistance +7%",
                    "Leather Gaiters - P.Evansion +1.5, Atk.Spd. 0.8%, Paralysis resistance 4.5%",
                    "Gauntlet - P.Evansion +0.75, Atk.Spd. +0.4%, Paralysis resistance +2.5%",
                    "Boots - P.Evansion +0.75, Atk.Spd. +0.4%, Paralysis resistance +2.5%"
                ]
            },
            {
                name: "Zubei's Robe",
                items: [
                    "Helmet - M.Atk +1.5%, Paralysis Resistance +3.5%",
                    "Tunic - M.Atk +4%, Paralysis Resistance +7%",
                    "Stockings - M.Atk +2.5%, Paralysis Resistance +4.5%",
                    "Gauntlet - M.Atk +1%, Paralysis Resistance +2.5%",
                    "Boots - M.Atk +1%, Paralysis Resistance +2.5%"
                ]
            },
            {
                name: "Blue Wolf Heavy",
                items: [
                    "Helmet - Run Spd +0.85, HP reg +1.5%, Stun Resistance +3.5%",
                    "Brestplate - Run Spd +1.75, HP reg +3.1%, Stun Resistance +7%",
                    "Gaiters - Run Spd +1, HP reg +2%, Stun Resistance +4.5%",
                    "Gloves - Run Spd +0.7, HP reg +1.2%, Stun Resistance +2.5%",
                    "Boots - Run Spd +0.7, HP reg +1.2%, Stun Resistance +2.5%"
                ]
            },
            {
                name: "Blue Wolf Light",
                items: [
                    "Helmet - P.Def +0.7%, Stun Resistance +3.5%",
                    "Leather Armor - P.Def +2.3%, Stun Resistance +11.5%",
                    "Gloves - P.Def +0.5%, Stun Resistance +2.5%",
                    "Boots - P.Def +0.5%, Stun Resistance +2.5%"
                ]
            },
            {
                name: "Blue Wolf Robe",
                items: [
                    "Helmet - Max MP +75, MP reg +2.1%, Stun Resistance +3.5%",
                    "Tunic - Max MP +150, MP reg +4.2%, Stun Resistance +7%",
                    "Stockings - Max MP +100, MP reg +2.7%, Stun Resistance +4.5%",
                    "Gloves - Max MP +50, MP reg +1.5%, Stun Resistance +2.5%",
                    "Boots - Max MP +50, MP reg +1.5%, Stun Resistance +2.5%"
                ]
            },
            {
                name: "Doom Heavy",
                items: [
                    "Helmet - Max HP +85, Sleep/Hold Resistance +3.5%",
                    "Plate Armor - Max HP +300, Sleep/Hold Resistance +11.5%",
                    "Gloves - Max HP +60, Sleep/Hold Resistance +2.5%",
                    "Boots - Max HP +60, Sleep/Hold Resistance +2.5%",
                    "Shield - Magic skill resist +1%"
                ]
            },
            {
                name: "Doom Light",
                items: [
                    "Helmet - P.Atk +1.2%, MP reg +2.1%, Sleep/Hold Resistance +3.5%",
                    "Leather Armor - P.Atk +2.25%, MP reg +6.9%, Sleep/Hold Resistance +11.5%",
                    "Gloves - P.Atk +0.75%, MP reg +1.5%, Sleep/Hold Resistance +2.5%",
                    "Boots - P.Atk +0.75%, MP reg +1.5%, Sleep/Hold Resistance +2.5%"
                ]
            },
            {
                name: "Doom Robe",
                items: [
                    "Helmet - Run Spd +0.85, MP reg +2.1%, Sleep/Hold Resistance +3.5%",
                    "Tunic - Run Spd +1.75, MP reg +4.2%, Sleep/Hold Resistance +6.5%",
                    "Stockings - Run Spd +1, MP reg +2.7%, Sleep/Hold Resistance +5%",
                    "Gloves - Run Spd +0.7, MP reg +1.5%, Sleep/Hold Resistance +2.5%",
                    "Boots - Run Spd +0.7, MP reg +1.5%, Sleep/Hold Resistance +2.5%"
                ]
            }
        ]
    },
    {
        grade: "A-Grade",
        "sets": [
            {
                name: "Dark Crystal Heavy",
                items: [
                    "Helmet - Healing Recived +2.5%, Cast.Spd +1.35%, Resist Paralysis +5.25%",
                    "Brestplate - Healing Recived +5%, Cast.Spd +2.5%, Resist Paralysis +10.5%",
                    "Gaiters - Healing Recived +3%, Cast.Spd +1.65%, Resist Paralysis +6.75%",
                    "Gloves - Healing Recived +1.5%, Cast.Spd +1%, Resist Paralysis +3.75%",
                    "Boots - Healing Recived +1.5%, Cast.Spd +1%, Resist Paralysis +3.75%",
                    "Shield - Magic skill resist +2%"
                ]
            },
            {
                name: "Dark Crystal Light",
                items: [
                    "Helmet - P.Atk.Spd. +0.75%, P.Atk +0.75%, Resist Paralysis +5.25%",
                    "Leather Armor - P.Atk.Spd. +1.5%, P.Atk +1.5%, Resist Paralysis +10.5%",
                    "Leggings - P.Atk.Spd. +1%, P.Atk +1%, Resist Paralysis +6.75%",
                    "Gloves - P.Atk.Spd. +0.4%, P.Atk +0.4%, Resist Paralysis +3.75%",
                    "Boots - P.Atk.Spd. +0.4%, P.Atk +0.4%, Resist Paralysis +3.75%"
                ]
            },
            {
                name: "Dark Crystal Robe",
                items: [
                    "Helmet - P.Def +1%, Run Spd. +1, Cancel Cast Chance -5.25%, Resist Paralysis +5.25%",
                    "Robe - P.Def +2.5%, Run Spd. +2.5, Cancel Cast Chance -17.5%, Resist Paralysis +17.5%",
                    "Gloves - P.Def +0.75%, Run Spd. +0.75, Cancel Cast Chance -3.75%, Resist Paralysis +3.75%",
                    "Boots - P.Def +0.75%, Run Spd. +0.75, Cancel Cast Chance -3.75%, Resist Paralysis +3.75%"
                ]
            },
            {
                name: "Tallum Heavy",
                items: [
                    "Helmet - P.Atk Spd. +1.15%, Weight Limit +1750, Poison/Bleed/Mental Resistance +5.25%",
                    "Plate Armor - P.Atk Spd. +2.35%, Weight Limit +5750, Poison/Bleed/Mental Resistance +7.25%",
                    "Gloves - P.Atk Spd. +0.75%, Weight Limit +1250, Poison/Bleed/Mental Resistance +3.25%",
                    "Boots - P.Atk Spd. +0.75%, Weight Limit +1250, Poison/Bleed/Mental Resistance +3.25%"
                ]
            },
            {
                name: "Tallum Light",
                items: [
                    "Helmet - Cast.Spd. +0.85%, Max MP +85, MP reg +5%, Poison/Bleed/Mental Resistance +5.25%",
                    "Leather Armor - Cast.Spd. +2.75%, Max MP +150, MP reg +8.65%, Poison/Bleed/Mental Resistance +17.25%",
                    "Gloves - Cast.Spd. +0.7%, Max MP +60, MP reg +3%, Poison/Bleed/Mental Resistance +3.75%",
                    "Boots - Cast.Spd. +0.7%, Max MP +60, MP reg +3%, Poison/Bleed/Mental Resistance +3.75%"
                ]
            },
            {
                name: "Tallum Robe",
                items: [
                    "Helmet - M.Def. +0.85%, Run Spd. +0.85, Poison/Bleed/Mental Resistance +5.25%",
                    "Tunic - Cast.Spd. +2.5%, M.Def. +1.75%, Run Spd. +1.75, Poison/Bleed/Mental Resistance +9.75%",
                    "Stockings - M.Def. +1%, Run Spd. +1, Poison/Bleed/Mental Resistance +7.5%",
                    "Gloves - M.Def. +0.7%, Run Spd. +0.7, Poison/Bleed/Mental Resistance +3.75%",
                    "Boots - M.Def. +0.7%, Run Spd. +0.7, Poison/Bleed/Mental Resistance +3.75%"
                ]
            },
            {
                name: "Majestic Heavy",
                items: [
                    "Helmet - P.Atk. +1.15%, P.Accuracy +0.85, Stun Resistance +5.25%",
                    "Plate Armor - P.Atk. +2.35%, P.Accuracy +1.55, Stun Resistance +17.25%",
                    "Gloves - P.Atk. +0.75%, P.Accuracy +0.6, Stun Resistance +3.75%",
                    "Boots - P.Atk. +0.75%, P.Accuracy +0.6, Stun Resistance +3.75%"
                ]
            },
            {
                name: "Majestic Light",
                items: [
                    "Helmet - P.Atk when using Bow +1.15%, Max MP +85, Stun Resistance +5.25%, Weight Limit +1750",
                    "Leather Armor - P.Atk when using Bow +2.5%, Max MP +150, Stun Resistance +17.25%, Weight Limit +5750",
                    "Gloves - P.Atk when using Bow +0.75%, Max MP +60, Stun Resistance +3.75%, Weight Limit +1250",
                    "Boots - P.Atk when using Bow +0.75%, Max MP +60, Stun Resistance +3.75%, Weight Limit +1250"
                ]
            },
            {
                name: "Majestic Robe",
                items: [
                    "Helmet - Max MP +85, MP reg +5%, Stun Resistance +3.75%",
                    "Robe - Cast.Spd. +2.5%, Max MP +150, MP reg +8.65%, Stun Resistance +17.25%",
                    "Gloves - Max MP +60, MP reg +3%, Stun Resistance +3.75%",
                    "Boots - Max MP +60, MP reg +3%, Stun Resistance +3.75%"
                ]
            },
            {
                name: "Nightmare Heavy",
                items: [
                    "Helmet - P.Atk +1.15%, Root/Sleep Resistance +5.25%",
                    "Plate Armor - P.Atk +2.35%, Root/Sleep Resistance +17.25%",
                    "Gloves - P.Atk +0.75%, Root/Sleep Resistance +3.75%",
                    "Boots - P.Atk +0.75%, Root/Sleep Resistance +3.75%",
                    "Shield - Magic skill resist +2%"
                ]
            },
            {
                name: "Nightmare Light",
                items: [
                    "Helmet - M.Def +1.5%, P.Vamp. +0.7%, Root/Sleep Resistance +5.25%",
                    "Leather Armor - M.Def +2.5%, P.Vamp. +2%, Root/Sleep Resistance +17.25%",
                    "Gloves - M.Def +1%, P.Vamp. +0.5%, Root/Sleep Resistance +3.75%",
                    "Boots - M.Def +1%, P.Vamp. +0.5%, Root/Sleep Resistance +3.75%"
                ]
            },
            {
                name: "Nightmare Robe",
                items: [
                    "Helmet - M.Atk. +2.5%, MP reg +5%, Root/Sleep Resistance +5.25%",
                    "Robe - M.Atk. +5%, MP reg +8.65%, Root/Sleep Resistance +17.25%",
                    "Gloves - M.Atk. +1.5%, MP reg +3%, Root/Sleep Resistance +3.75%",
                    "Boots - M.Atk. +1.5%, MP reg +3%, Root/Sleep Resistance +3.75%"
                ]
            }
        ]
    },
    {
        grade: "S-Grade",
        "sets": [
            {
                name: "Imperial Crusader Set",
                items: [
                    "Helmet - Max HP +125, P.Def +1.25%, Sleep/Hold Resistance +8.5%",
                    "Brestplate - Max HP +225, P.Def +2.5%, Sleep/Hold Resistance +12%",
                    "Gaiters - Max HP +150, P.Def +1.75%, Sleep/Hold Resistance +6.75%",
                    "Gauntlet - Max HP +85, P.Def +0.85%, Sleep/Hold Resistance +6%",
                    "Boots - Max HP +85, P.Def +0.85%, Sleep/Hold Resistance +6%",
                    "Shield - Magic skill resist +2%"
                ]
            },
            {
                name: "Draconic Leather Set",
                items: [
                    "Helmet - P.Atk.Spd. + 0.75%, P.Atk. +0.75%, Max MP +135, Weight Limit +1440",
                    "Leather Armor - P.Atk.Spd. + 1.35%, P.Atk. +1.35%, Max MP +225, Weight Limit +5500",
                    "Gloves - P.Atk.Spd. + 0.5%, P.Atk. +0.5%, Max MP +85, Weight Limit +960",
                    "Boots - P.Atk.Spd. + 0.5%, P.Atk. +0.5%, Max MP +85, Weight Limit +960"
                ]
            },
            {
                name: "Major Arcane Set",
                items: [
                    "Circlet - Cancel Cast Chance -7.5%, Stun Resistance +7.5%, M.Atk +1.5%, Run Spd. +1.5, Weight Limit +1440",
                    "Robe - Cancel Cast Chance -15%, Stun Resistance +15%, M.Atk +5%, Run Spd. +3, Weight Limit +5500",
                    "Gloves - Cancel Cast Chance -5%, Stun Resistance +5%, M.Atk +1%, Run Spd. +1, Weight Limit +960",
                    "Boots - Cancel Cast Chance -5%, Stun Resistance +5%, M.Atk +1%, Run Spd. +1, Weight Limit +960",
                    "Arcane Sigil - Magic Skill Resistance +2%, Cancel Cast Chance -13%"
                ]
            }
        ]
    }
]

export const fortressAndCastleInfo = [
    {
        name: "Gludio Castle",
        upgrades: [
            "Residence Health Lv. 1: Incoming Heal Power +8%",
            "Residence Guidance Lv. 1: Accuracy +4",
            "Residence Fortitude Lv. 1: Resistance Stun +10%",
            "Protection of Gludio: Stun attack +15%, Bleed resistance +15%, Water Resistance +8"
        ]
    },
    {
        name: "Dion Castle",
        upgrades: [
            "Residence Spirit Lv. 1: Max CP +12%",
            "Residence Shield Lv. 1: P. Def. +5%",
            "Residence Movement Lv. 1: Speed +5",
            "Protection of Dion: Hold attack +15%, Poison resistance +15%, Holy Resistance +8"
        ]
    },
    {
        name: "Giran Castle",
        upgrades: [
            "Residence Soul Lv. 1: Max MP +5%",
            "Residence Agility Lv. 1: Evasion +4",
            "Residence Death Fortune Lv. 1: When a clan member is killed, reduces Exp. points consumption rate",
            "Protection of Giran: Bleed attack +15%, Paralysis resistance +15%, Wind Resistance +8"
        ]
    },
    {
        name: "Oren Castle",
        upgrades: [
            "Residence Body Lv. 1: Max HP +7%",
            "Residence Empower Lv. 1: M. Atk. +8%",
            "Residence Resist Lava Lv. 1: Resistance to Fire +10, Resistance to Earth +10",
            "Protection of Oren: Paralysis attack +15%, Hold resistance +15%, Dark Resistance +8"
        ]
    },
    {
        name: "Aden Castle",
        upgrades: [
            "Residence Might Lv. 1: P. Atk. +6%",
            "Residence Shield Block Lv. 1: Shield Defense Rate +20%",
            "Residence Vigilance Lv. 1: Resistance Sleep +10%",
            "Protection of Aden: Mental attack +15%, Stun resistance +15%, Earth Resistance +8"
        ]
    },
    {
        name: "Innadril Castle",
        upgrades: [
            "Residence Clarity Lv. 1: MP Consumption -5%",
            "Residence Magic Barrier Lv. 1: M. Def. +5%",
            "Residence Freedom Lv. 1: Resistance Hold +10%",
            "Protection of Innadril: Sleep attack +15%, Stun resistance +15%, Water Resistance +8"
        ]
    },
    {
        name: "Goddard Castle",
        upgrades: [
            "Residence Body Lv. 1: Max HP +7%",
            "Residence Moral Lv. 1: CP Regeneration Bonus +25%",
            "Residence Shield Defense Lv. 1: Shield Defense +225",
            "Protection of Goddard: Stun attack +15%, Sleep resistance +15%, Fire Resistance +8"
        ]
    },
    {
        name: "Rune Castle",
        upgrades: [
            "Residence Health Lv. 1: Incoming Heal Power +8%",
            "Residence Magic Barrier Lv. 1: M. Def. +5%",
            "Residence Resist Typhoon Lv. 1: Resistance to Water +10, Resistance to Wind +10",
            "Protection of Rune: Poison attack +15%, Hold resistance +15%, Fire Resistance +8"
        ]
    },
    {
        name: "Schuttgart Castle",
        upgrades: [
            "Residence Soul Lv. 1: Max MP +5%",
            "Residence Guidance Lv. 1: Accuracy +4",
            "Residence Death Fortune Lv. 1: When a clan member is killed, reduces Exp. points consumption rate",
            "Protection of Schuttgart: Hold attack +15%, Fear resistance +15%, Wind Resistance +8"
        ]
    },
    {
        name: "Aaru Fortress",
        upgrades: [
            "Residence Soul Lv. 1: Max MP +5%",
            "Residence Clarity Lv. 1: MP Consumption -5%"
        ]
    },
    {
        name: "Antharas' Fortress",
        upgrades: [
            "Residence Shield Defense Lv. 1: Shield Defense +225",
            "Residence Empower Lv. 1: M. Atk. +8%"
        ]
    },
    {
        name: "Archaic Fortress",
        upgrades: [
            "Residence Body Lv. 1: Max HP +7%",
            "Residence Vigilance Lv. 1: Resistance Sleep +10%"
        ]
    },
    {
        name: "Bayou Fortress",
        upgrades: [
            "Residence Might Lv. 1: P. Atk. +6%",
            "Residence Empower Lv. 1: M. Atk. +8%"
        ]
    },
    {
        name: "Borderland Fortress",
        upgrades: [
            "Residence Spirit Lv. 1: Max CP +12%",
            "Residence Death Fortune Lv. 1: Reduced Exp. points consumption rate when a clan member is killed"
        ]
    },
    {
        name: "Cloud Mountain Fortress",
        upgrades: [
            "Residence Fortitude Lv. 1: Resistance Stun +10%",
            "Residence Death Fortune Lv. 1: Reduced Exp. points consumption rate when a clan member is killed"
        ]
    },
    {
        name: "Demon Fortress",
        upgrades: [
            "Residence Spirit Lv. 1: Max CP +12%",
            "Residence Moral Lv. 1: CP Regeneration Bonus +25%"
        ]
    },
    {
        name: "Dragonspine Fortress",
        upgrades: [
            "Residence Magic Barrier Lv. 1: M. Def. +5%",
            "Residence Resist Typhoon Lv. 1: Resistance to Water +10, Resistance to Wind +10"
        ]
    },
    {
        name: "Floran Fortress",
        upgrades: [
            "Residence Guidance Lv. 1: Accuracy +4",
            "Residence Freedom Lv. 1: Resistance Hold +10%"
        ]
    },
    {
        name: "Hive Fortress",
        upgrades: [
            "Residence Agility Lv. 1: Evasion +4",
            "Residence Resist Lava Lv. 1: Resistance to Fire +10, Resistance to Earth +10"
        ]
    },
    {
        name: "Hunter's Fortress",
        upgrades: [
            "Residence Might Lv. 1: P. Atk. +6%",
            "Residence Agility Lv. 1: Evasion +4"
        ]
    },
    {
        name: "Ivory Fortress",
        upgrades: [
            "Residence Moral Lv. 1: CP Regeneration Bonus +25%",
            "Residence Freedom Lv. 1: Resistance Hold +10%"
        ]
    },
    {
        name: "Monastic Fortress",
        upgrades: [
            "Residence Body Lv. 1: Max HP +7%",
            "Residence Health Lv. 1: Incoming Heal Power +8%"
        ]
    },
    {
        name: "Narsell Fortress",
        upgrades: [
            "Residence Health Lv. 1: Incoming Heal Power +8%",
            "Residence Vigilance Lv. 1: Resistance Sleep +10%"
        ]
    },
    {
        name: "Shanty (Camp) Fortress",
        upgrades: [
            "Residence Body Lv. 1: Max HP +7%",
            "Residence Shield Block Lv. 1: Shield Defense Rate +20%"
        ]
    },
    {
        name: "Southern Fortress",
        upgrades: [
            "Residence Shield Block Lv. 1: Shield Defense Rate +20%",
            "Residence Resist Typhoon Lv. 1: Resistance to Water +10, Resistance to Wind +10"
        ]
    },
    {
        name: "Swamp Fortress",
        upgrades: [
            "Residence Shield Lv. 1: P. Def. +5%",
            "Residence Guidance Lv. 1: Accuracy +4"
        ]
    },
    {
        name: "Tanor Fortress",
        upgrades: [
            "Residence Movement Lv. 1: Speed +5",
            "Residence Resist Lava Lv. 1: Resistance to Fire +10, Resistance to Earth +10"
        ]
    },
    {
        name: "Valley Fortress",
        upgrades: [
            "Residence Empower Lv. 1: M. Atk. +8%",
            "Residence Shield Defense Lv. 1: Shield Defense +225"
        ]
    },
    {
        name: "Western Fortress",
        upgrades: [
            "Residence Shield Lv. 1: P. Def. +5%",
            "Residence Shield Defense Lv. 1: Shield Defense +225",
            "Residence Death Fortune Lv. 1: Reduced Exp. points consumption rate when a clan member is killed"
        ]
    },
    {
        name: "White Sands Fortress",
        upgrades: [
            "Residence Soul Lv. 1: Max MP +5%",
            "Residence Magic Barrier Lv. 1: M. Def. +5%"
        ]
    }
]


