import {ES, GB, PT, RU, UA} from "country-flag-icons/react/3x2";
import {useContext, useState} from "react";
import {useLocalStorage} from "@mantine/hooks";
import {HeaderToggleContext} from "../../App";
import getText from "../../locale/lang";


export default function DesktopLangButton() {
    const {changeToggle, setChangeToggle} = useContext(HeaderToggleContext);
    const [, setLang] = useLocalStorage({key: 'lang', defaultValue: 'en'});
    const [show, setShow] = useState(false)
    const [touched, setTouched] = useState(false)


    const toggleShowing = () => {
        setShow(!show)
        setTouched(true)
    }

    const changeLang = (lang) => {
        setLang(lang);
        setShow(false);
        setChangeToggle(!changeToggle);
    }

    return (
        <div className="nk-share-buttons nk-share-buttons-left d-none d-md-flex" style={{zIndex: 1100}}>
            <ul>
                <li id={'w_button_lang'} onClick={toggleShowing}>
                    <span className={"nk-share-icon " + (touched ? "" : "fading-effect")} title="Share page on Facebook" data-share="facebook">
                        <i className={"fa fa-globe"} aria-hidden="true"></i>
                    </span>
                    <span className="nk-share-name">{getText('language')}</span>
                </li>

                {show &&
                    <>
                        <li onClick={() => changeLang('en')}>
                        <span id={'w_button_lang_gb'} className="nk-share-icon">
                            <GB title={getText('en')} style={{height: 15}}/>
                        </span>
                            <span className="nk-share-name">{getText('en')}</span>
                        </li>
                        <li onClick={() => changeLang('es')}>
                        <span id={'w_button_lang_es'} className="nk-share-icon">
                            <ES title={getText('es')} style={{height: 15}}/>
                        </span>
                            <span className="nk-share-name">{getText('es')}</span>
                        </li>
                        <li onClick={() => changeLang('pt')}>
                        <span id={'w_button_lang_pt'} className="nk-share-icon">
                            <PT title={getText('pt')} style={{height: 15}}/>
                        </span>
                            <span className="nk-share-name">{getText('pt')}</span>
                        </li>
                        <li onClick={() => changeLang('ua')}>
                        <span id={'w_button_lang_ua'} className="nk-share-icon">
                            <UA title={getText('ua')} style={{height: 15}}/>
                        </span>
                            <span className="nk-share-name">{getText('ua')}</span>
                        </li>
                        <li onClick={() => changeLang('ru')}>
                        <span id={'w_button_lang_ru'} className="nk-share-icon">
                            <RU title={getText('ru')} style={{height: 15}}/>
                        </span>
                            <span className="nk-share-name">{getText('ru')}</span>
                        </li>
                    </>
                }
            </ul>
        </div>
    )
}