import Gap from "../../components/Gap";
import {Helmet} from "react-helmet-async";
import {LINKS} from "../../App";
import getText from "../../locale/lang";
import {Container} from "@mantine/core";
import PolicyButtons from "./PolicyButtons";


export default function TermsAndConditions() {

    return (<Container>
        <Helmet>
            <title>Dares - {getText('termsAndConditions')} L2 Server</title>
            <link rel="canonical" href={`${LINKS.mainUrl}/`}/>
        </Helmet>
        <Gap/>
        <Gap/>
        <Gap/>
        <PolicyButtons position="start"/>
        <div>
            <h2>Terms and Conditions</h2>
            <ol>
                <li>
                    <b>Introduction</b>
                    <p>Welcome to dares.club. By accessing our website and using our services you agree to be bound by these Terms and Conditions (“Terms”). Please read these Terms carefully. If you disagree with any part of the terms you must not use our website or services.</p>
                </li>
                <li>
                    <b>Intellectual Property Rights</b>
                    <p>Other than content you own which you may have opted to include on this Website under these Terms dares.club and/or its licensors own all rights to the intellectual property and material contained in this Website. You are granted a limited license only
                        subject to the restrictions provided in these Terms for purposes of viewing the material contained on this Website.</p>
                </li>
                <li>
                    <b>Restrictions</b>
                    <p>You are expressly restricted from the following:</p>
                    <ol>
                        <li>Publishing any Website material in any media;</li>
                        <li>Selling sublicensing and/or otherwise commercializing any Website material;</li>
                        <li>Publicly performing and/or showing any Website material;</li>
                        <li>Using this Website in any way that is or may be damaging to this Website;</li>
                        <li>Using this Website in any way that impacts user access to this Website;</li>
                        <li>Using this Website contrary to applicable laws and regulations or in a way that causes or may cause harm to the Website or to any person or business entity;</li>
                        <li>Engaging in any data mining data harvesting data extracting or any other similar activity in relation to this Website or while using this Website;</li>
                        <li>Using this Website to engage in any advertising or marketing.</li>
                    </ol>
                </li>
                <li>
                    <b>Your Content</b>
                    <p>In these Terms “Your Content” shall mean any audio video text images or other material you choose to display on this Website. By displaying Your Content you grant dares.club a non-exclusive worldwide irrevocable sublicensable license to use reproduce adapt
                        publish translate and distribute it in any and all media.</p>
                    <p>Your Content must be your own and must not be infringing on any third party’s rights. Dares.club reserves the right to remove any of Your Content from this Website at any time without notice.</p>
                </li>
                <li>
                    <b>No warranties</b>
                    <p>This Website is provided “as is” with all faults and dares.club makes no express or implied representations or warranties of any kind related to this Website or the materials contained on this Website.</p>
                </li>
                <li>
                    <b>Limitation of liability</b>
                    <p>In no event shall dares.club nor any of its officers directors and employees be liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract tort or otherwise and dares.club including its
                        officers directors and employees shall not be liable for any indirect consequential or special liability arising out of or in any way related to your use of this Website.</p>
                </li>
                <li>
                    <b>Indemnification</b>
                    <p>You hereby indemnify to the fullest extent dares.club from and against any and/or all liabilities costs demands causes of action damages and expenses arising in any way related to your breach of any of the provisions of these Terms.</p>
                </li>
                <li>
                    <b>Severability</b>
                    <p>If any provision of these Terms is found to be unenforceable or invalid under any applicable law such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole and such provisions shall be deleted without affecting the
                        remaining provisions herein.</p>
                </li>
                <li>
                    <b>Variation of Terms</b>
                    <p>dares.club is permitted to revise these Terms at any time as it sees fit and by using this Website you are expected to review such Terms on a regular basis to ensure you understand all terms and conditions governing use of this Website.</p>
                </li>
                <li>
                    <b>Assignment</b>
                    <p>dares.club shall be permitted to assign transfer and subcontract its rights and/or obligations under these Terms without any notification or consent required. However you shall not be permitted to assign transfer or subcontract any of your rights and/or
                        obligations under these Terms.</p>
                </li>
                <li>
                    <b>Entire Agreement</b>
                    <p>These Terms including any legal notices and disclaimers contained on this Website constitute the entire agreement between dares.club and you in relation to your use of this Website and supersede all prior agreements and understandings with respect to the
                        same.</p>
                </li>
                <li>
                    <b>Governing Law & Jurisdiction</b>
                    <p>These Terms will be governed by and interpreted in accordance with the laws of the Country and State of operation and you submit to the non-exclusive jurisdiction of the state and federal courts located in for the resolution of any disputes.</p>
                </li>
                <li>
                    <b>Community</b>
                    <p>This is a private gaming community. Here you can find an emulator of an amazing game that was extinct many years ago but a few people still love and enjoy more than 14 years later. This is a humble fan project intended for nostalgic players that want to
                        experience a travel to the past. This cannot replace the modern game by any means for more information about it we recommend you to visit the official website.</p>
                </li>
                <li>
                    <b>Game Rules</b>
                    <p>ANY kind of cheating is strictly prohibited and will result in an immediate and irreversible BAN. This includes but not limited to the usage of third party software like L2Walker Adrenaline Hlapex Macro programs etc. It is also forbidden to take advantage
                        of any in-game bug such a bugged quest with reward higher than normal making trains where mobs get stuck in walls etc.RMT (Real Money Trades) is strictly forbidden and will lead to a permanent ban of all accounts involved.</p>
                </li>
            </ol>


        </div>

    </Container>)
}