import React from 'react';
import getText from "../../../locale/lang";

const RareArmor = ({info}) => {
    return (
        <div className="bg-dark-2" style={{marginBottom: 16, padding: 20, display: "flex", alignItems: 'flex-start', flexDirection: 'column'}}>
            <h4>{getText('rareArmor')}</h4>
            {info.armorInfo.map((gradeItem, index) => {
                return <div key={index}>
                    <h5>{gradeItem.grade}</h5>
                    <ul>
                        {gradeItem.sets.map((set, setIndex) => {
                            return (
                                <li key={setIndex}>
                                    {set?.name}
                                    <ul>
                                        {set.items.map((item, itemIndex) => (
                                            <li key={itemIndex}>{item}</li>
                                        ))}
                                    </ul>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            })}
        </div>
    );
};

export default RareArmor;