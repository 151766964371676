import Gap from "../../components/Gap";
import {Helmet} from "react-helmet-async";
import {LINKS} from "../../App";
import getText from "../../locale/lang";
import {Container} from "@mantine/core";
import PolicyButtons from "./PolicyButtons";


export default function RefundPolicy() {

    return (<Container>
        <Helmet>
            <title>Dares - {getText('refundPolicy')} L2 Server</title>
            <link rel="canonical" href={`${LINKS.mainUrl}/`}/>
        </Helmet>
        <Gap/>
        <Gap/>
        <Gap/>
        <PolicyButtons position="start"/>
        <div>
            <h2>Refund Policy</h2>
            <ol>
                <li>
                    <b>General provisions and terms used</b>
                    <ol>
                        <li>Game Website – <a href="https://dares.club">https://dares.club</a>.</li>
                        <li>The site administration – is the copyright holder of the project, its web resources, and all materials that are posted on them. Is a party to the User Agreement. Provides administration and maintenance, provides users with access to the project and
                            services.
                        </li>
                        <li>User – an individual who uses project resources. A user who has passed the registration procedure and received a login and password is a registered user and has his own Account (account). Any User accessing and using the project resources automatically
                            agrees to this User Regulation and accepts the requirements established by this User Regulation.
                        </li>
                        <li>Account/user profile – a special subsection of the project protected by a password. The Profile contains information about the User which is posted by the User himself.</li>
                    </ol>
                </li>
                <li>
                    <b>Refund of funds</b>
                    <p>You can contact our support team 24/7 in discord support chat <a href="https://discord.gg/dares-club">https://discord.gg/dares-club</a> or in the email <a href="mailto:support@dares.club">support@dares.club</a></p>
                    <p>Goods purchased by the User on this site are intangible which means that they cannot be returned physically.</p>
                    <p>By replenishing the balance of your Account you agree that the service is considered to be provided from the moment of receipt of funds to your Account. The funds are credited to your Account at the time of payment.</p>
                    <p>Refunds for the services rendered are not made. The site administration may at its discretion return game items lost by the user in cases of error, failure, hacking or other malfunction of the server software.</p>
                </li>
            </ol>

        </div>

    </Container>)
}