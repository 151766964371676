import Gap from "../../components/Gap";
import {Helmet} from "react-helmet-async";
import {LINKS} from "../../App";
import getText from "../../locale/lang";
import {Container} from "@mantine/core";
import PolicyButtons from "./PolicyButtons";


export default function ServerRules() {

    return (<Container>
        <Helmet>
            <title>Dares - {getText('serverRules')} L2 Server</title>
            <link rel="canonical" href={`${LINKS.mainUrl}/`}/>
        </Helmet>
        <Gap/>
        <Gap/>
        <Gap/>
        <PolicyButtons position="start"/>
        <div>
            <h2>Server Rules</h2>
            <ol>
                <li>
                    <b>Bot</b>
                    <p>The use of automation tools is prohibited. You can only use macros in-game to the extent allowed by the Macro function.</p>
                </li>
                <li>
                    <b>Real Money Trading</b>
                    <p>Real Money Trading is prohibited. In-game goods, characters, or accounts cannot be exchanged for real-world currency.</p>
                </li>
                <li>
                    <b>Exploit</b>
                    <p>Users are obligated to report any bugs they encounter during their gaming experience for correction by the Staff. Deliberate and/or repeated use of a bug for the user's or third parties' benefit will result in penalties.</p>
                </li>
                <li>
                    <b>Scam</b>
                    <p>Scamming another player is not allowed. This encompasses various concepts, such as scamshops (maliciously selling something with a misleading title with the intent to defraud with a fake item or quantity) or accessing someone else's account to take items
                        that legitimately belong to the account owner. In extreme cases where the damage to the scammed player is severe (S-grade equipment, epic jewelry, etc.), the staff reserves the option to provide compensation.</p>
                </li>
            </ol>

        </div>

    </Container>)
}