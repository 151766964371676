import footerCorner from "../img/footer-corner.png";
import Gap from "../components/Gap";
import FooterButton from "../components/Features/FooterButton";
import {HeaderToggleContext, LINKS} from "../App";
import {useContext, useEffect} from "react";
import getText from "../locale/lang";
import DesktopLangButton from "../components/Header/DesktopLangButton";
import {Container} from "@mantine/core";
import {useCookieContext} from "../CookieContext";
import PolicyButtons from "../pages/Policy/PolicyButtons";


export default function Footer() {
    const {changeToggle} = useContext(HeaderToggleContext);
    const {refKey} = useCookieContext();

    useEffect(() => {
    }, [refKey, changeToggle])

    return (
        <>
            <DesktopLangButton/>
            <div className="adaptive-height"></div>
            <Gap/>
            <Gap/>
            <Gap/>

            <footer className="nk-footer nk-footer-parallax nk-footer-parallax-opacity adaptive-height" style={{position: 'absolute', bottom: 0, width: '100%'}}>
                <img className="nk-footer-top-corner" src={footerCorner} alt=""/>
                <Container>
                    <div className="nk-gap"></div>
                    <div style={{display: "flex", justifyContent: "left", width: '100%'}}>

                        <div className="nk-footer-links" style={{display: "flex", justifyContent: "start", flexDirection: "row", flexWrap: "wrap", alignItems: "end", width: '100%'}}>
                            <FooterButton matomo_id={'w_button_signup_footer'} to={`${LINKS.signup}${refKey ? `?utm_source=${refKey}` : ''}`} className="link-effect">{getText('signup')}</FooterButton>
                            <FooterButton matomo_id={'w_button_donate_footer'} to={`${LINKS.donate}${refKey ? `?utm_source=${refKey}` : ''}`} className="link-effect">{getText('donate')}</FooterButton>
                            <FooterButton matomo_id={'w_button_discord_footer'} to={LINKS.discord} className="link-effect">{getText('discord')}</FooterButton>
                        </div>

                    </div>
                    <Gap/>
                    {/*{window.screen.width > 767 &&*/}
                    {/*    <div style={{justifyContent: 'space-evenly', display: 'flex', flexWrap: 'wrap'}}>*/}
                    {/*        <a href="https://l2anons.info/" className="review-link" target="_blank" rel="noreferrer">*/}
                    {/*            <img*/}
                    {/*                src="https://images.l2anons.info/logo_L2Anons.png"*/}
                    {/*                height={31}*/}
                    {/*                alt="Новые сервера Lineage 2, список серверов, анонсы серверов Lineage 2"*/}
                    {/*                title="Новые сервера Lineage 2, список серверов, анонсы серверов Lineage 2"*/}
                    {/*                border={0}*/}
                    {/*            />*/}
                    {/*        </a>*/}
                    {/*        <a href="https://l2argument.ru">*/}
                    {/*            <img*/}
                    {/*                src="https://l2argument.ru/assets/images/l2argument3.jpg"*/}
                    {/*                title="База знаний и рейтинг серверов Lineage 2"*/}
                    {/*                alt="База знаний и рейтинг серверов Lineage 2"*/}
                    {/*                height={31}*/}
                    {/*                border={0}*/}
                    {/*            />*/}
                    {/*        </a>*/}
                    {/*        <a href="https://l2an.com" className="review-link" target="_blank" rel="noreferrer">*/}
                    {/*            <img*/}
                    {/*                src="https://l2an.com/bnrs/l2anbn.jpg"*/}
                    {/*                title="Анонсы серверов Lineage 2"*/}
                    {/*                alt="Анонсы серверов Lineage 2"*/}
                    {/*                height={31}*/}
                    {/*                border={0}*/}
                    {/*            />*/}
                    {/*        </a>*/}
                    {/*        <a href="https://l2new.ru/" className="review-link" target="_blank" rel="noreferrer">*/}
                    {/*            <img*/}
                    {/*                src="https://l2new.ru/l2/images/promo.gif"*/}
                    {/*                title="Анонсы новых серверов Lineage 2 на l2new.ru"*/}
                    {/*                alt="Анонсы новых серверов Lineage 2 на l2new.ru"*/}
                    {/*                height={31}*/}
                    {/*                border={0}*/}
                    {/*            />*/}
                    {/*        </a>*/}
                    {/*        <a href="https://l2network.eu/index.php?a=in&u=Dares" className="review-link" target="_blank" rel="noreferrer">*/}
                    {/*            <img*/}
                    {/*                src="https://l2network.eu/button.php?u=Dares&buttontype=static"*/}
                    {/*                title="L2Network"*/}
                    {/*                alt="L2Network"*/}
                    {/*                height={31}*/}
                    {/*                border={0}*/}
                    {/*            />*/}
                    {/*        </a>*/}
                    {/*        <a href="https://www.l2-servera.com" className="review-link" target="_blank" rel="noreferrer">*/}
                    {/*            <img*/}
                    {/*                src="https://www.l2-servera.com/wp-content/themes/servers/assets/images/button/black.gif"*/}
                    {/*                title="Анонсы серверов Lineage 2."*/}
                    {/*                alt="Анонсы серверов Lineage 2."*/}
                    {/*                height={31}*/}
                    {/*                border={0}*/}
                    {/*            />*/}
                    {/*        </a>*/}
                    {/*        <a href="https://l2-top.ru" className="review-link" target="_blank" rel="noreferrer">*/}
                    {/*            <img*/}
                    {/*                src="https://l2-top.ru/img/button1.gif"*/}
                    {/*                title="Анонсы серверов Lineage 2, Interlude, Gracia Final, High Five, Epilogue, Freya, GOD, Classic, Essence"*/}
                    {/*                alt='Анонсы серверов Lineage 2, Interlude, Gracia Final, High Five, Epilogue, Freya, GOD, Classic, Essence'*/}
                    {/*                height={31}*/}
                    {/*                border={0}*/}
                    {/*            />*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {/*<Gap/>*/}
                    <div className="row" style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: '100%'}}>
                        <div className="nk-footer-links" style={{display: "flex", justifyContent: "start"}}>
                            <p>Ⓒ Dares 2021 - {new Date().getFullYear()}</p>
                        </div>
                        <PolicyButtons position="end"/>
                    </div>
                </Container>
            </footer>
        </>
    )
}