import React from 'react';
import getText from "../../../locale/lang";

const FortressAndCastle = ({info}) => {
    return (
        <div className="bg-dark-2" style={{marginBottom: 16, padding: 20, display: "flex", alignItems: 'flex-start', flexDirection: 'column'}}>
            <h4>{getText('fortressAndCastle')}</h4>
            {info.fortressAndCastleInfo.map((location, index) => {
                return <div key={index}>
                    <h5>{location.name}</h5>
                    <ul>
                        {location.upgrades.map((upgrade, upgradeIndex) => {
                            return (
                                <li key={upgradeIndex}>
                                    {upgrade}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            })}
        </div>
    );
};

export default FortressAndCastle;