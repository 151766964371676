import Gap from "../components/Gap";
import FooterButton from "../components/Features/FooterButton";
import {Center, Modal} from "@mantine/core";
import {useState} from "react";
import getText from "../locale/lang";


export default function Vote() {
    const [opened, setOpened] = useState(false);

    return (
        <li>
            <button
                className="link-effect-4 ready"
                style={{backgroundColor: '#060606', border: 'none', color: '#ffffff', fontWeight: 600}}
                onClick={() => setOpened(true)}
                id={'w_button_vote'}
            >
                <span className="link-effect-inner">
                    <span className="link-effect-l">
                        <span>{getText('vote')}</span>
                    </span>
                    <span className="link-effect-r">
                        <span>{getText('vote')}</span>
                    </span>
                    <span className="link-effect-shade">
                        <span>{getText('vote')}</span>
                    </span>
                </span>
            </button>
            <Modal opened={opened} onClose={() => setOpened(false)} centered title={`Take your reward`} size="auto"
                   styles={() => ({
                       root: {
                           zIndex: 99999,
                       },
                       title: {
                           fontFamily: 'Marcellus SC',
                       },
                       modal: {
                           backgroundColor: '#181818',
                           color: '#fff',
                       },
                       body: {
                           display: 'flex'
                       },
                       close: {
                           '&:hover': {
                               backgroundColor: '#222222',
                               color: '#fff'
                           }
                       }
                   })}
            >
                <Gap/>
                <Center style={{display: 'flex', flexDirection: 'column'}}>
                    <FooterButton to='https://l2.hopzone.net/site/vote/105846/1' className="link-effect">hopzone</FooterButton>
                    <FooterButton to='https://top.l2jbrasil.com/index.php?a=in&u=DaresAdmin' className="link-effect">l2jbrasil</FooterButton>
                </Center>
            </Modal>
        </li>
    )
}