import {useEffect} from "react";
import axios from "axios";
import {HOME_PAGE, LINKS} from "../App";
import {useNavigate, useParams} from "react-router-dom";
import {is_referral_key_exist} from "../misc";


export default function Promo() {
    const {referralKey} = useParams()
    const navigate = useNavigate();
    
    useEffect(() => {
        if (is_referral_key_exist()){
            navigate(HOME_PAGE)
            return
        }
        let date = new Date(new Date().setMonth(new Date().getMonth()+1)).toUTCString();
        document.cookie = `referral_key=${referralKey.toLowerCase()};path=/;domain=.${LINKS.cookieDomain};expires=${date}`
        axios.post(`${LINKS.apiUrl}/referral/promo`, {referralKey: referralKey.toLowerCase()}).then(() => {})
        navigate(HOME_PAGE)
    }, [navigate, referralKey])
}