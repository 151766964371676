import PalletButton from "../components/PalletButton";
import Gap from "../components/Gap";
import {isMobile, LINKS} from "../App";
import {Helmet} from "react-helmet-async";
import getText from "../locale/lang";
import ReactPixel from "react-facebook-pixel";
import {Container} from "@mantine/core";
import {deleteFramingSymbols} from "../misc";


export default function Download() {


    const downloadPixelEvent = () => {
        ReactPixel.track('Lead');
    }

    return (
        <>
            <Helmet>
                <title>Dares - {getText('download')} L2 Server</title>
                <link rel="canonical" href={`${LINKS.mainUrl}/`}/>
            </Helmet>
            {isMobile && <><Gap/><Gap/><Gap/><Gap/></>}
            <Gap/>
            <Container>
                <Gap/>
                <Gap/>
                <div className="row align-items-center">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="row no-gutters" style={{display: "block"}}>
                            <PalletButton id={`w_btn_download_updater_${localStorage.getItem('server') ? deleteFramingSymbols(localStorage.getItem('server').split('-')[1]) : '6'}`} icon={<ion-icon name="cloud-download-outline"></ion-icon>}
                                          href={LINKS.download.remastered.autoUpdater}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          onClick={downloadPixelEvent}>
                                <h2 className="nk-ibox-title">{getText('autoUpdater')}</h2>
                                <span>{getText('fasterWay')}</span>
                            </PalletButton>
                        </div>
                        <div className="row no-gutters" style={{justifyContent: 'center'}}>
                            <PalletButton id={`w_btn_download_google_${localStorage.getItem('server') ? deleteFramingSymbols(localStorage.getItem('server').split('-')[1]) : '6'}`} icon={<i className='fa-brands fa-google-drive'></i>}
                                          href={LINKS.download.remastered.googleDrive}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          onClick={downloadPixelEvent}>
                                <h2 className="nk-ibox-title">{getText('client')}</h2>
                            </PalletButton>
                            <PalletButton id={`w_btn_download_mega_${localStorage.getItem('server') ? deleteFramingSymbols(localStorage.getItem('server').split('-')[1]) : '6'}`} icon={<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="119.5 239.5 721 721">
                                <circle cx="480" cy="600" r="360.5" fill="#fff"/>
                                <path fill="#0e0e0e" d="M267 454h85l128 130 129-130h82v293h-86V577.25L500.334 682h-40.668L355 577.25V747h-88z"/>
                            </svg>}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={LINKS.download.remastered.MEGA} onClick={downloadPixelEvent}>
                                <h2 className="nk-ibox-title">{getText('client')}</h2>
                            </PalletButton>
                        </div>
                    </div>
                </div>
                <Gap/>
                <h1 style={{textAlign: 'center', fontSize: 100}}>{getText('howToPlay')}</h1>
                <div className="row vertical-gap">
                    <div className="col-md-12">
                        <div className="nk-plain-video">
                            <iframe
                                id={'w_video'}
                                src="https://www.youtube.com/embed/8FtWbL4Sp2g" title="dates.club updater"
                                width="500" height="281"
                                webkitallowfullscreen="" mozallowfullscreen=""
                                allowFullScreen="" style={{border: 'none'}}
                                loading="lazy"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}