import React from 'react';
import getText from "../../../locale/lang";
import {LINKS} from "../../../App";

const mainInfo = [
    {img: 'https://images.dares.club/Icon/skill_i/skill0755.png', name: 'Protection of Rune (increase M.Def)'},
    {img: 'https://images.dares.club/Icon/skill_i/skill0756.png', name: 'Elemental of Rune (Increase Elemental Def.)'},
    {img: 'https://images.dares.club/Icon/skill_i/skill0757.png', name: 'Alignment of Rune (Increase tendency Def.)'},
    {img: 'https://images.dares.club/Icon/skill_i/skill0758.png', name: 'Fighter\'s Will (+5% skill power,atk.spd.p.skill reuse)'},
    {img: 'https://images.dares.club/Icon/skill_i/skill0759.png', name: 'Archer\'s Will (+5% skill power,atk.spd., +50 Range)'},
    {img: 'https://images.dares.club/Icon/skill_i/skill0945.png', name: 'Magic\'s Will (+5% M.atk / cast.spd. decrease mp cons. 5%)'},
]

const SkillsInfo = ({info}) => {
    return (
        <div className="bg-dark-2" style={{marginBottom: 16, padding: 20, display: "flex", alignItems: 'flex-start', flexDirection: 'column'}}>
            <h4>{getText('skillChanges')}</h4>
            <ul>
                <li>{getText('skillFirstLi')}</li>
                <li>{getText('skillSecondLi')}</li>
                <li>For all:
                    <ul style={{listStyle: 'none', display: "flex", flexDirection: 'column', gap: 10}}>
                        {mainInfo.map((item, index) => {
                            return (
                                <li key={index} style={{listStyle: 'none', display: "flex", flexDirection: 'row', gap: 10}}>
                                    <img src={item.img} alt=""/>
                                    <span>{item.name}</span>
                                </li>
                            )
                        })
                        }
                    < /ul>
                </li>
            </ul>
            {info.skillsInfo.map((info, index) => {
                return <React.Fragment key={index}>
                    <h5>{info.class}</h5>
                    <ul style={{listStyle: 'none'}}>
                        {info?.general &&
                            <li>
                                <a href={`${LINKS.mainUrl}/database/classes/${localStorage.getItem('server')}`}><h6>General</h6></a>
                                <ul style={{listStyle: 'none'}}>
                                    {info.general.map((item, index) => (
                                        <li key={index}>
                                            <img src={item.img} alt=""/>
                                            <span style={{marginLeft: 10}} dangerouslySetInnerHTML={{__html: item.change}}></span>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        }

                        {info.profs.map((profsItem, index) => (
                            <li key={index} style={{marginTop: 25}}>
                                <a href={`${LINKS.mainUrl}/database/classes/${localStorage.getItem('server')}`}><h6>{profsItem.prof}</h6></a>
                                <ul style={{listStyle: 'none'}}>
                                    {profsItem.changes.map((item, index) => (
                                        <li key={index} style={{marginBottom: 5,}}>
                                            <img src={item.img} alt=""/>
                                            <span style={{marginLeft: 10}} dangerouslySetInnerHTML={{__html: item.change}}></span>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </React.Fragment>
            })}

        </div>
    )
};

export default SkillsInfo;