import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet-async";
import getText from "../../../locale/lang";
import {LINKS} from "../../../App";
import {Center, Container, Table} from "@mantine/core";
import Gap from "../../../components/Gap";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import ScrollToTopButton from "../../../components/ScrollToTopButton";
import BackButton from "../../../components/BackButton";
import useSkills from "../../../hooks/useSkills";
import useItems from "../../../hooks/useItems";

const skillTypes = {
    0: "Physical skills",
    1: "Magic skills",
    2: "Boosting Skills",
    3: "Weakening Skills",
    4: "Clan/Hero/Mentor skills",
    5: "Item Skills",
    6: "Toggle skills",
    7: "Transformation Skills",
    11: "Equipment Skills",
    12: "Abilities",
    13: "Racial skills",
    14: "Additional skills",
    15: "Item Skills (Clan/Hero/Mentoring Skills)",
    16: "Item Skills (Equip Skills)"
};


const DatabaseProf = () => {
    const {id, server} = useParams()
    const [skilllearns, setSkilllearns] = useState(undefined)
    const {skills, getSkillIconSrc} = useSkills()
    const {items} = useItems()
    const [profSkills, setProfSkills] = useState(undefined)
    const [getLevels, setGetLevels] = useState(undefined)
    const [mapping, setMapping] = useState(undefined)

    useEffect(() => {
        axios.get('https://new.dares.club/database/skilllearns.json').then(({data}) => {
            setSkilllearns(data)
            setProfSkills(data[id])
            setGetLevels(Object.keys(data[id]))
        })
        axios.get('https://new.dares.club/database/mapping.json').then(({data}) => {
            setMapping(data)
        })
    }, [id])

    useEffect(() => {

    }, []);

    return (
        <>
            <Helmet>
                <title>Dares - {getText('database')} L2 Server</title>
                <link rel="canonical" href={`${LINKS.mainUrl}/`}/>
            </Helmet>

            <Container>
                <ScrollToTopButton/>
                <BackButton/>
                <Gap/>
                <Gap/>
                <Gap/>
                {!(skilllearns && skills) ? <Center><h3>{getText('loading')}</h3></Center> : <div style={{padding: 20, backgroundColor: '#060606', lineHeight: 1.5}}>
                    <Container>
                        <center><h1>{mapping.names[id]}</h1></center>
                        {getLevels.map((getLevel, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Table style={{color: '#f8f9fa'}} verticalSpacing="sm">
                                        <thead>
                                        <tr>
                                            <th colSpan={9} style={{textAlign: 'center', color: '#f8f9fa'}}>Level: {getLevel}</th>
                                        </tr>
                                        <tr>
                                            <th>Icon</th>
                                            <th>Name</th>
                                            <th width={52} style={{textAlign: 'center'}}>SP</th>
                                            <th width={115} style={{textAlign: 'center'}}>Type</th>
                                            <th width={61} style={{textAlign: 'center'}}>Magic</th>
                                            <th width={150} style={{textAlign: 'center'}}>Spell Book</th>
                                        </tr>
                                        </thead>
                                        <tbody className={'custom-striped-tbody'}>
                                        {profSkills[getLevel].map((skill, index) => {
                                            let skill_data
                                            try {
                                                skill_data = skills[skill.id][skill.level][0]
                                            } catch (e) {
                                                return null
                                            }
                                            return (
                                                <tr key={index}>
                                                    <td><img src={getSkillIconSrc(skill_data)} alt={skill_data.name}/></td>
                                                    <td>
                                                        <Link to={`/database/skill/${skill.id}/${server}`}>
                                                            <span style={{color: '#e08821', fontSize: 16}}>{skill_data.name}</span>
                                                        </Link>
                                                        <span style={{color: 'gray', fontSize: 16}}> Lvl {skill_data.level}</span>
                                                        <span style={{display: 'block', color: '#f8f9fa', fontSize: 16, lineHeight: 1.8, wordSpacing: 1.2}} dangerouslySetInnerHTML={{__html: skill_data.desc.replace(/\\n/gi, '<br/>')}} />
                                                    </td>
                                                    <td style={{textAlign: 'center', color: '#f8f9fa', fontSize: 16, lineHeight: 1.8, wordSpacing: 1.2}}>{skill.sp}</td>
                                                    <td style={{textAlign: 'center', color: '#f8f9fa', fontSize: 16, lineHeight: 1.8, wordSpacing: 1.2}}>{skillTypes[skill_data.icon_type]}</td>
                                                    <td style={{textAlign: 'center', color: '#f8f9fa', fontSize: 16, lineHeight: 1.8, wordSpacing: 1.2}}>{skill_data.is_magic === '1' ? 'Yes' : 'No'}</td>
                                                    <td style={{textAlign: 'center', color: '#f8f9fa', fontSize: 16, lineHeight: 1.8, wordSpacing: 1.2}}>{items[skill.item] ? `${skill.itemCount} x ${items[skill.item].name}` : '-'}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </Table>
                                    <Gap/>
                                </React.Fragment>
                            )
                        })}
                    </Container>
                </div>}
            </Container>
        </>
    );
};

export default DatabaseProf;