export default function PageBorder() {

    return(
        <div className="nk-page-border">
            <div className="nk-page-border-t"></div>
            <div className="nk-page-border-r"></div>
            <div className="nk-page-border-b"></div>
            <div className="nk-page-border-l"></div>
        </div>
    )
};