import React from "react";
import getText from "../../../locale/lang";


export default function RatesChroniclesInfo({info}) {
    return (
        <div className="bg-dark-2" style={{
            marginBottom: 16,
            padding: 20,
            display: "flex",
            alignItems: 'flex-start',
            flexDirection: 'column'
        }}>
            <h4>{getText('rates')}</h4>
            <ul>
                {info.ratesInfo.rates.map((desc, index) => (
                    <li key={index} dangerouslySetInnerHTML={{__html: desc}}/>
                ))}
            </ul>

            <h5>{getText('premiumRates')}</h5>
            <ul>
                {info.ratesInfo.premiumRates.map((desc, index) => (
                    <li key={index} dangerouslySetInnerHTML={{__html: desc}}/>
                ))}
            </ul>

            <p>{getText('ratesAttention')}</p>
        </div>
    )
}