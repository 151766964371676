import React, {createContext, useContext, useState, useEffect, useCallback} from 'react';

export function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookieValue(name) {
    const nameString = `${name}=`;
    const value = document.cookie.split('; ').find(row => row.startsWith(nameString));
    return value ? decodeURIComponent(value.split('=')[1]) : null;
}

// Создание контекста
const CookieContext = createContext();

// Хук для удобного доступа к контексту
export function useCookieContext() {
    return useContext(CookieContext);
}

// Провайдер контекста
export function CookieProvider({children}) {
    const [refKey, setRefKey] = useState(null);

    // Функция для обновления куки
    const updateCookie = (name, value, days) => {
        setCookie(name, value, days); // Установка куки
    };

    const updateRefKey = useCallback((value) => {
        setCookie('referral_key', value, 31); // Установка куки
        setRefKey(value); // Обновление состояния в контексте
    }, []);

    // Подписка на изменения куки (можно реализовать через эффекты или другой механизм)
    useEffect(() => {
        setRefKey(getCookieValue('referral_key'));
    }, []);

    const value = {
        refKey,
        updateCookie,
        updateRefKey
    };

    return <CookieContext.Provider value={value}>{children}</CookieContext.Provider>;
}
