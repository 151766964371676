import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {HelmetProvider} from "react-helmet-async";
import {Provider} from "react-redux";
import store from "./store";
import {MantineProvider} from "@mantine/core";
import PageBorder from "./components/PageBorder";
import {BrowserRouter} from "react-router-dom";
import {CookieProvider} from "./CookieContext";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <MantineProvider theme={{
                fontFamily: "Marcellus SC",
                headings: {
                    fontFamily: "Marcellus SC",
                },
                components: {
                    Container: {
                        styles: {
                            root: {
                                width: '100%',
                                '@media (min-width: 1980px)': {
                                    maxWidth: 1920,
                                },
                                '@media (min-width: 2560px)': {
                                    maxWidth: 2500,
                                },
                            }
                        },
                        defaultProps: {size: 'xl'}
                    },
                }
            }}>
                <HelmetProvider>
                    <PageBorder/>
                    <BrowserRouter>
                        <CookieProvider>
                            <App/>
                        </CookieProvider>
                    </BrowserRouter>
                </HelmetProvider>
            </MantineProvider>
        </Provider>
    </React.StrictMode>
);
