import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

const useSkills = (id = undefined) => {
    const dispatch = useDispatch();
    const skills = useSelector(state => state.skillsJsonState);
    const [skill, setSkill] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!skills && !loading) {
            setLoading(true);
            dispatch.skillsJsonState.fetchSkills();
        }

        if (skills && id) {
            setSkill(skills[id]);
        }

    }, [skills, dispatch, loading, id]);

    const getSkillIconSrc = (skill) => {
        return 'https://images.dares.club/' + skill.icon.replace(/\[|\]/g, '').replace('.', '/skill_i/').replace(/^./, str => str.toUpperCase()) + '.png'
    }

    return {skills, skill, getSkillIconSrc}
};

export default useSkills;