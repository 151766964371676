import Gap from "../../components/Gap";
import {Helmet} from "react-helmet-async";
import {LINKS} from "../../App";
import getText from "../../locale/lang";
import {Container} from "@mantine/core";
import PolicyButtons from "./PolicyButtons";


export default function CookiePolicy() {

    return (<Container>
        <Helmet>
            <title>Dares - {getText('cookiePolicy')} L2 Server</title>
            <link rel="canonical" href={`${LINKS.mainUrl}/`}/>
        </Helmet>
        <Gap/>
        <Gap/>
        <Gap/>
        <PolicyButtons position="start"/>
        <div>
            <h2>Cookie Policy</h2>
            <p>Thank you for visiting dares.club (“Website”). This cookie notice applies to the Website any website or branded pages on third party platforms (e.g. YouTube and Facebook) and applications accessed or used through such websites or platforms which are operated by or
                on behalf of dares.club.</p>
            <p>By using dares.club Site you are consenting to our use of cookies and other tracking technology in accordance with this notice. If you do not agree to our use of cookies and other tracking technology in this way you should set your browser settings accordingly or
                not use the dares.club Site. If you disable cookies that we use this may impact your user experience while on the dares.club Site.</p>
            <p>When using a mobile device to connect to the internet you should also refer to the privacy notice of the specific App you are using to understand its specific data collection practices.</p>
            <ol>
                <li>
                    <b>What are cookies?</b>
                    <p>Cookies are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work or work more efficiently as well as to provide information to the owners of the site. See below for details on what
                        information is collected by cookies and how we use that information. For more information about the kind of data we collect please read our Privacy Policy.</p>
                </li>
                <li>
                    <b>How and why does dares.club use them?</b>
                    <p>dares.club uses cookies to gain a better understanding how visitors use this website. Cookies help us tailor dares.club websites to your personal needs to improve their user-friendliness gain customer satisfaction feedback on our websites (through
                        designated partners) and to communicate to you elsewhere on the web. To enable this some cookies are applied when you enter our sites (see links for various dares.club sites for further details). dares.club keeps all the information collected from cookies
                        in a non–personally identifiable format. dares.club cookies located on your computer do not retain your name or your IP address.</p>
                </li>
                <li>
                    <b>What type of cookies does dares.club use?</b>
                    <p>The following types of cookies are used on dares.club.</p>
                    <ol>
                        <li><b>Necessary cookies</b>
                            <p>These are cookies that are strictly necessary for the operation of a website. Without these cookies this website won’t work properly. Accordingly we are not asking you for your specific consent for those cookies. For all other cookies your informed
                                consent is required.</p>
                        </li>
                        <li><b>Session cookies</b>
                            <p>Session cookies are temporary cookie files which are erased when you close your browser. When you restart your browser and go back to the site that created that cookie the website will treat you as a new visitor.</p>
                        </li>
                        <li><b>Functional/Persistent Cookies</b>
                            <p>These are cookies which are set up to improve the functionality of the website. For example cookies that remember the content you previously viewed on this website or the email address and password you provided when registering during an earlier
                                visit to this website. Cookies may also remember items you have previously placed in your shopping cart while visiting a dares.club website. Using Functional cookies therefore may allow us to serve you content tailored to your interests and save
                                you the time of having to re-register or re-enter information when you re-visit this website or try to access certain member-only sections. On some websites cookies enable us to store your favourite recipes activities points or high scores. We may
                                also use Cookies to lockout underage users from certain activities.</p>
                        </li>
                        <li><b>Cookies that send information to us</b>
                            <p>These are the cookies that we set on a dares.club Site and they can only be read by that site. This is known as a “First Party” cookie. We also place cookies on brand ads which are placed on other websites owned by third parties (e.g. Facebook). We
                                obtain information via those cookies when you click on or interact with the advertisement. In this situation the brand is placing a “Third Party” cookie. The brand may use the information obtained by these cookies to serve you with advertising that
                                is relevant and of interest to you based on your past online behaviour.</p>
                        </li>
                        <li><b>Cookies that send information to other companies</b>
                            <p>These are cookies that are set on a dares.club Site by our partner companies (e.g. Facebook or advertisers). They may use the data collected from these cookies to anonymously target advertising to you on other websites based on your visit to this
                                Website. For example if you use a social widget (e.g. the Facebook icon) on the Website it will record your “share” or “like”. Facebook (as the company setting the cookie) will collect the data. This is known as a “Third Party’’ cookie.</p>
                        </li>
                        <li><b>Demographics and Interest Reporting</b>
                            <p>Some of our websites use Google Analytics Demographics and Interest Reporting to improve advertising. Some common applications are to target advertising based on what’s relevant to a user to improve reporting on campaign performance and to avoid
                                showing ads the user has already seen. This is done using something called the DoubleClick cookie. If you don’t want your information collected in this way you can:</p>
                            <ol>
                                <li>use Google’s Ad Settings to opt out of Display Advertising; or</li>
                                <li>use Google’s Browser add-on to opt out of Google Analytics</li>
                            </ol>
                            <p>DoubleClick cookies contain no personally identifiable information. Sometimes the DoubleClick cookie will contain an additional identifier that is similar in appearance to the cookie ID and is used to identify an ad campaign to which a user was
                                exposed previously. We will not use any information reported by Google Analytics Demographics and Interest Reporting to identify any individual user of our site.</p>
                            <p>Find out more about Google Analytics Demographics and Interest Reporting</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <b>How can I change my cookie settings?</b>
                    <p>Please ensure that your computer setting reflects whether you are happy to accept cookies or not. You can set your browser to warn you before accepting cookies or you can simply set it to refuse them although you may not have access to all the features of
                        this website if you do so. See your browser ‘help’ button for how you can do this. You do not need to have cookies on to use or navigate through many parts of this and other dares.club websites. Remember that if you use different computers in different
                        locations you will need to ensure that each browser is adjusted to suit your cookie preferences. Click at the bottom left of the screen -> cookie settings -> show details -> decline all.</p>
                    <p>The Site allows you to choose NOT to allow the services which create and use these cookies to run although you may not have access to all the features of the Site if you do so. To do this now just scroll up the page and click on the “Change Cookie Settings”
                        link higher up. For more information on the new legislation regarding cookies see the Information Commissioners Office’s website – <a href="https://ico.org.uk/">https://ico.org.uk/</a></p>
                    <p>For useful information on cookies see <a href="http://www.aboutcookies.org/">AboutCookies.org</a></p>
                </li>
                <li>
                    <b>Legal Notice Update</b>
                    <p>We reserve the right to make any changes and corrections to this notice. Please refer to this page from time to time to review these and new additional information.</p>
                </li>
            </ol>


    </div>

</Container>)
}