import axios from "axios";


export const skillsJsonState = {
    state: null,
    reducers: {
        setSkills(_, payload) {
            return payload
        }
    },
    effects: (dispatch) => ({
        async fetchSkills() {
            const res = await axios.get('https://new.dares.club/database/skills.json')
            dispatch.skillsJsonState.setSkills(res.data)
            return res
        },
    }),
};

export const setsJsonState = {
    state: null,
    reducers: {
        setSets(state, payload) {
            return payload
        }
    },
    effects: (dispatch) => ({
        async fetchSets() {
            const res = await axios.get('https://new.dares.club/database/sets.json')
            dispatch.setsJsonState.setSets(res.data)
            return res
        },
    }),
};

export const itemsJsonState = {
    state: null,
    reducers: {
        setItems(state, payload) {
            return payload
        }
    },
    effects: (dispatch) => ({
        async fetchItems() {
            axios.get('https://new.dares.club/database/items.json').then(r => {
                dispatch.itemsJsonState.setItems(r.data)
                return r.data
            })
        },
    }),
};
