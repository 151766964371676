import {init} from "@rematch/core";
import {itemsJsonState, setsJsonState, skillsJsonState} from "./models";

const models = {
    skillsJsonState,
    setsJsonState,
    itemsJsonState
}

const store = init({models});
export default store;