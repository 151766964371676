import {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {HeaderToggleContext} from "../../App";
import {getServer, GoogleAnalyticsButton} from "../../misc";
import {useLocationPathname} from "../../hooks/useLocationPathname";


export default function LinkButton(props) {
    const pathname = useLocationPathname();
    const {changeToggle, setChangeToggle} = useContext(HeaderToggleContext);
    const [server, setServer] = useState(getServer());

    useEffect(() => {
        setServer(getServer());
    }, [pathname]);

    const linkAction = () => {
        GoogleAnalyticsButton()
        setChangeToggle(!changeToggle)
        document.querySelector('#burgerButton').click()
        if (props.to === 'features' && window.location.pathname.split('/')[1] === 'features') {
            const headerHeight = 200;
            const element = document.querySelector('#w_button_features_features');
            const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({
                top: elementPosition - headerHeight,
                behavior: 'smooth'
            });
        } else {
            window.scrollTo(0, 0)
        }
    }


    return (
        <li id={props.matomo_id} className={`${window.location.pathname.split('/')[1] === props.to ? 'active' : ''}`} onClick={() => {
            linkAction()
        }}>
            <Link to={`/${props.to}/${server}`} className={`link-effect-4 ready`}>
                <span className={`link-effect-inner`}>
                    <span className={`link-effect-l`}>
                        <span>{props.children}</span>
                    </span>
                    <span className={`link-effect-r`}>
                        <span>{props.children}</span>
                    </span>
                    <span className={`link-effect-shade`}>
                        <span>{props.children}</span>
                    </span>
                </span>
            </Link>
        </li>
    )
}