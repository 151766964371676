import React from 'react';
import {useNavigate} from "react-router-dom";

const BackButton = () => {
    const navigate = useNavigate()
    const backHandle = () => {
        navigate(-1)
    }
    return (
        <div className="nk-back-button nk-side-buttons-visible">
            <ul onClick={backHandle}>
                <li style={{listStyleType: 'none'}} className="nk-scroll-top">
                    <span className="nk-btn nk-btn-lg nk-btn-icon">
                        <i className="fa-solid fa-arrow-left"></i>
                    </span>
                </li>
            </ul>
        </div>
    );
};

export default BackButton;