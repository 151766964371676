import getText from "../../locale/lang";
import LinkButton from "./LinkButton";


export default function Links() {
    return (
        <>
            <LinkButton to={'features'} matomo_id={'w_button_features'}>{getText('features')}</LinkButton>
            <LinkButton to={'download'} matomo_id={'w_button_download'}>{getText('download')}</LinkButton>
            <LinkButton to={'streamers'} matomo_id={'w_button_streamers'}>{getText('streamers')}</LinkButton>
            <LinkButton to={'database'} matomo_id={'w_button_database'}>{getText('database')}</LinkButton>
        </>
    )
}