import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

const useItems = () => {
    const dispatch = useDispatch();
    const items = useSelector(state => state.itemsJsonState);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!items && !loading) {
            setLoading(true)
            dispatch.itemsJsonState.fetchItems();
        }
    }, [loading, items, dispatch]);

    const getItem = (itemsId) => {
        let item;
        if (typeof itemsId === 'string') {
            item = items[itemsId.split(';')[0]]
        } else {
            item = items[itemsId]
        }
        return item
    }

    const getItemIconSrc = (item) => {
        if (!item) {
            return ''
        }
        let icon = item.icon
        if (!icon) {
            icon = item[1][0].icon
        }
        return 'https://images.dares.club/' + icon.split(';')[0].replace('{', '').replace(/\[|\]/g, '').replace('icon.', '').replace('Icon.', '') + '.png'
    }

    return {items, getItem, getItemIconSrc}

};

export default useItems;