import React, {useEffect, useState} from 'react';
import {Center, Container, Group, Table} from "@mantine/core";
import {Helmet} from "react-helmet-async";
import {LINKS} from "../../../App";
import Gap from "../../../components/Gap";
import getText from "../../../locale/lang";

import {Link, useParams} from "react-router-dom";
import axios from "axios";
import RaceButton from "../RaceButton";

const DatabaseClasses = () => {
    const [race, setRace] = useState(undefined);
    const [startProfs, setStartProfs] = useState(undefined)
    const [mapping, setMapping] = useState(undefined)
    const {server} = useParams()

    useEffect(() => {
        axios.get('https://new.dares.club/database/mapping.json').then(({data}) => {
            setMapping(data)
        })
    }, []);

    useEffect(() => {
        if (mapping && race) {
            const orcProfs = mapping.races[race]
            const keys = Object.keys(mapping.mapping)
            const startProfsKeys = keys.filter(id => orcProfs.includes(parseInt(id)))
            setStartProfs(startProfsKeys)
        }
    }, [mapping, race]);

    return (
        <>
            <Helmet>
                <title>Dares - {getText('database')} L2 Server</title>
                <link rel="canonical" href={`${LINKS.mainUrl}/`}/>
            </Helmet>

            <Container>
                <Gap/>
                <Gap/>
                <Gap/>
                <div style={{padding: 20, backgroundColor: '#060606'}}>
                    <Container>

                        {!mapping ? <Center><h3>{getText('loading')}</h3></Center> : <>
                        <Gap/>
                            <Group position="center">
                                <RaceButton race={'human'} setRace={setRace}/>
                                <RaceButton race={'elf'} setRace={setRace}/>
                                <RaceButton race={'dark elf'} setRace={setRace}/>
                                <RaceButton race={'orc'} setRace={setRace}/>
                                <RaceButton race={'dwarf'} setRace={setRace}/>
                            </Group>
                            <Gap/>
                            {startProfs && startProfs.map(key => {
                                const prof = mapping.mapping[`${key}`]
                                return (
                                    <React.Fragment key={key}>
                                        <Table style={{color: 'white', textAlign: 'center'}}>
                                            <thead style={{height: 50}}>
                                            <tr>
                                                <td colSpan={9}>
                                                    <Link to={`/database/prof/${key}/${server}`}>
                                                        {mapping.names[key]}
                                                    </Link>
                                                </td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                {prof.children.map(profKey => (
                                                    <td key={profKey.id}>
                                                        <Table style={{color: 'white', textAlign: 'center'}}>
                                                            <thead>
                                                            <tr>
                                                                <td colSpan={9}>
                                                                    <Link to={`/database/prof/${profKey.id}/${server}`}>
                                                                        {mapping.names[profKey.id]}
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                {profKey.children.map(key2 => (
                                                                    <td key={key2.id}><Link to={`/database/prof/${key2.id}/${server}`}>{mapping.names[key2.id]}</Link></td>))}
                                                            </tr>
                                                            <tr>
                                                                {profKey.children.map(key2 => {
                                                                    return key2.children.map(key3 => (
                                                                        <td key={key3.id}><Link to={`/database/prof/${key3.id}/${server}`}>{mapping.names[key3.id]}</Link></td>))
                                                                })}
                                                            </tr>
                                                            </tbody>
                                                        </Table>
                                                    </td>
                                                ))}
                                            </tr>
                                            </tbody>
                                        </Table>
                                        <Gap/>
                                    </React.Fragment>
                                )
                            })}
                        </>}
                    </Container>
                </div>
            </Container>
        </>
    );
};

export default DatabaseClasses;