import React, {useEffect, useState} from 'react';

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > window.innerHeight / 2) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Подписка на событие прокрутки и очистка
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    // Функция для прокрутки страницы вверх
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    return (
        <div className={`nk-side-buttons nk-side-buttons-visible nk-side-buttons-show-scroll-top scroll-to-top ${isVisible ? 'visible' : ''}`} style={{paddingRight: 40}}>
            <ul onClick={scrollToTop}>
                <li className="nk-scroll-top">
                            <span className="nk-btn nk-btn-lg nk-btn-icon">
                                <i className="fa-solid fa-arrow-up"></i>
                            </span>
                </li>
            </ul>
        </div>
    );
};

export default ScrollToTopButton;