import Countdown from "react-countdown";
import getText from "../locale/lang";
import {isMobile} from "../App";


export default function MyCountdown(props) {

    const renderer = ({days, hours, minutes}) => {
        let title;
        let style;
        if (isMobile) {
            title = <h6 dangerouslySetInnerHTML={{__html: props.title}}/>
            style = {fontSize: '0.7rem', backdropFilter: 'blur(5px)', margin: '0 auto',
                backgroundColor: 'rgba(14, 14, 14, 0.5)', borderRadius: 10, padding: '20px 40px', width: 'fit-content'}
        } else {
            title = <h5 dangerouslySetInnerHTML={{__html: props.title}}/>
            style = {fontSize: '1rem', backdropFilter: 'blur(5px)', margin: '0 auto',
                backgroundColor: 'rgba(14, 14, 14, 0.5)', borderRadius: 10, padding: '20px 40px', width: 'fit-content'}
        }

        return (
            <div id={'w_button_features_home'} style={{marginBottom: 10}} >
                <div className="nk-countdown rate-button" style={style}>
                    {title}
                    {!!days &&
                        <div>
                            <span>{days}</span>
                            {getText('days')}
                        </div>
                    }
                    {!!hours &&
                        <div>
                            <span>{hours}</span>
                            {getText('hours')}
                        </div>
                    }
                    {!!minutes &&
                        <div>
                            <span>{minutes}</span>
                            {getText('minutes')}
                        </div>
                    }
                </div>
            </div>
        )

    };

    return (
        <Countdown
            date={props.date}
            renderer={renderer}
        />
    )
}