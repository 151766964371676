import React from 'react';
import getText from "../../../locale/lang";

const RareJewelry = ({info}) => {
    return (
        <div className="bg-dark-2" style={{marginBottom: 16, padding: 20, display: "flex", alignItems: 'flex-start', flexDirection: 'column'}}>
            <h4>{getText('rareJewelry')}</h4>
            {info.jewelryInfo.map((gradeItem, index) => {
                return <div key={index}>
                    <h5>{gradeItem.grade}</h5>
                    <ul>
                        {gradeItem.items.map((item, itemIndex) => {
                            const properties = item?.name ? item.properties : item

                            return (
                                Array.isArray(item.properties) ?
                                    <li key={itemIndex}>
                                        {item?.name}
                                        <ul>
                                            {item.properties.map((property, propertyIndex) => (
                                                <li key={propertyIndex}>{property}</li>
                                            ))}
                                        </ul>
                                    </li> :
                                    <li key={itemIndex}>
                                        {properties}
                                    </li>
                            )
                        })}
                    </ul>
                </div>
            })}
        </div>
    );
};

export default RareJewelry;