import React from 'react';

const RaceButton = ({race, setRace}) => {
    const stripped_race = race.replace(/ /g, '');

    return (
        <button
            className="nk-btn nk-btn-x2 nk-btn-color-white ready"
            style={{margin: '-10px 0', fontFamily: 'Marcellus SC'}}
            onClick={() => {
                setRace(stripped_race)
            }}
            id={`w_btn_database_race_${stripped_race}`}
        >
            {race.split('').map((key, index) => (
                <span key={index}>{key}</span>
            ))}
        </button>
    );
};

export default RaceButton;