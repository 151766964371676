import {Routes, Route, useParams, Navigate} from "react-router-dom";
import Footer from "./layouts/Footer";
import Header from "./layouts/Header";
import './App.css';
import PrivacyPolicy from "./pages/Policy/PrivacyPolicy";
import React, {createContext, useEffect, useState} from "react";
import Download from "./pages/Download";
import Redirect from "./components/Header/Redirect";
import Promo from "./pages/Promo";
import FeaturesRemastered from "./pages/FeaturesRematered";
import DatabaseClasses from "./pages/database/classes/DatabaseClasses";
import DatabaseProf from "./pages/database/classes/DatabaseProf";
import DatabaseSkill from "./pages/database/DatabaseSkill";
import Database from "./pages/Database";
import DatabaseSets from "./pages/database/sets/DatabaseSets";
import DatabaseSet from "./pages/database/sets/DatabaseSet";
import Streamers from "./pages/Streamers";
import useQueryParam from "./hooks/useQueryParam";
import {is_referral_key_exist} from "./misc";
import axios from "axios";
import ReactPixel from "react-facebook-pixel";
import {useCookieContext} from "./CookieContext";
import ServerRules from "./pages/Policy/ServerRules";
import TermsAndConditions from "./pages/Policy/TermsAndConditions";
import CookiePolicy from "./pages/Policy/CookiePolicy";
import RefundPolicy from "./pages/Policy/RefundPolicy";

export const mobileWidth = 769
export const isMobile = window.innerWidth < mobileWidth
export const ACTIVE_SERVER = 'remastered-x3'
export const SERVER_LIST = ['remastered-x3']
export const HOME_PAGE = `/features/${ACTIVE_SERVER}`

// export const INTERLUDE_DATE = new Date(Date.UTC(2022, 10, 18, 17)) --- x100 Interlude
export const INTERLUDE_DATE_X50 = new Date(Date.UTC(2023, 0, 13, 20)) /// x50 Interlude
export const INTERLUDE_DATE_X100 = new Date(Date.UTC(2023, 1, 24, 20)) /// x50 Interlude

export const REMASTERED_DATE_X10_OBT = new Date(Date.UTC(2023, 4, 23, 17)) /// x5 Remastered
export const REMASTERED_DATE_X10 = new Date(Date.UTC(2023, 4, 26, 17)) /// x5 Remastered
export const REMASTERED_DATE_X6 = new Date(Date.UTC(2023, 11, 22, 17)) /// x6 Remastered
export const REMASTERED_DATE_X6_NEW = new Date(Date.UTC(2024, 1, 9, 19)) /// x6 Remastered NEW
export const REMASTERED_DATE_X3 = new Date(Date.UTC(2024, 4, 24, 17)) /// x3 Remastered
export const REMASTERED_OBT_DATE_X3 = new Date(Date.UTC(2024, 4, 17, 17)) /// x3 OBT Remastered
export const REMASTERED_DATE_X50 = new Date(Date.UTC(2024, 2, 8, 18))
export const CLASSIC_DATE = new Date(Date.UTC(2022, 9, 28, 17)) /// x5 Classic

export let LINKS
if (window.location.href.split('/')[2] === 'dares.club') {
    LINKS = {
        mainUrl: 'https://dares.club',
        cookieDomain: 'dares.club',
        apiUrl: 'https://api.dares.club',
        discord: 'https://discord.gg/dares-club',
        forum: 'https://forum.dares.club/',
        signup: 'https://cabinet.dares.club/signup',
        login: 'https://cabinet.dares.club/login',
        donate: 'https://cabinet.dares.club/donate',
        play_now_download: `${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}/download/${ACTIVE_SERVER}`,
        download: {
            classic: {
                googleDrive: 'https://drive.google.com/file/d/1oGORccgUju5JJZZl9u4tkRmvqL5rnEpz/view?usp=sharing', autoUpdater: 'https://updater.dares.club/dares.club.exe',
            }, interlude: {
                googleDrive: 'https://drive.google.com/file/d/1mzN7SslVs0rnUNHselltrzqPGaCU2Rzl/view?usp=sharing',
                clientMega: 'https://mega.nz/file/JllgkSSJ#jkoJnnOebFxTdzhlNgOanWWpqrZkHYMby0Rz7v5M2s4',
                autoUpdater: 'https://updater.dares.club/interlude/dares.club.exe',
                client: 'https://updater.dares.club/interlude/dares.club.zip',
            }, remastered: {
                autoUpdater: 'https://new.dares.club/dares.club.exe',
                client: 'https://new.dares.club/dares.club.zip',
                googleDrive: 'https://drive.google.com/file/d/1bunxshJey8Btytx2xFx-2ubb-3YQfiNk/view?usp=sharing',
                MEGA: 'https://mega.nz/file/JllgkSSJ#jkoJnnOebFxTdzhlNgOanWWpqrZkHYMby0Rz7v5M2s4'
            },
        }
    }
} else {
    LINKS = {
        mainUrl: `${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}`,
        cookieDomain: `${window.location.href.split('/')[2]}`,
        apiUrl: 'https://api.dev.dares.club',
        discord: 'https://discord.gg/dares-club',
        forum: 'https://forum.dares.club/',
        signup: 'https://cabinet.dev.dares.club/signup',
        login: 'https://cabinet.dev.dares.club/login',
        donate: 'https://cabinet.dev.dares.club/donate',
        play_now_download: `${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}/download/${ACTIVE_SERVER}`,
        download: {
            classic: {
                googleDrive: 'https://drive.google.com/file/d/1oGORccgUju5JJZZl9u4tkRmvqL5rnEpz/view?usp=sharing', autoUpdater: 'https://updater.dares.club/dares.club.exe',
            }, interlude: {
                googleDrive: 'https://drive.google.com/file/d/1mzN7SslVs0rnUNHselltrzqPGaCU2Rzl/view?usp=sharing',
                clientMega: 'https://mega.nz/file/JllgkSSJ#jkoJnnOebFxTdzhlNgOanWWpqrZkHYMby0Rz7v5M2s4',
                autoUpdater: 'https://updater.dares.club/interlude/dares.club.exe',
                client: 'https://updater.dares.club/interlude/dares.club.zip',
            }, remastered: {
                autoUpdater: 'https://updater.dares.club/dares.club.exe',
                client: 'https://updater.dares.club/dares.club.zip',
                googleDrive: 'https://drive.google.com/file/d/1bunxshJey8Btytx2xFx-2ubb-3YQfiNk/view?usp=sharing',
                MEGA: 'https://mega.nz/file/JllgkSSJ#jkoJnnOebFxTdzhlNgOanWWpqrZkHYMby0Rz7v5M2s4'
            },
        }
    }
}


export const HeaderToggleContext = createContext()

const MainLayout = ({children}) => {
    const {server} = useParams();

    if (!SERVER_LIST.includes(server)) {
        return <Navigate to={HOME_PAGE} replace/>;
    }

    return <>
        <Header/>
        {children}
        <Footer/>
    </>
}

export default function App() {
    const [changeToggle, setChangeToggle] = useState(false);

    const {updateRefKey} = useCookieContext();
    const utm_source = useQueryParam('utm_source');

    useEffect(() => {
        ReactPixel.init('270099082769969');
        ReactPixel.pageView();
    }, []);

    useEffect(() => {
        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', '"en"')
        }
    }, []);

    useEffect(() => {
        if (utm_source !== null) {
            if (is_referral_key_exist()) {
                return
            }
            let referralKey = utm_source.toLowerCase()
            updateRefKey(referralKey);
            axios.post(`${LINKS.apiUrl}/referral/promo`, {referralKey}).then(() => console.log('success'))
        }

        localStorage.setItem('server', ACTIVE_SERVER);
    }, [updateRefKey, utm_source])

    return (<>
        <HeaderToggleContext.Provider value={{changeToggle, setChangeToggle}}>
            <div className="App">
                <Routes>
                    <Route path="/promo/:referralKey" element={<Promo/>}/>

                    <Route path="/streamers/:server" element={<MainLayout><Streamers/></MainLayout>}/>

                    <Route path="/features/:server" element={<MainLayout><FeaturesRemastered/></MainLayout>}/>
                    <Route path="/download/:server" element={<MainLayout><Download/></MainLayout>}/>

                    <Route path="/database/:server" element={<MainLayout><Database/></MainLayout>}/>
                    <Route path="/database/classes/:server" element={<MainLayout><DatabaseClasses/></MainLayout>}/>
                    <Route path="/database/prof/:id/:server" element={<MainLayout><DatabaseProf/></MainLayout>}/>
                    <Route path="/database/skill/:id/:server" element={<MainLayout><DatabaseSkill/></MainLayout>}/>
                    <Route path="/database/sets/:server" element={<MainLayout><DatabaseSets/></MainLayout>}/>
                    <Route path="/database/set/:id/:server" element={<MainLayout><DatabaseSet/></MainLayout>}/>

                    <Route path="/privacy-policy/:server" element={<MainLayout><PrivacyPolicy/></MainLayout>}/>
                    <Route path="/server-rules/:server" element={<MainLayout><ServerRules/></MainLayout>}/>
                    <Route path="/terms/:server" element={<MainLayout><TermsAndConditions/></MainLayout>}/>
                    <Route path="/cookie-policy/:server" element={<MainLayout><CookiePolicy/></MainLayout>}/>
                    <Route path="/refund-policy/:server" element={<MainLayout><RefundPolicy/></MainLayout>}/>

                    <Route path="/*" element={<Redirect to={HOME_PAGE}/>}/>
                </Routes>
            </div>
        </HeaderToggleContext.Provider>

    </>);
}
