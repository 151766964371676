import {TwitchPlayer} from "react-twitch-embed";
import {Center, Container, Title} from "@mantine/core";
import Gap from "../components/Gap";
import getText from "../locale/lang";
import {useEffect, useState} from "react";
import axios from "axios";


const Streamers = () => {
    const [loading, setLoading] = useState(true);
    const [streamers, setStreamers] = useState(undefined);

    useEffect(() => {
        axios.get('https://new.dares.club/streamers/data.json').then(res => {
            console.log(res.data.length === 0)
            setStreamers(res.data);
            setLoading(false);
        })
    }, [])


    return (
        <Container>
            <Gap/>
            <Gap/>
            <Gap/>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Title order={2} align="center" mb={25}>{getText('streamers')}</Title>
                {loading || streamers === undefined ? <Center><h3>{getText('loading')}</h3></Center> :
                    streamers.length === 0 ? <Center><h5>Contact us in discord to add your stream</h5></Center> :
                        streamers.map((streamer, index) => (
                            (index % 2 === 0) ? <div key={streamer.channel + index} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                {streamers.slice(index, index + 2).map(streamer => (
                                    <TwitchPlayer
                                        key={streamer.channel}
                                        channel={streamer.channel}
                                        id={streamer.channel}
                                        style={{display: `block`, width: "100%", height: 378, padding: 10}}
                                    />
                                ))}
                            </div> : null
                        ))}
            </div>
        </Container>
    );
};

export default Streamers;