import styled from "styled-components";
import {useEffect, useState} from "react";

const BTN = styled.div`
  &:hover {
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.3);
  }
`;

export default function FeaturesButton(props) {
    const [class_, setClass_] = useState(props.active === props.name ? 'choosed' : '');

    useEffect(() => {
        setClass_(props.active === props.name ? 'choosed' : '');
    }, [props])

    return (
        <BTN id={props.matomo_id} className={`bg-dark-3 ${class_}`} onClick={props.onClick} style={{marginBottom: 16, marginTop: 16, padding: 20, display: "flex", alignItems: 'center'}}>
            <h6 style={{margin: 0, paddingLeft: 10}}>
                {props.text}
            </h6>
        </BTN>
    )
}