import React from 'react';
import getText from "../../../locale/lang";

const Sets = ({info}) => {
    return (
        <div className="bg-dark-2" style={{marginBottom: 16, padding: 20, display: "flex", alignItems: 'flex-start', flexDirection: 'column'}}>
            <h4>{getText('setChanges')}</h4>
            <h5>NG</h5>
            <ul>
                <li>Wooden Set: P. Def. +2%, P. Atk. Spd. +2%, Max HP +50</li>
                <li>Devotion Set: Cast. Spd. +15%, MP Reg. +5%</li>
            </ul>
            {info.setChanges.map((item, index) => (
                    <React.Fragment key={index}>
                        <h5>{item.title}</h5>
                        <ul>
                            {item.description.map((item, index) => (
                                <React.Fragment key={index}>
                                    <h5>{item.title}</h5>
                                    <ul>
                                        {item.description.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                </React.Fragment>
                            ))}
                        </ul>
                    </React.Fragment>
                )
            )}
        </div>
    );
};

export default Sets;