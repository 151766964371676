import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {ACTIVE_SERVER} from "../../App";

export default function Redirect(props) {
    const navigate = useNavigate();

    useEffect(() => {
        if (!props.server) {
            navigate(props.to)
            return;
        }
        navigate(`${props.to}${ACTIVE_SERVER}`)
    }, [navigate, props.server, props.to])
}