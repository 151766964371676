import BurgerButton from "../components/Header/BurgerButton";
import Links from "../components/Header/Links";
import {Link} from "react-router-dom";
import {isMobile, LINKS} from "../App";
import {useWindowEvent} from "@mantine/hooks";
import getText from "../locale/lang";
import RedirectButton from "../components/Header/RedirectButton";
import MobileLangButton from "../components/Header/MobileLangButton";
import PlayNow from "../components/PlayNow";
import Vote from "../pages/Vote";
import ReactPixel from "react-facebook-pixel";
import {Container} from "@mantine/core";
import {useEffect} from "react";
import {useCookieContext} from "../CookieContext";
import daresIcon from "../img/Dares.ico";

export default function Header() {
    const {refKey} = useCookieContext();

    useEffect(() => {
    }, [refKey]);

    let lastScroll = 0;
    const scrollPosition = () => document.documentElement.scrollTop

    useWindowEvent('scroll', () => {
        if (scrollPosition() > lastScroll && !document.querySelector('.my-header').classList.contains('hide-header') && scrollPosition() > 500) {
            document.querySelector('.my-header').classList.add('hide-header')
        } else if (scrollPosition() < lastScroll && document.querySelector('.my-header').classList.contains('hide-header')) {
            document.querySelector('.my-header').classList.remove('hide-header')
        }
        lastScroll = scrollPosition()
    });


    const loginClick = () => {
        ReactPixel.track('InitiateCheckout');
    }

    return (<header className={`nk-header nk-header-opaque`}
                    style={{position: 'fixed', top: 0, transition: 'transform .1s linear'}}>
        <div className="nk-contacts-top nk-contacts-top-transparent my-header">
            <Container style={{display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <div className="nk-contacts-left" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <img src={daresIcon} alt="icon" style={{marginRight: 20, height: '1.75rem'}}/>
                    <Link id={'w_button_home_logo'} to={`/`}>
                        <h3 style={{marginBottom: 0}}>Dares Club</h3>
                    </Link>
                </div>
                <div className="nk-contacts-right">
                    <div className="nk-navbar">
                        <ul className="nk-nav">
                            <Vote/>
                            <RedirectButton to={LINKS.discord}>{getText('discord')}</RedirectButton>
                            <RedirectButton matomo_id={'w_button_singup'} to={`${LINKS.login}${refKey ? `?utm_source=${refKey}` : ''}`} onClick={loginClick}>{getText('login')}</RedirectButton>
                            <a href="https://l2oops.com" style={{zIndex: 99999}} target="_blank" rel="noreferrer">
                                <img
                                    src="https://l2oops.com/logo/black_l2oops_mini.png"
                                    width={122}
                                    height={31}
                                    alt="Новые сервера Lineage 2"
                                    title="Новые сервера Lineage 2"
                                    border={0}
                                />
                            </a>
                        </ul>
                    </div>
                </div>
            </Container>
        </div>

        <nav className={`nk-navbar nk-navbar-top nk-navbar-sticky nk-navbar-autohide nk-onscroll-show`}>
            <Container>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>

                    <ul className="nk-nav nk-nav-right d-none d-lg-block" data-nav-mobile="#nk-nav-mobile">
                        <Links/>
                    </ul>
                    {!isMobile && <PlayNow/>}
                    <BurgerButton/>
                </div>
            </Container>
        </nav>

        <div id="nk-nav-mobile" className="nk-navbar nk-navbar-side nk-navbar-left-side d-lg-none">
            <div className="nano">
                <div className="nano-content">
                    <div className="nk-navbar-mobile-content" style={{display: 'flex', flexWrap: 'wrap', height: '100%', alignContent: 'space-between'}}>
                        <ul className="nk-nav">
                            <Links/>
                        </ul>
                        <MobileLangButton/>
                    </div>
                </div>
            </div>
        </div>
        {/*<ModalNews/>*/}
    </header>)
}