import getText from "../../../locale/lang";
import React from "react";
import {useLocalStorage} from "@mantine/hooks";


export default function BossInfo({info}) {
    const [lang] = useLocalStorage({key: 'lang', defaultValue: localStorage.getItem('lang').replace(/"/g, '') || 'en'});

    return (
        <div className="bg-dark-2" style={{marginBottom: 16, padding: 20, display: "flex", alignItems: 'flex-start', flexDirection: 'column'}}>
            <h4>{getText('bossInfo')}</h4>
            {info.bossInfo.map((item, index) => {
                const text = item[lang] === undefined || item[lang].length === 0 ? item.en : item[lang]
                return <React.Fragment key={index}>
                    <h5>{text.title}</h5>
                    {Array.isArray(text.description) && <ul>
                        {text.description.map((desc, index) => (
                            <li key={index} dangerouslySetInnerHTML={{__html: desc}}/>
                        ))}
                    </ul>}
                </React.Fragment>
            })}
        </div>
    )
}