export default function OptionButton(props) {
    return (
        <button className="nk-btn nk-btn-md nk-btn-rounded link-effect-4 ready" onClick={props.onClick}>
            <span className="link-effect-inner">
                <span className="link-effect-l"><span>{props.children}</span></span>
                <span className="link-effect-r"><span>{props.children}</span></span>
                <span className="link-effect-shade"><span>{props.children}</span></span>
            </span>
        </button>
    )
}