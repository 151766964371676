import getText from "../../../locale/lang";
import {useLocalStorage} from "@mantine/hooks";


export default function FeaturesInfo({info}) {
    const [lang] = useLocalStorage({key: 'lang',
        defaultValue: localStorage.getItem('lang') ? localStorage.getItem('lang').replace(/"/g, '') : 'en'});

    return (
        <div className="bg-dark-2" style={{marginBottom: 16, padding: 20, display: "flex", alignItems: 'flex-start', flexDirection: 'column'}}>
            <h4>{getText('featuresOf')} Dares.club</h4>
            <ul>
                {info.featuresInfo.map((item, index) => {
                    const text = item[lang] === undefined || item[lang].length === 0 ? item.en : item[lang]
                    return (text.length === 0) ? <br key={index}/> : <li key={index} dangerouslySetInnerHTML={{__html: text}}></li>
                })}
            </ul>
        </div>
    )
}